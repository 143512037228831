import { DateUtils } from "react-day-picker";
import { Month, Quarter, Strip } from "./enums";
import dateFnsParse from "date-fns/parse";
import dateFnsFormat from "date-fns/format";
import moment from "moment";

const MonthCode: Map<number, Month> = new Map<number, Month>();
MonthCode.set(1, Month.JAN);
MonthCode.set(2, Month.FEB);
MonthCode.set(3, Month.MAR);
MonthCode.set(4, Month.APR);
MonthCode.set(5, Month.MAY);
MonthCode.set(6, Month.JUN);
MonthCode.set(7, Month.JUL);
MonthCode.set(8, Month.AUG);
MonthCode.set(9, Month.SEP);
MonthCode.set(10, Month.OCT);
MonthCode.set(11, Month.NOV);
MonthCode.set(12, Month.DEC);

export { MonthCode };

const ReverseMonthCode: Map<Month, number> = new Map<Month, number>();
ReverseMonthCode.set(Month.JAN, 1);
ReverseMonthCode.set(Month.FEB, 2);
ReverseMonthCode.set(Month.MAR, 3);
ReverseMonthCode.set(Month.APR, 4);
ReverseMonthCode.set(Month.MAY, 5);
ReverseMonthCode.set(Month.JUN, 6);
ReverseMonthCode.set(Month.JUL, 7);
ReverseMonthCode.set(Month.AUG, 8);
ReverseMonthCode.set(Month.SEP, 9);
ReverseMonthCode.set(Month.OCT, 10);
ReverseMonthCode.set(Month.NOV, 11);
ReverseMonthCode.set(Month.DEC, 12);

export { ReverseMonthCode };

export interface Expiry {
  Year: number;
  Month: Month;
  Enabled?: boolean;
}

const ExpiryLookup: Map<Month, string> = new Map<Month, string>();
ExpiryLookup.set(Month.JAN, "F");
ExpiryLookup.set(Month.FEB, "G");
ExpiryLookup.set(Month.MAR, "H");
ExpiryLookup.set(Month.APR, "J");
ExpiryLookup.set(Month.MAY, "K");
ExpiryLookup.set(Month.JUN, "M");
ExpiryLookup.set(Month.JUL, "N");
ExpiryLookup.set(Month.AUG, "Q");
ExpiryLookup.set(Month.SEP, "U");
ExpiryLookup.set(Month.OCT, "V");
ExpiryLookup.set(Month.NOV, "X");
ExpiryLookup.set(Month.DEC, "Z");

export { ExpiryLookup };

const ExpiryReverseLookup: Map<string, Month> = new Map<string, Month>();
ExpiryReverseLookup.set("F", Month.JAN);
ExpiryReverseLookup.set("G", Month.FEB);
ExpiryReverseLookup.set("H", Month.MAR);
ExpiryReverseLookup.set("J", Month.APR);
ExpiryReverseLookup.set("K", Month.MAY);
ExpiryReverseLookup.set("M", Month.JUN);
ExpiryReverseLookup.set("N", Month.JUL);
ExpiryReverseLookup.set("Q", Month.AUG);
ExpiryReverseLookup.set("U", Month.SEP);
ExpiryReverseLookup.set("V", Month.OCT);
ExpiryReverseLookup.set("X", Month.NOV);
ExpiryReverseLookup.set("Z", Month.DEC);

export { ExpiryReverseLookup };

const QuarterExpiryLookup: Map<Quarter, string> = new Map<Quarter, string>();
QuarterExpiryLookup.set(Quarter.Q1, "H");
QuarterExpiryLookup.set(Quarter.Q2, "M");
QuarterExpiryLookup.set(Quarter.Q3, "U");
QuarterExpiryLookup.set(Quarter.Q4, "Z");

export { QuarterExpiryLookup };

const StripExpiryLookup: Map<Strip, string> = new Map<Strip, string>();
StripExpiryLookup.set(Strip.FIN, "M");
StripExpiryLookup.set(Strip.CAL, "Z");

export { StripExpiryLookup };

const QuarterReverseExpiryLookup: Map<string, Quarter> = new Map<string, Quarter>();
QuarterReverseExpiryLookup.set("H", Quarter.Q1);
QuarterReverseExpiryLookup.set("M", Quarter.Q2);
QuarterReverseExpiryLookup.set("U", Quarter.Q3);
QuarterReverseExpiryLookup.set("Z", Quarter.Q4);

export { QuarterReverseExpiryLookup };

const StripReverseExpiryLookup: Map<string, Strip> = new Map<string, Strip>();
StripReverseExpiryLookup.set("M", Strip.FIN);
StripReverseExpiryLookup.set("Z", Strip.CAL);

export { StripReverseExpiryLookup };

export const ExpiryConverter = {
  toLongYear(i: number) {
    if (i >= 2000) return i;
    return 2000 + i;
  },
  toShortYear(i: number) {
    if (i < 100) return i;
    return i % 100;
  },
  toString(exp: Expiry) {
    return `${exp.Month.toString()} ${this.toShortYear(exp.Year).toString()}`;
  },
  fromString(exp: string) {
    let splitted = exp.split(" ");
    let obj: Expiry = { Month: splitted[0] as Month, Year: Number(splitted[1]) };

    return obj;
  }
};

export function parseDate(str: string, format: string, locale: any) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

export function formatDate(date: Date, format: string, locale: any) {
  return dateFnsFormat(date, format, { locale });
}

export function getNetDate(date: string | Date | null | undefined) {
  if (!date || date === "0001-01-01T00:00:00") {
    return "0001-01-01T00:00:00";
  }
  return moment(date).format(MOMENT_NET_FORMAT);
}

export function getNetDateTime(date: string | Date | null | undefined) {
  if (!date || date === "0001-01-01T00:00:00") {
    return "0001-01-01T00:00:00";
  }
  return moment(date).format(MOMENT_NET_TIME_FORMAT);
}

export const FORMAT = "dd MMM yyyy";
export const FORMAT_TIME = "dd MMM yyyy H:mm";
export const MOMENT_FORMAT = "D MMM yyyy";
export const NET_FORMAT = "yyyy-MM-dd";
export const MOMENT_NET_FORMAT = "yyyy-MM-DD";
export const MOMENT_NET_TIME_FORMAT = "yyyy-MM-DDTHH:mm:ss.SSS";
export const DISPLAY_TIME_FORMAT = "MMMM Do YYYY, h:mm:ss a";
