import { put, race, call, take, throttle, takeLatest } from "redux-saga/effects";
import {
  DealsBaseAction,
  GetDealsFalied,
  GetDealsSucceeded,
  GetLogFalied,
  GetLogSucceeded,
  GET_DEALS_REQUEST,
  GET_LOG_REQUEST,
  SubmitDealFailed,
  SubmitDealSucceeded,
  SUBMIT_DEAL_CANCEL,
  SUBMIT_DEAL_REQUEST
} from "sagas/reducers/deals";
import { AuditLogModel, DealsApiFactory, DealModel, DealModelResult } from "../api";
import { DealActionReply, DealModelAction } from "../types/deals";
import axios from "axios";
import { LoginService } from "internal/dcc-services/LoginService";
import { HTTPReply } from "sagas/types/config";
const dealsClient = DealsApiFactory();

// workers
function* getDeals(action: DealsBaseAction<number>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(dealsClient.apiDealsGet, action.payload || 0);
    const reply = res.data;
    const data: DealModel[] = reply.Body;

    yield put(GetDealsSucceeded(data));
  } catch (error) {
    const e = error as any;
    yield put(GetDealsFalied({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getLogs(action: DealsBaseAction<number>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply = yield call(dealsClient.apiDealsDealIdLogsGet, action.payload || 0);
    const reply = res.data;
    const data: AuditLogModel[] = reply.Body;

    yield put(GetLogSucceeded(data));
  } catch (error) {
    const e = error as any;
    yield put(GetLogFalied({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* submitDeal(action: DealsBaseAction<DealModelAction>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const { done, cancel } = yield race({
      done: call(dealsClient.apiDealsCreatedealPost, action.payload?.Deals),
      cancel: take(SUBMIT_DEAL_CANCEL)
    });
    if (!cancel) {
      const res: DealModelResult = done?.data?.Body;
      if (res && res.DealId && res.DealReference) {
        yield put(
          SubmitDealSucceeded({
            DealActionReply: DealActionReply.Succeeded,
            DealResult: { DealId: res.DealId, DealReference: res.DealReference },
            DealActionRequest: action.payload?.DealAction!
          })
        );
      } else {
        yield put(
          SubmitDealFailed({
            Message: "CreateExchangeDeal Failed",
            Status: 500,
            StatusText: "CreateExchangeDeal Failed"
          })
        );
      }
    }
  } catch (error) {
    const e = error as any;
    yield put(SubmitDealFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

// watchers
export function* watchSubmitDeal() {
  yield throttle(1000, SUBMIT_DEAL_REQUEST, submitDeal);
}
export function* watchGetLogs() {
  yield takeLatest(GET_LOG_REQUEST, getLogs);
}
export function* watchGetDeals() {
  yield takeLatest(GET_DEALS_REQUEST, getDeals);
}
