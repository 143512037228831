import config from "../appConfig";

import { AccountInfo, AuthenticationResult, EventMessage, EventType, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";

export const loginRequest: RedirectRequest = {
  scopes: ["openid", "offline_access", `${config.Authentication.ClientId}/.default`]
};

const app = new PublicClientApplication({
  auth: {
    clientId: config.Authentication.ClientId || "",
    redirectUri: "/",
    azureCloudOptions: {
      azureCloudInstance: "https://login.microsoftonline.com",
      tenant: config.Authentication.TenantId || ""
    }
  },
  cache: {
    cacheLocation: "localStorage",
    secureCookies: !config.App.TestEnvironment
  },
  telemetry: {
    application: {
      appName: config.App.InsightsRole || "",
      appVersion: process.env.REACT_APP_BUILD_VERSION || "0.0"
    }
  }
});

// Error callback:
app.addEventCallback((ev: EventMessage) => {
  if (ev.error == null) return;
  console.error(ev.error);
});

// Success callback:
app.addEventCallback(c => {
  if (c.eventType === EventType.LOGIN_SUCCESS || c.eventType === EventType.SSO_SILENT_SUCCESS) {
    const result = c.payload as AuthenticationResult;
    const accountInfo: AccountInfo | null = result?.account || null;
    if (accountInfo != null) {
      app.setActiveAccount(accountInfo);
    }
  }
});

app
  .initialize()
  .then(() => app.handleRedirectPromise())
  .catch(error => {
    console.error(error);
  });

export { app as MsalApp };
