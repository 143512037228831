import {
  ProfileCreateRequestModel,
  ProfileCreateResponseModel,
  ProfileDeleteRequestResponseModel,
  ProfileDisplayPriorityGetResponseModel,
  ProfileExpandedGetResponseModel,
  ProfileExpandQueryModel,
  ProfileGetResponseModel,
  ProfileGetSummaryResponseModel,
  ProfileNormalisationsGetResponseModel,
  ProfileResolutionsGetResponseModel,
  ProfileSeasonsGetResponseModel,
  ProfileTypesGetResponseModel,
  ProfileUpdateRequestModel,
  ProfileUpdateResponseModel,
  ProfileUsageGetResponseModel
} from "sagas/api";
import { IApiError, IApiResponse } from "../types/config";

export { rootSaga } from "../controllers/root";

export const CREATE_PROFILE_REQUEST_V2 = "PROFILES/CREATE_PROFILE_REQUEST_V2";
export const CREATE_PROFILE_SUCCEEDED_V2 = "PROFILES/CREATE_PROFILE_SUCCEEDED_V2";
export const CREATE_PROFILE_FAILED_V2 = "PROFILES/CREATE_PROFILE_FAILED_V2";
export const CREATE_PROFILE_CLEAR_V2 = "PROFILES/CREATE_PROFILE_CLEAR_V2";

export const GET_SUMMARY_REQUEST_V2 = "PROFILES/GET_SUMMARY_REQUEST_V2";
export const GET_SUMMARY_SUCCEEDED_V2 = "PROFILES/GET_SUMMARY_SUCCEEDED_V2";
export const GET_SUMMARY_FAILED_V2 = "PROFILES/GET_SUMMARY_FAILED_V2";

export const GET_PROFILE_REQUEST_V2 = "PROFILES/GET_PROFILE_REQUEST_V2";
export const GET_PROFILE_SUCCEEDED_V2 = "PROFILES/GET_PROFILE_SUCCEEDED_V2";
export const GET_PROFILE_FAILED_V2 = "PROFILES/GET_PROFILE_FAILED_V2";
export const CLEAR_PROFILE_V2 = "PROFILES/CLEAR_PROFILE_V2";

export const UPDATE_PROFILE_REQUEST_V2 = "PROFILES/UPDATE_PROFILE_REQUEST_V2";
export const UPDATE_PROFILE_SUCCEEDED_V2 = "PROFILES/UPDATE_PROFILE_SUCCEEDED_V2";
export const UPDATE_PROFILE_FAILED_V2 = "PROFILES/UPDATE_PROFILE_FAILED_V2";

export const DELETE_PROFILE_REQUEST_REQUEST_V2 = "PROFILES/DELETE_PROFILE_REQUEST_REQUEST_V2";
export const DELETE_PROFILE_REQUEST_SUCCEEDED_V2 = "PROFILES/DELETE_PROFILE_REQUEST_SUCCEEDED_V2";
export const DELETE_PROFILE_REQUEST_FAILED_V2 = "PROFILES/DELETE_PROFILE_REQUEST_FAILED_V2";

export const DELETE_PROFILE_REQUEST_V2 = "PROFILES/DELETE_PROFILE_REQUEST_V2";
export const DELETE_PROFILE_SUCCEEDED_V2 = "PROFILES/DELETE_PROFILE_SUCCEEDED_V2";
export const DELETE_PROFILE_FAILED_V2 = "PROFILES/DELETE_PROFILE_FAILED_V2";

export const GET_PROFILE_RESOLUTIONS_REQUEST_V2 = "PROFILES/GET_PROFILE_RESOLUTIONS_REQUEST_V2";
export const GET_PROFILE_RESOLUTIONS_SUCCEEDED_V2 = "PROFILES/GET_PROFILE_RESOLUTIONS_SUCCEEDED_V2";
export const GET_PROFILE_RESOLUTIONS_FAILED_V2 = "PROFILES/GET_PROFILE_RESOLUTIONS_FAILED_V2";

export const GET_PROFILE_SEASONS_REQUEST_V2 = "PROFILES/GET_PROFILE_SEASONS_REQUEST_V2";
export const GET_PROFILE_SEASONS_SUCCEEDED_V2 = "PROFILES/GET_PROFILE_SEASONS_SUCCEEDED_V2";
export const GET_PROFILE_SEASONS_FAILED_V2 = "PROFILES/GET_PROFILE_SEASONS_FAILED_V2";

export const GET_PROFILE_TYPES_REQUEST_V2 = "PROFILES/GET_PROFILE_TYPES_REQUEST_V2";
export const GET_PROFILE_TYPES_SUCCEEDED_V2 = "PROFILES/GET_PROFILE_TYPES_SUCCEEDED_V2";
export const GET_PROFILE_TYPES_FAILED_V2 = "PROFILES/GET_PROFILE_TYPES_FAILED_V2";

export const GET_PROFILE_NORMALISATIONS_REQUEST_V2 = "PROFILES/GET_PROFILE_NORMALISATIONS_REQUEST_V2";
export const GET_PROFILE_NORMALISATIONS_SUCCEEDED_V2 = "PROFILES/GET_PROFILE_NORMALISATIONS_SUCCEEDED_V2";
export const GET_PROFILE_NORMALISATIONS_FAILED_V2 = "PROFILES/GET_PROFILE_NORMALISATIONS_FAILED_V2";

export const GET_DISPLAY_PRIORITIES_REQUEST_V2 = "PROFILES/GET_DISPLAY_PRIORITIES_REQUEST_V2";
export const GET_DISPLAY_PRIORITIES_SUCCEEDED_V2 = "PROFILES/GET_DISPLAY_PRIORITIES_SUCCEEDED_V2";
export const GET_DISPLAY_PRIORITIES_FAILED_V2 = "PROFILES/GET_DISPLAY_PRIORITIES_FAILED_V2";

export const GET_PROFILE_USAGES_REQUEST_V2 = "PROFILES/GET_PROFILE_USAGES_REQUEST_V2";
export const GET_PROFILE_USAGES_SUCCEEDED_V2 = "PROFILES/GET_PROFILE_USAGES_SUCCEEDED_V2";
export const GET_PROFILE_USAGES_FAILED_V2 = "PROFILES/GET_PROFILE_USAGES_FAILED_V2";

export const GET_PROFILE_EXPAND_REQUEST_V2 = "PROFILES/GET_PROFILE_EXPAND_REQUEST_V2";
export const GET_PROFILE_EXPAND_SUCCEEDED_V2 = "PROFILES/GET_PROFILE_EXPAND_SUCCEEDED_V2";
export const GET_PROFILE_EXPAND_FAILED_V2 = "PROFILES/GET_PROFILE_EXPAND_FAILED_V2";
export const GET_PROFILE_EXPAND_CLEAR_V2 = "PROFILES/GET_PROFILE_EXPAND_CLEAR_V2";

export interface ProfileBaseAction<T> {
  type: string;
  payload?: T;
}

// action creators
export const CreateProfileRequest = (model: ProfileCreateRequestModel): ProfileBaseAction<ProfileCreateRequestModel> => ({
  type: CREATE_PROFILE_REQUEST_V2,
  payload: model
});

export const CreateProfileSuccess = (data: ProfileCreateResponseModel): ProfileBaseAction<ProfileCreateResponseModel> => ({
  type: CREATE_PROFILE_SUCCEEDED_V2,
  payload: data
});

export const CreateProfileFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: CREATE_PROFILE_FAILED_V2,
  payload: message
});

export const CreateProfileClear = (model: boolean): ProfileBaseAction<boolean> => ({
  type: CREATE_PROFILE_CLEAR_V2,
  payload: model
});

export const GetSummaryRequest = (model: string | null | boolean): ProfileBaseAction<string | null | boolean> => ({
  type: GET_SUMMARY_REQUEST_V2,
  payload: model
});

export const GetSummarySuccess = (data: ProfileGetSummaryResponseModel): ProfileBaseAction<ProfileGetSummaryResponseModel> => ({
  type: GET_SUMMARY_SUCCEEDED_V2,
  payload: data
});

export const GetSummaryFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_SUMMARY_FAILED_V2,
  payload: message
});

export const GetProfileRequest = (model: string | null): ProfileBaseAction<string | null> => ({
  type: GET_PROFILE_REQUEST_V2,
  payload: model
});

export const ClearProfile = (model: string | null): ProfileBaseAction<string | null> => ({
  type: CLEAR_PROFILE_V2,
  payload: model
});

export const GetProfileSuccess = (data: ProfileGetResponseModel): ProfileBaseAction<ProfileGetResponseModel> => ({
  type: GET_PROFILE_SUCCEEDED_V2,
  payload: data
});

export const GetProfileFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_PROFILE_FAILED_V2,
  payload: message
});

export const UpdateProfileRequest = (p: {
  profileId: string | null;
  model: ProfileUpdateRequestModel;
}): ProfileBaseAction<{ profileId: string | null; model: ProfileUpdateRequestModel }> => ({
  type: UPDATE_PROFILE_REQUEST_V2,
  payload: p
});

export const UpdateProfileSuccess = (data: ProfileUpdateResponseModel): ProfileBaseAction<ProfileUpdateResponseModel> => ({
  type: UPDATE_PROFILE_SUCCEEDED_V2,
  payload: data
});

export const UpdateProfileFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: UPDATE_PROFILE_FAILED_V2,
  payload: message
});

export const DeleteProfileRequestRequest = (profileId: string | null): ProfileBaseAction<string | null> => ({
  type: DELETE_PROFILE_REQUEST_REQUEST_V2,
  payload: profileId
});

export const DeleteProfileRequestSuccess = (
  data: ProfileDeleteRequestResponseModel | null
): ProfileBaseAction<ProfileDeleteRequestResponseModel | null> => ({
  type: DELETE_PROFILE_REQUEST_SUCCEEDED_V2,
  payload: data
});

export const DeleteProfileRequestFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: DELETE_PROFILE_REQUEST_FAILED_V2,
  payload: message
});

export const DeleteProfileRequest = (p: {
  profileId: string | null;
  authKey: string | null;
}): ProfileBaseAction<{ profileId: string | null; authKey: string | null }> => ({
  type: DELETE_PROFILE_REQUEST_V2,
  payload: p
});

export const DeleteProfileSuccess = (data: IApiResponse | null): ProfileBaseAction<IApiResponse | null> => ({
  type: DELETE_PROFILE_SUCCEEDED_V2,
  payload: data
});

export const DeleteProfileFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: DELETE_PROFILE_FAILED_V2,
  payload: message
});

export const GetProfileSeasonsRequest = (profileId: string | null): ProfileBaseAction<string | null> => ({
  type: GET_PROFILE_SEASONS_REQUEST_V2,
  payload: profileId
});

export const GetProfileSeasonsSuccess = (data: ProfileSeasonsGetResponseModel): ProfileBaseAction<ProfileSeasonsGetResponseModel> => ({
  type: GET_PROFILE_SEASONS_SUCCEEDED_V2,
  payload: data
});

export const GetProfileSeasonsFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_PROFILE_SEASONS_FAILED_V2,
  payload: message
});

export const GetProfileTypesRequest = (profileId: string | null): ProfileBaseAction<string | null> => ({
  type: GET_PROFILE_TYPES_REQUEST_V2,
  payload: profileId
});

export const GetProfileTypesSuccess = (data: ProfileTypesGetResponseModel): ProfileBaseAction<ProfileTypesGetResponseModel> => ({
  type: GET_PROFILE_TYPES_SUCCEEDED_V2,
  payload: data
});

export const GetProfileTypesFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_PROFILE_TYPES_FAILED_V2,
  payload: message
});

export const GetProfileResolutionsRequest = (profileId: string | null): ProfileBaseAction<string | null> => ({
  type: GET_PROFILE_RESOLUTIONS_REQUEST_V2,
  payload: profileId
});

export const GetProfileResolutionsSuccess = (
  data: ProfileResolutionsGetResponseModel
): ProfileBaseAction<ProfileResolutionsGetResponseModel> => ({
  type: GET_PROFILE_RESOLUTIONS_SUCCEEDED_V2,
  payload: data
});

export const GetProfileResolutionsFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_PROFILE_RESOLUTIONS_FAILED_V2,
  payload: message
});

export const GetProfileNormalisationsRequest = (profileId: string | null): ProfileBaseAction<string | null> => ({
  type: GET_PROFILE_NORMALISATIONS_REQUEST_V2,
  payload: profileId
});

export const GetProfileNormalisationsSuccess = (
  data: ProfileNormalisationsGetResponseModel
): ProfileBaseAction<ProfileNormalisationsGetResponseModel> => ({
  type: GET_PROFILE_NORMALISATIONS_SUCCEEDED_V2,
  payload: data
});

export const GetProfileNormalisationsFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_PROFILE_NORMALISATIONS_FAILED_V2,
  payload: message
});

export const GetDisplayPrioritiesRequest = (profileId: string | null): ProfileBaseAction<string | null> => ({
  type: GET_DISPLAY_PRIORITIES_REQUEST_V2,
  payload: profileId
});

export const GetDisplayPrioritiesSuccess = (
  data: ProfileDisplayPriorityGetResponseModel
): ProfileBaseAction<ProfileDisplayPriorityGetResponseModel> => ({
  type: GET_DISPLAY_PRIORITIES_SUCCEEDED_V2,
  payload: data
});

export const GetDisplayPrioritiesFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_DISPLAY_PRIORITIES_FAILED_V2,
  payload: message
});

export const GetProfileUsagesRequest = (profileId: string | null): ProfileBaseAction<string | null> => ({
  type: GET_PROFILE_USAGES_REQUEST_V2,
  payload: profileId
});

export const GetProfileUsagesSuccess = (data: ProfileUsageGetResponseModel): ProfileBaseAction<ProfileUsageGetResponseModel> => ({
  type: GET_PROFILE_USAGES_SUCCEEDED_V2,
  payload: data
});

export const GetProfileUsagesFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_PROFILE_USAGES_FAILED_V2,
  payload: message
});

export const GetProfileExpandedRequest = (profileId: ProfileExpandQueryModel): ProfileBaseAction<ProfileExpandQueryModel> => ({
  type: GET_PROFILE_EXPAND_REQUEST_V2,
  payload: profileId
});

export const GetProfileExpandedSuccess = (data: ProfileExpandedGetResponseModel): ProfileBaseAction<ProfileExpandedGetResponseModel> => ({
  type: GET_PROFILE_EXPAND_SUCCEEDED_V2,
  payload: data
});

export const GetProfileExpandedFailed = (message: IApiError): ProfileBaseAction<IApiError> => ({
  type: GET_PROFILE_EXPAND_FAILED_V2,
  payload: message
});

export const GetProfileExpandedClear = (message: boolean | null): ProfileBaseAction<boolean | null> => ({
  type: GET_PROFILE_EXPAND_CLEAR_V2,
  payload: message
});

// reducer
export interface ProfilesSagaState {
  ProfileExpanded?: {
    request?: ProfileExpandQueryModel;
    reply?: ProfileExpandedGetResponseModel;
    error?: IApiError;
  };
  ProfileUsages?: {
    request?: string | null;
    reply?: ProfileUsageGetResponseModel;
    error?: IApiError;
  };
  ProfileDisplayPriorities?: {
    request?: string | null;
    reply?: ProfileDisplayPriorityGetResponseModel;
    error?: IApiError;
  };
  ProfileNormalisations?: {
    request?: string | null;
    reply?: ProfileNormalisationsGetResponseModel;
    error?: IApiError;
  };
  ProfileResolutions?: {
    request?: string | null;
    reply?: ProfileResolutionsGetResponseModel;
    error?: IApiError;
  };
  ProfileTypes?: {
    request?: string | null;
    reply?: ProfileTypesGetResponseModel;
    error?: IApiError;
  };
  ProfileSeasons?: {
    request?: string | null;
    reply?: ProfileSeasonsGetResponseModel;
    error?: IApiError;
  };
  ProfileUpdate?: {
    request?: { profileId: string | null; model: ProfileUpdateRequestModel };
    reply?: ProfileUpdateResponseModel;
    error?: IApiError;
  };
  Profiles?: {
    request?: string | null;
    reply?: ProfileGetResponseModel;
    error?: IApiError;
  };
  ProfileGetSummary?: {
    request?: string | null;
    reply?: ProfileGetSummaryResponseModel;
    error?: IApiError;
  };
  ProfileCreate?: {
    request?: ProfileCreateRequestModel;
    reply?: ProfileCreateResponseModel;
    error?: IApiError;
  };
  DeleteProfile?: {
    request?: string | null;
    reply?: IApiResponse;
    error?: IApiError;
  };
  DeleteProfileRequest?: {
    request?: string | null;
    reply?: ProfileDeleteRequestResponseModel;
    error?: IApiError;
  };
}

const initState: ProfilesSagaState = {};

export const ProfilesReducer = (state: ProfilesSagaState = initState, action: ProfileBaseAction<any>) => {
  switch (action.type) {
    case CREATE_PROFILE_REQUEST_V2:
      return { ...state, ProfileCreate: { request: action.payload } };
    case CREATE_PROFILE_SUCCEEDED_V2:
      return { ...state, ProfileCreate: { request: state.ProfileCreate?.request, reply: action.payload } };
    case CREATE_PROFILE_FAILED_V2:
      return { ...state, ProfileCreate: { request: state.ProfileCreate?.request, error: action.payload } };
    case CREATE_PROFILE_CLEAR_V2:
      return { ...state, ProfileCreate: undefined };
    case GET_SUMMARY_REQUEST_V2:
      return state.ProfileGetSummary?.request != null && action.payload !== true
        ? state
        : { ...state, ProfileGetSummary: { request: action.payload || {}, reply: undefined } };
    case GET_SUMMARY_SUCCEEDED_V2:
      return {
        ...state,
        ProfileGetSummary: { request: state.ProfileGetSummary?.request, reply: action.payload as ProfileGetSummaryResponseModel }
      };
    case GET_SUMMARY_FAILED_V2:
      return { ...state, ProfileGetSummary: { request: state.ProfileGetSummary?.request, error: action.payload } };
    case GET_PROFILE_REQUEST_V2:
      return { ...state, Profiles: { request: action.payload } };
    case CLEAR_PROFILE_V2:
      return { ...state, Profiles: { request: null, reply: null } };
    case GET_PROFILE_SUCCEEDED_V2:
      return { ...state, Profiles: { request: state.Profiles?.request, reply: action.payload } };
    case GET_PROFILE_FAILED_V2:
      return { ...state, Profiles: { request: state.Profiles?.request, error: action.payload } };
    case UPDATE_PROFILE_REQUEST_V2:
      return { ...state, ProfileUpdate: { request: action.payload } };
    case UPDATE_PROFILE_SUCCEEDED_V2:
      return { ...state, ProfileUpdate: { request: state.ProfileUpdate?.request, reply: action.payload } };
    case UPDATE_PROFILE_FAILED_V2:
      return { ...state, ProfileUpdate: { request: state.ProfileUpdate?.request, error: action.payload } };
    case DELETE_PROFILE_REQUEST_REQUEST_V2:
      return { ...state, DeleteProfileRequest: { request: action.payload } };
    case DELETE_PROFILE_REQUEST_SUCCEEDED_V2:
      return {
        ...state,
        DeleteProfileRequest: { request: state.DeleteProfileRequest?.request, reply: action.payload }
      };
    case DELETE_PROFILE_REQUEST_FAILED_V2:
      return {
        ...state,
        DeleteProfileRequest: { request: state.DeleteProfileRequest?.request, error: action.payload }
      };
    case DELETE_PROFILE_REQUEST_V2:
      return { ...state, DeleteProfile: { request: action.payload } };
    case DELETE_PROFILE_SUCCEEDED_V2:
      return { ...state, DeleteProfile: { request: state.DeleteProfile?.request, reply: action.payload } };
    case DELETE_PROFILE_FAILED_V2:
      return { ...state, DeleteProfile: { request: state.DeleteProfile?.request, error: action.payload } };
    case GET_PROFILE_RESOLUTIONS_REQUEST_V2:
      return { ...state, ProfileResolutions: { request: action.payload } };
    case GET_PROFILE_RESOLUTIONS_SUCCEEDED_V2:
      return { ...state, ProfileResolutions: { request: state.ProfileResolutions?.request, reply: action.payload } };
    case GET_PROFILE_RESOLUTIONS_FAILED_V2:
      return { ...state, ProfileResolutions: { request: state.ProfileResolutions?.request, error: action.payload } };
    case GET_PROFILE_SEASONS_REQUEST_V2:
      return { ...state, ProfileSeasons: { request: action.payload } };
    case GET_PROFILE_SEASONS_SUCCEEDED_V2:
      return { ...state, ProfileSeasons: { request: state.ProfileSeasons?.request, reply: action.payload } };
    case GET_PROFILE_SEASONS_FAILED_V2:
      return { ...state, ProfileSeasons: { request: state.ProfileSeasons?.request, error: action.payload } };
    case GET_PROFILE_TYPES_REQUEST_V2:
      return { ...state, ProfileTypes: { request: action.payload } };
    case GET_PROFILE_TYPES_SUCCEEDED_V2:
      return { ...state, ProfileTypes: { request: state.ProfileTypes?.request, reply: action.payload } };
    case GET_PROFILE_TYPES_FAILED_V2:
      return { ...state, ProfileTypes: { request: state.ProfileTypes?.request, error: action.payload } };
    case GET_PROFILE_NORMALISATIONS_REQUEST_V2:
      return { ...state, ProfileNormalisations: { request: action.payload } };
    case GET_PROFILE_NORMALISATIONS_SUCCEEDED_V2:
      return {
        ...state,
        ProfileNormalisations: { request: state.ProfileNormalisations?.request, reply: action.payload }
      };
    case GET_PROFILE_NORMALISATIONS_FAILED_V2:
      return {
        ...state,
        ProfileNormalisations: { request: state.ProfileNormalisations?.request, error: action.payload }
      };
    case GET_DISPLAY_PRIORITIES_REQUEST_V2:
      return { ...state, ProfileDisplayPriorities: { request: action.payload } };
    case GET_DISPLAY_PRIORITIES_SUCCEEDED_V2:
      return {
        ...state,
        ProfileDisplayPriorities: { request: state.ProfileDisplayPriorities?.request, reply: action.payload }
      };
    case GET_DISPLAY_PRIORITIES_FAILED_V2:
      return {
        ...state,
        ProfileDisplayPriorities: { request: state.ProfileDisplayPriorities?.request, error: action.payload }
      };
    case GET_PROFILE_USAGES_REQUEST_V2:
      return { ...state, ProfileUsages: { request: action.payload } };
    case GET_PROFILE_USAGES_SUCCEEDED_V2:
      return { ...state, ProfileUsages: { request: state.ProfileUsages?.request, reply: action.payload } };
    case GET_PROFILE_USAGES_FAILED_V2:
      return { ...state, ProfileUsages: { request: state.ProfileUsages?.request, error: action.payload } };
    case GET_PROFILE_EXPAND_REQUEST_V2:
      return { ...state, ProfileExpanded: { request: action.payload } };
    case GET_PROFILE_EXPAND_SUCCEEDED_V2:
      return { ...state, ProfileExpanded: { request: state.ProfileExpanded?.request, reply: action.payload } };
    case GET_PROFILE_EXPAND_FAILED_V2:
      return { ...state, ProfileExpanded: { request: state.ProfileExpanded?.request, error: action.payload } };
    case GET_PROFILE_EXPAND_CLEAR_V2:
      return { ...state, ProfileExpanded: undefined };
  }

  return state;
};
