import { Month, Quarter, Strip } from "internal/dcc-types/enums";
import { MonthCode, Expiry, ExpiryConverter, ReverseMonthCode } from "internal/dcc-types/dates";
import moment from "moment";

export const DateService = {
  lateEntryDays: 0,
  getOptionExpiry(date: Date) {
    const dayPreceding = moment(date).add(-1, "days");
    let lastTradingDay = moment(dayPreceding).add(-6, "weeks");

    if (lastTradingDay.toDate().getDay() === 6 || lastTradingDay.toDate().getDay() === 0) {
      // 6 = Saturday, 0 = Sunday
      lastTradingDay = lastTradingDay.add(1, "days");
    }
    if (lastTradingDay.toDate().getDay() === 6 || lastTradingDay.toDate().getDay() === 0) {
      // 6 = Saturday, 0 = Sunday
      lastTradingDay = lastTradingDay.add(1, "days");
    }

    return lastTradingDay.toDate();
  },
  getPeriodDates(q: Quarter, s: Strip, y: number, m?: Month | null, tradingDate?: Date | null) {
    let from = new Date();
    let to = new Date();
    let message = "";
    if (m && m !== Month.Undefined) {
      const month = ReverseMonthCode.get(m) || 1;
      from = moment({ year: y, month: month - 1, day: 1 })
        .startOf("month")
        .toDate();
      to = moment({ year: y, month: month - 1, day: 1 })
        .endOf("month")
        .toDate();
    } else if (s !== Strip.Undefined) {
      if (s === Strip.FIN) {
        from = new Date(y - 1, 6, 1);
        to = new Date(y, 5, 30);
      } else if (s === Strip.CAL) {
        from = new Date(y, 0, 1);
        to = new Date(y, 11, 31);
      }
    } else if (q !== Quarter.Undefined) {
      if (q === Quarter.Q1) {
        from = new Date(y, 0, 1);
        to = new Date(y, 2, 31);
      } else if (q === Quarter.Q2) {
        from = new Date(y, 3, 1);
        to = new Date(y, 5, 30);
      } else if (q === Quarter.Q3) {
        from = new Date(y, 6, 1);
        to = new Date(y, 8, 30);
      } else if (q === Quarter.Q4) {
        from = new Date(y, 9, 1);
        to = new Date(y, 11, 31);
      }
    }

    // Date changed to balance of period
    if (tradingDate && moment(tradingDate).isAfter(from) && moment(tradingDate).isBefore(to)) {
      from = moment(tradingDate).add(1, "days").toDate();
      message = "Date changed to balance of period";
    }

    return [from, to, message];
  },
  isCalTradable(date: Date, year: number) {
    var endQ1 = new Date(year, 2, 31);
    date.setHours(0, 0, 0, 0);
    if (date > endQ1) {
      return false;
    }
    return true;
  },

  isFinTradable(date: Date, year: number) {
    var endQ3 = new Date(year - 1, 8, 30);
    date.setHours(0, 0, 0, 0);
    if (date > endQ3) {
      return false;
    }
    return true;
  },

  getASXTradableQuarters(date: Date) {
    let quarters = [2, 5, 8, 11];
    date.setHours(0, 0, 0, 0);
    let cutoffDates = quarters
      .map(q => {
        var cutoff = new Date(date.getFullYear(), q + 1, 0);
        var d = this.getLastTradingDay(cutoff);
        return new Date(date.getFullYear(), q, d);
      })
      .map(d => {
        d.setDate(d.getDate() + this.lateEntryDays);
        return d;
      });

    if (date <= cutoffDates[0]) {
      return [Quarter.Q1, Quarter.Q2, Quarter.Q3, Quarter.Q4];
    }
    if (cutoffDates[0] < date && date <= cutoffDates[1]) {
      return [Quarter.Q2, Quarter.Q3, Quarter.Q4];
    }
    if (cutoffDates[1] < date && date <= cutoffDates[2]) {
      return [Quarter.Q3, Quarter.Q4];
    }
    if (cutoffDates[2] < date && date <= cutoffDates[3]) {
      return [Quarter.Q4];
    }
    if (cutoffDates[3] < date) {
      return [];
    }
  },

  getTradableQuarters(date: Date) {
    let quarters = [2, 5, 8, 11];
    date.setHours(0, 0, 0, 0);
    let cutoffDates = quarters
      .map(q => {
        var cutoff = new Date(date.getFullYear(), q + 1, 0);
        var d = this.getLastTradingDay(cutoff);
        return new Date(date.getFullYear(), q, d);
      })
      .map(d => {
        d.setDate(d.getDate() + this.lateEntryDays);
        return d;
      });

    if (date < cutoffDates[0]) {
      return [Quarter.Q1, Quarter.Q2, Quarter.Q3, Quarter.Q4];
    }
    if (cutoffDates[0] <= date && date < cutoffDates[1]) {
      return [Quarter.Q2, Quarter.Q3, Quarter.Q4];
    }
    if (cutoffDates[1] <= date && date < cutoffDates[2]) {
      return [Quarter.Q3, Quarter.Q4];
    }
    if (cutoffDates[2] <= date && date < cutoffDates[3]) {
      return [Quarter.Q4];
    }
    if (cutoffDates[3] <= date) {
      return [];
    }
  },

  getYearCode(date: Date) {
    return date.getFullYear().toString().substr(-2);
  },

  rollMonth(date: Date) {
    var next = new Date();
    if (date.getMonth() === 11) {
      next = new Date(date.getFullYear() + 1, 0, 1);
    } else {
      next = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }
    return next;
  },

  getLastTradingDay(date: Date) {
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var lastTradingDay = lastDayOfMonth.getDate();

    // 6 = Saturday, 0 = Sunday
    if (lastDayOfMonth.getDay() === 6) {
      return lastTradingDay - 1;
    }
    if (lastDayOfMonth.getDay() === 0) {
      return lastTradingDay - 2;
    }

    return lastTradingDay;
  },

  getFutureExpiryDates(date: Date, n: number) {
    let result: Expiry[] = [];

    let allowLateEntry = 0;
    let y = date.getFullYear();
    let m = date.getMonth();

    date = new Date(y, date.getMonth(), date.getDate());
    // date.setDate(date.getDate() - this.lateEntryDays);

    if (date >= new Date(y, 0, 1) && date <= new Date(y, 2, 31)) {
      date = new Date(date.getFullYear(), 0 + allowLateEntry, 1);
    } else if (date >= new Date(y, 3, 1) && date <= new Date(y, 5, 30)) {
      date = new Date(date.getFullYear(), 3 + allowLateEntry, 1);
    } else if (date >= new Date(y, 6, 1) && date <= new Date(y, 8, 30)) {
      date = new Date(date.getFullYear(), 6 + allowLateEntry, 1);
    } else if (date >= new Date(y, 9, 1) && date <= new Date(y, 11, 31)) {
      date = new Date(date.getFullYear(), 9 + allowLateEntry, 1);
    }

    const steps = Array.from(Array(n).keys());
    result = steps
      .map(s => {
        const nextMonth = date.getMonth() + s;
        if (nextMonth === 12) {
          return new Date(date.getFullYear() + 1, 0, 1);
        } else {
          return new Date(date.getFullYear(), nextMonth, 1);
        }
      })
      .map(d => {
        return {
          Year: ExpiryConverter.toShortYear(d.getFullYear()),
          Month: MonthCode.get(d.getMonth() + 1) || Month.JAN,
          Enabled: (d.getFullYear() === y && d.getMonth() >= m) || d.getFullYear() > y
        };
      });

    return result;
  },

  getCreationYears(date: Date, up: number, down: number) {
    let result: number[] = [];
    let current = date.getFullYear();

    for (let i = down; i > 0; i--) {
      const nxt = current - i;
      result.push(nxt);
    }

    for (let i = 0; i < up; i++) {
      const nxt = current + i;
      result.push(nxt);
    }

    return result;
  },
  getFutureYears(date: Date, n: number, otc: boolean = false) {
    let result: number[] = [];
    let current = date.getFullYear();
    // last trading day
    //if (date.getMonth() === 11 &&  DateService.getLastTradingDay(date) <= date.getDate()){
    //    current += 1;
    //}

    let allowLateEntry = 0;
    if (!otc && date.getDate() <= this.lateEntryDays && date.getMonth() === 0) {
      allowLateEntry = -1;
    }
    current = current + allowLateEntry;

    for (let i = 0; i < n; i++) {
      result[i] = current;
      current += 1;
    }

    return result;
  }
};
