/* tslint:disable */
/* eslint-disable */
/**
 * EnergyEdge.MarketEdge.DealCapture.Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "./common";
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI } from "./base";
import { MarketDealIntermediariesApi } from "api/intermediariesApiClient";

/**
 *
 * @export
 * @enum {string}
 */

export enum AllowedGroups {
  Frontoffice = "FRONTOFFICE",
  Middleoffice = "MIDDLEOFFICE",
  Backoffice = "BACKOFFICE",
  Appadmin = "APPADMIN",
  Useradmin = "USERADMIN"
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ApiResponseStatus {
  Success = "Success",
  Failed = "Failed",
  Error = "Error"
}

/**
 *
 * @export
 * @interface AuditLogModel
 */
export interface AuditLogModel {
  /**
   *
   * @type {number}
   * @memberof AuditLogModel
   */
  AuditLogId?: number;
  /**
   *
   * @type {number}
   * @memberof AuditLogModel
   */
  UserId?: number;
  /**
   *
   * @type {string}
   * @memberof AuditLogModel
   */
  UserFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditLogModel
   */
  UserLastName?: string | null;
  /**
   *
   * @type {number}
   * @memberof AuditLogModel
   */
  DealId?: number;
  /**
   *
   * @type {string}
   * @memberof AuditLogModel
   */
  DealReference?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditLogModel
   */
  CreatedDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditLogModel
   */
  Action?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditLogModel
   */
  Description?: string | null;
}
/**
 *
 * @export
 * @interface BrokerModel
 */
export interface BrokerModel {
  /**
   *
   * @type {KeyedValue}
   * @memberof BrokerModel
   */
  Name: KeyedValue;
  /**
   *
   * @type {boolean}
   * @memberof BrokerModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BrokerModel
   */
  EnabledOnASX?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof BrokerModel
   */
  EnabledOnOTC?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof BrokerModel
   */
  EnabledOnGSH?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof BrokerModel
   */
  EnabledOnENV?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof BrokerModel
   */
  EnabledOnFEX?: boolean | null;
  /**
   *
   * @type {Array<ExchangeFee>}
   * @memberof BrokerModel
   */
  ASXFees?: Array<ExchangeFee> | null;
  /**
   *
   * @type {Array<ExchangeFee>}
   * @memberof BrokerModel
   */
  FEXFees?: Array<ExchangeFee> | null;
  /**
   *
   * @type {Array<ExchangeFee>}
   * @memberof BrokerModel
   */
  AEMOFees?: Array<ExchangeFee> | null;
  /**
   *
   * @type {Array<Fee>}
   * @memberof BrokerModel
   */
  EPFees?: Array<Fee> | null;
  /**
   *
   * @type {Array<Fee>}
   * @memberof BrokerModel
   */
  SRAFees?: Array<Fee> | null;
  /**
   *
   * @type {Array<OTCFee>}
   * @memberof BrokerModel
   */
  OTCFees?: Array<OTCFee> | null;
}
/**
 *
 * @export
 * @interface Cap
 */
export interface Cap {
  /**
   *
   * @type {number}
   * @memberof Cap
   */
  Strike?: number | null;
  /**
   *
   * @type {number}
   * @memberof Cap
   */
  Premium?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Cap
   */
  PaidAcrossPeriod?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Cap
   */
  UpfrontDate?: string | null;
}
/**
 *
 * @export
 * @interface ClearerAccountModel
 */
export interface ClearerAccountModel {
  /**
   *
   * @type {number}
   * @memberof ClearerAccountModel
   */
  Id: number;
  /**
   *
   * @type {string}
   * @memberof ClearerAccountModel
   */
  Name: string;
  /**
   *
   * @type {number}
   * @memberof ClearerAccountModel
   */
  SortOrder?: number | null;
  /**
   *
   * @type {string}
   * @memberof ClearerAccountModel
   */
  CloseoutMethodology?: MarketDealIntermediariesApi.CloseoutMethodologyType | null;
}
/**
 *
 * @export
 * @interface ClearerModel
 */
export interface ClearerModel {
  /**
   *
   * @type {KeyedValue}
   * @memberof ClearerModel
   */
  Name: KeyedValue;
  /**
   *
   * @type {boolean}
   * @memberof ClearerModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ClearerModel
   */
  EnabledOnASX?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ClearerModel
   */
  EnabledOnFEX?: boolean | null;
  /**
   *
   * @type {Array<ExchangeFee>}
   * @memberof ClearerModel
   */
  ASXFees?: Array<ExchangeFee> | null;
  /**
   *
   * @type {Array<ExchangeFee>}
   * @memberof ClearerModel
   */
  FEXFees?: Array<ExchangeFee> | null;
  /**
   *
   * @type {Array<ClearerAccountModel>}
   * @memberof ClearerModel
   */
  Accounts?: Array<ClearerAccountModel> | null;
}
/**
 *
 * @export
 * @interface Collar
 */
export interface Collar {
  /**
   *
   * @type {string}
   * @memberof Collar
   */
  OptionPremiumPaymentDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Collar
   */
  ExpiryDate?: string | null;
  /**
   *
   * @type {CollarLeg}
   * @memberof Collar
   */
  LongLeg?: CollarLeg;
  /**
   *
   * @type {CollarLeg}
   * @memberof Collar
   */
  ShortLeg?: CollarLeg;
  /**
   *
   * @type {boolean}
   * @memberof Collar
   */
  PaidAcrossPeriod?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Collar
   */
  UpfrontDate?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Collar
   */
  AmericanOption?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Collar
   */
  EuropeanOption?: boolean | null;
}
/**
 *
 * @export
 * @interface CollarLeg
 */
export interface CollarLeg {
  /**
   *
   * @type {number}
   * @memberof CollarLeg
   */
  Premium?: number | null;
  /**
   *
   * @type {number}
   * @memberof CollarLeg
   */
  Strike?: number | null;
}
/**
 *
 * @export
 * @interface CommentModel
 */
export interface CommentModel {
  /**
   *
   * @type {number}
   * @memberof CommentModel
   */
  CommentId?: number;
  /**
   *
   * @type {number}
   * @memberof CommentModel
   */
  UserId?: number;
  /**
   *
   * @type {string}
   * @memberof CommentModel
   */
  UserFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommentModel
   */
  UserLastName?: string | null;
  /**
   *
   * @type {number}
   * @memberof CommentModel
   */
  DealId?: number;
  /**
   *
   * @type {string}
   * @memberof CommentModel
   */
  DealReference?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommentModel
   */
  CreatedDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommentModel
   */
  Message?: string | null;
}
/**
 *
 * @export
 * @interface ConfigModel
 */
export interface ConfigModel {
  /**
   *
   * @type {string}
   * @memberof ConfigModel
   */
  LoggedOnUserEmail?: string | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof ConfigModel
   */
  LoggedOnUser?: KeyedValue;
  /**
   *
   * @type {Array<EnabledKeyedValue>}
   * @memberof ConfigModel
   */
  Books?: Array<EnabledKeyedValue> | null;
  /**
   *
   * @type {Array<KeyedValue>}
   * @memberof ConfigModel
   */
  Traders?: Array<KeyedValue> | null;
  /**
   *
   * @type {Array<CounterpartyModel>}
   * @memberof ConfigModel
   */
  Counterparties?: Array<CounterpartyModel> | null;
  /**
   *
   * @type {Array<KeyedValue>}
   * @memberof ConfigModel
   */
  FirmnessDependencies?: Array<KeyedValue> | null;
  /**
   *
   * @type {Array<EnabledKeyedValue>}
   * @memberof ConfigModel
   */
  EnvironExclusions?: Array<EnabledKeyedValue> | null;
  /**
   *
   * @type {Array<EnabledKeyedValue>}
   * @memberof ConfigModel
   */
  EnvironCreationYears?: Array<EnabledKeyedValue> | null;
  /**
   *
   * @type {Array<EnvironValue>}
   * @memberof ConfigModel
   */
  EnvironProducts?: Array<EnvironValue> | null;
}
/**
 *
 * @export
 * @interface ContractPeriodModel
 */
export interface ContractPeriodModel {
  /**
   *
   * @type {string}
   * @memberof ContractPeriodModel
   */
  From: string;
  /**
   *
   * @type {string}
   * @memberof ContractPeriodModel
   */
  To: string;

  VolumeProfileTotal?: number | null;
  VolumeProfileAverage?: number | null;
  PriceProfileTimeWeightedAverage?: number | null;
  PriceProfileVolumeWeightedAverage?: number | null;
  MissingPriceForVolume?: boolean | null;
}
/**
 *
 * @export
 * @interface CounterpartyContactModel
 */
export interface CounterpartyContactModel {
  /**
   *
   * @type {number}
   * @memberof CounterpartyContactModel
   */
  Id?: number;
  /**
   *
   * @type {string}
   * @memberof CounterpartyContactModel
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyContactModel
   */
  SecondName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyContactModel
   */
  Position?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyContactModel
   */
  ContactPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyContactModel
   */
  ContactMobile?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyContactModel
   */
  ContactEmail?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyContactModel
   */
  Trader?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyContactModel
   */
  SettlementOfficer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyContactModel
   */
  ContactElectricityTraded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyContactModel
   */
  ContactEnvironTraded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyContactModel
   */
  ContactSRATraded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyContactModel
   */
  ContactGasTraded?: boolean;
  /**
   *
   * @type {number}
   * @memberof CounterpartyContactModel
   */
  SortOrder?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyContactModel
   */
  Enabled?: boolean;
}
/**
 *
 * @export
 * @interface CounterpartyCreditModel
 */
export interface CounterpartyCreditModel {
  /**
   *
   * @type {number}
   * @memberof CounterpartyCreditModel
   */
  Id?: number;
  /**
   *
   * @type {string}
   * @memberof CounterpartyCreditModel
   */
  CreditRating?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyCreditModel
   */
  RatingsAgency?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyCreditModel
   */
  AgreementType?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyCreditModel
   */
  AgreementDate?: string;
  /**
   *
   * @type {string}
   * @memberof CounterpartyCreditModel
   */
  AustaClearCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyCreditModel
   */
  CSA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyCreditModel
   */
  ElectricityNetted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyCreditModel
   */
  EnvironNetted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyCreditModel
   */
  SRANetted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyCreditModel
   */
  GasNetted?: boolean;
}
/**
 *
 * @export
 * @interface CounterpartyModel
 */
export interface CounterpartyModel {
  /**
   *
   * @type {number}
   * @memberof CounterpartyModel
   */
  Id?: number;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  Name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  FullName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  AddressLine1?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  AddressLine2?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  State?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  PostCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  Country?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  Phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  Email?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  ACN?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  ABN?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  REC?: string | null;
  /**
   *
   * @type {string}
   * @memberof CounterpartyModel
   */
  CounterpartyCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyModel
   */
  ElectricityTraded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyModel
   */
  EnvironTraded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyModel
   */
  SRATraded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyModel
   */
  GasTraded?: boolean;
  /**
   *
   * @type {number}
   * @memberof CounterpartyModel
   */
  SortOrder?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof CounterpartyModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {Array<CounterpartyContactModel>}
   * @memberof CounterpartyModel
   */
  CounterpartyContact?: Array<CounterpartyContactModel> | null;
  /**
   *
   * @type {CounterpartyCreditModel}
   * @memberof CounterpartyModel
   */
  CounterpartyCredit?: CounterpartyCreditModel;
}
/**
 *
 * @export
 * @interface CustomProfileModel
 */
export interface CustomProfileModel {
  /**
   *
   * @type {number}
   * @memberof CustomProfileModel
   */
  CustomProfileId?: number;
  /**
   *
   * @type {string}
   * @memberof CustomProfileModel
   */
  Name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CustomProfileModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {Array<ProfileDetailsModel>}
   * @memberof CustomProfileModel
   */
  Details?: Array<ProfileDetailsModel> | null;
}
/**
 *
 * @export
 * @interface DayModel
 */
export interface DayModel {
  /**
   *
   * @type {number}
   * @memberof DayModel
   */
  DayPeriodId?: number;
  /**
   *
   * @type {number}
   * @memberof DayModel
   */
  DayId?: number;
  /**
   *
   * @type {string}
   * @memberof DayModel
   */
  Name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayModel
   */
  Enabled?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DayModel
   */
  IncludePubHol?: boolean | null;
}
/**
 *
 * @export
 * @interface DayTypeCreateRequestDayModel
 */
export interface DayTypeCreateRequestDayModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeCreateRequestDayModel
   */
  DayIdentifier?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayTypeCreateRequestDayModel
   */
  IncludePublicHolidays?: boolean | null;
}
/**
 *
 * @export
 * @interface DayTypeCreateRequestModel
 */
export interface DayTypeCreateRequestModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeCreateRequestModel
   */
  DayTypeName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayTypeCreateRequestModel
   */
  Enabled?: boolean | null;
  /**
   *
   * @type {Array<DayTypeCreateRequestDayModel>}
   * @memberof DayTypeCreateRequestModel
   */
  Days?: Array<DayTypeCreateRequestDayModel> | null;
}
/**
 *
 * @export
 * @interface DayTypeCreateResponseModel
 */
export interface DayTypeCreateResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeCreateResponseModel
   */
  DayTypeIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeCreateResponseModel
   */
  Path?: string | null;
}
/**
 *
 * @export
 * @interface DayTypeCreateResponseModelIApiResponse
 */
export interface DayTypeCreateResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DayTypeCreateResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DayTypeCreateResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {DayTypeCreateResponseModel}
   * @memberof DayTypeCreateResponseModelIApiResponse
   */
  Body?: DayTypeCreateResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof DayTypeCreateResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface DayTypeDaysDayResponseModel
 */
export interface DayTypeDaysDayResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeDaysDayResponseModel
   */
  DayIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeDaysDayResponseModel
   */
  DayName?: string | null;
}
/**
 *
 * @export
 * @interface DayTypeDaysResponseModel
 */
export interface DayTypeDaysResponseModel {
  /**
   *
   * @type {Array<DayTypeDaysDayResponseModel>}
   * @memberof DayTypeDaysResponseModel
   */
  Days?: Array<DayTypeDaysDayResponseModel> | null;
}
/**
 *
 * @export
 * @interface DayTypeDaysResponseModelIApiResponse
 */
export interface DayTypeDaysResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DayTypeDaysResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DayTypeDaysResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {DayTypeDaysResponseModel}
   * @memberof DayTypeDaysResponseModelIApiResponse
   */
  Body?: DayTypeDaysResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof DayTypeDaysResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface DayTypeDeleteRequestResponseModel
 */
export interface DayTypeDeleteRequestResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeDeleteRequestResponseModel
   */
  DayTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeDeleteRequestResponseModel
   */
  AuthorisationKey?: string | null;
}
/**
 *
 * @export
 * @interface DayTypeDeleteRequestResponseModelIApiResponse
 */
export interface DayTypeDeleteRequestResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DayTypeDeleteRequestResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DayTypeDeleteRequestResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {DayTypeDeleteRequestResponseModel}
   * @memberof DayTypeDeleteRequestResponseModelIApiResponse
   */
  Body?: DayTypeDeleteRequestResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof DayTypeDeleteRequestResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface DayTypeGetDayResponseModel
 */
export interface DayTypeGetDayResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeGetDayResponseModel
   */
  DayIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeGetDayResponseModel
   */
  DayName?: string | null;
}
/**
 *
 * @export
 * @interface DayTypeGetOublicHolidayModifiersResponseModel
 */
export interface DayTypeGetOublicHolidayModifiersResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeGetOublicHolidayModifiersResponseModel
   */
  ModifierIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeGetOublicHolidayModifiersResponseModel
   */
  Name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DayTypeGetOublicHolidayModifiersResponseModel
   */
  Description?: string | null;
}
/**
 *
 * @export
 * @interface DayTypeGetDayTypeDayResponseModel
 */
export interface DayTypeGetDayTypeDayResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeGetDayTypeDayResponseModel
   */
  DayIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeGetDayTypeDayResponseModel
   */
  DayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayTypeGetDayTypeDayResponseModel
   */
  IncludePublicHolidays?: boolean;
}
/**
 *
 * @export
 * @interface DayTypeGetDayTypeResponseModel
 */
export interface DayTypeGetDayTypeResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeGetDayTypeResponseModel
   */
  DayTypeIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeGetDayTypeResponseModel
   */
  DayTypeName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayTypeGetDayTypeResponseModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {Array<DayTypeGetDayTypeDayResponseModel>}
   * @memberof DayTypeGetDayTypeResponseModel
   */
  Days?: Array<DayTypeGetDayTypeDayResponseModel> | null;

  PublicHolidayModifier?: string | DayTypeGetOublicHolidayModifiersResponseModel | null;
}
/**
 *
 * @export
 * @interface DayTypeGetResponseModel
 */
export interface DayTypeGetResponseModel {
  /**
   *
   * @type {Array<DayTypeGetDayTypeResponseModel>}
   * @memberof DayTypeGetResponseModel
   */
  DayTypes?: Array<DayTypeGetDayTypeResponseModel> | null;
  /**
   *
   * @type {Array<DayTypeGetDayResponseModel>}
   * @memberof DayTypeGetResponseModel
   */
  AllDays?: Array<DayTypeGetDayResponseModel> | null;
  AllPublicHolidayModifiers?: Array<DayTypeGetOublicHolidayModifiersResponseModel> | null;
}

/**
 *
 * @export
 * @interface DayTypeGetResponseModelIApiResponse
 */
export interface DayTypeGetResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DayTypeGetResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DayTypeGetResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {DayTypeGetResponseModel}
   * @memberof DayTypeGetResponseModelIApiResponse
   */
  Body?: DayTypeGetResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof DayTypeGetResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface DayTypeGetSingularDayResponseModel
 */
export interface DayTypeGetSingularDayResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeGetSingularDayResponseModel
   */
  DayIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeGetSingularDayResponseModel
   */
  DayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayTypeGetSingularDayResponseModel
   */
  IncludePublicHolidays?: boolean;
}
/**
 *
 * @export
 * @interface DayTypeGetSingularResponseModel
 */
export interface DayTypeGetSingularResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeGetSingularResponseModel
   */
  DayTypeIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeGetSingularResponseModel
   */
  DayTypeName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayTypeGetSingularResponseModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {Array<DayTypeGetSingularDayResponseModel>}
   * @memberof DayTypeGetSingularResponseModel
   */
  Days?: Array<DayTypeGetSingularDayResponseModel> | null;

  PublicHolidayModifier?: string | DayTypeGetOublicHolidayModifiersResponseModel | null;
}
/**
 *
 * @export
 * @interface DayTypeGetSingularResponseModelIApiResponse
 */
export interface DayTypeGetSingularResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DayTypeGetSingularResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DayTypeGetSingularResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {DayTypeGetSingularResponseModel}
   * @memberof DayTypeGetSingularResponseModelIApiResponse
   */
  Body?: DayTypeGetSingularResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof DayTypeGetSingularResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface DayTypeUpdateRequestDayModel
 */
export interface DayTypeUpdateRequestDayModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeUpdateRequestDayModel
   */
  DayIdentifier?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayTypeUpdateRequestDayModel
   */
  IncludePublicHolidays?: boolean | null;
}
/**
 *
 * @export
 * @interface DayTypeUpdateRequestModel
 */
export interface DayTypeUpdateRequestModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeUpdateRequestModel
   */
  DayTypeIdentifier?: string | null;
  /**
   *
   * @type {string}
   * @memberof DayTypeUpdateRequestModel
   */
  DayTypeName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DayTypeUpdateRequestModel
   */
  Enabled?: boolean | null;
  /**
   *
   * @type {Array<DayTypeUpdateRequestDayModel>}
   * @memberof DayTypeUpdateRequestModel
   */
  Days?: Array<DayTypeUpdateRequestDayModel> | null;
}
/**
 *
 * @export
 * @interface DayTypeUpdateResponseModel
 */
export interface DayTypeUpdateResponseModel {
  /**
   *
   * @type {string}
   * @memberof DayTypeUpdateResponseModel
   */
  DayTypeIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof DayTypeUpdateResponseModel
   */
  Path?: string | null;
}
/**
 *
 * @export
 * @interface DayTypeUpdateResponseModelIApiResponse
 */
export interface DayTypeUpdateResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DayTypeUpdateResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DayTypeUpdateResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {DayTypeUpdateResponseModel}
   * @memberof DayTypeUpdateResponseModelIApiResponse
   */
  Body?: DayTypeUpdateResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof DayTypeUpdateResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface DealModel
 */
export interface DealModel {
  /**
   *
   * @type {number}
   * @memberof DealModel
   */
  DealId?: number;
  /**
   *
   * @type {string}
   * @memberof DealModel
   */
  DealReference?: string | null;

  MarketId?: string;
  MarketName?: string;
  CurrencyCode: string;
  /**
   *
   * @type {string}
   * @memberof DealModel
   */
  TradeDate?: string;
  /**
   *
   * @type {DealType}
   * @memberof DealModel
   */
  DealType?: DealType;
  /**
   *
   * @type {FutureModel}
   * @memberof DealModel
   */
  FutureModel?: FutureModel;
  /**
   *
   * @type {Array<DealModelLeg>}
   * @memberof DealModel
   */
  DealModelLegs?: Array<DealModelLeg> | null;
  /**
   *
   * @type {OptionModel}
   * @memberof DealModel
   */
  OptionModel?: OptionModel;
  /**
   *
   * @type {Swap}
   * @memberof DealModel
   */
  Swap?: Swap;
  /**
   *
   * @type {Cap}
   * @memberof DealModel
   */
  Cap?: Cap;
  /**
   *
   * @type {Floor}
   * @memberof DealModel
   */
  Floor?: Floor;
  /**
   *
   * @type {Swaption}
   * @memberof DealModel
   */
  Swaption?: Swaption;
  /**
   *
   * @type {Collar}
   * @memberof DealModel
   */
  Collar?: Collar;
  /**
   *
   * @type {EnvironModel}
   * @memberof DealModel
   */
  EnvironModel?: EnvironModel;
  /**
   *
   * @type {GasSupplyHubDealModel}
   * @memberof DealModel
   */
  GasSupplyHubDealModel?: GasSupplyHubDealModel;
  /**
   *
   * @type {InstrumentType}
   * @memberof DealModel
   */
  InstrumentType?: InstrumentType;
  /**
   *
   * @type {OtcInstrumentType}
   * @memberof DealModel
   */
  OtcInstrumentType?: OtcInstrumentType;
  /**
   *
   * @type {EPInstrumentType}
   * @memberof DealModel
   */
  EPInstrumentType?: EPInstrumentType;
  /**
   *
   * @type {SRAInstrumentType}
   * @memberof DealModel
   */
  SRAInstrumentType?: SRAInstrumentType;
  /**
   *
   * @type {GSHInstrumentType}
   * @memberof DealModel
   */
  GSHInstrumentType?: GSHInstrumentType;
  /**
   *
   * @type {string}
   * @memberof DealModel
   */
  Status?: string | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  Book?: KeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  AuthorisedTrader?: KeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  DealEntryTrader?: KeyedValue;
  /**
   *
   * @type {string}
   * @memberof DealModel
   */
  LinkedDeal?: string | null;
  /**
   *
   * @type {string}
   * @memberof DealModel
   */
  Strategy?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DealModel
   */
  ExcludeCredit?: boolean | null;
  /**
   *
   * @type {Side}
   * @memberof DealModel
   */
  BuySell?: Side;
  /**
   *
   * @type {string}
   * @memberof DealModel
   */
  ProductCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof DealModel
   */
  Price?: number;
  /**
   *
   * @type {number}
   * @memberof DealModel
   */
  Volume?: number;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  Broker?: KeyedValue;
  /**
   *
   * @type {number}
   * @memberof DealModel
   */
  BrokerageRate?: number | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  BrokerageRateUnit?: KeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  Clearer?: KeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  ClearingAccount?: KeyedValue;
  /**
   *
   * @type {number}
   * @memberof DealModel
   */
  ClearingFee?: number | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  ClearingFeeUnit?: KeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  Exchange?: KeyedValue;
  /**
   *
   * @type {number}
   * @memberof DealModel
   */
  ExchangeFee?: number | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  ExchangeFeeUnit?: KeyedValue;
  /**
   *
   * @type {number}
   * @memberof DealModel
   */
  BlockExchangeFee?: number | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  BlockExchangeFeeUnit?: KeyedValue;
  /**
   *
   * @type {number}
   * @memberof DealModel
   */
  EFPFee?: number | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  EFPFeeUnit?: KeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  FirmnessDependency?: KeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  Counterparty?: KeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof DealModel
   */
  CounterpartyContact?: KeyedValue;
  /**
   *
   * @type {string}
   * @memberof DealModel
   */
  CounterpartyReference?: string | null;
  /**
   *
   * @type {string}
   * @memberof DealModel
   */
  BrokerReference?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DealModel
   */
  AFMA?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DealModel
   */
  LFC?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DealModel
   */
  ISDA?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DealModel
   */
  EditRef?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DealModel
   */
  EditBrokerRef?: boolean | null;
  /**
   *
   * @type {Array<Link>}
   * @memberof DealModel
   */
  Links?: Array<Link> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof DealModel
   */
  LinkedEFT?: Array<string> | null;
}
/**
 *
 * @export
 * @interface DealModelLeg
 */
export interface DealModelLeg {
  /**
   *
   * @type {number}
   * @memberof DealModelLeg
   */
  DealLegId?: number;
  /**
   *
   * @type {FutureModel}
   * @memberof DealModelLeg
   */
  FutureModel?: FutureModel;
  /**
   *
   * @type {string}
   * @memberof DealModelLeg
   */
  ProductCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof DealModelLeg
   */
  Price?: number;
  /**
   *
   * @type {number}
   * @memberof DealModelLeg
   */
  Volume?: number;
}
/**
 *
 * @export
 * @interface DealModelResult
 */
export interface DealModelResult {
  /**
   *
   * @type {number}
   * @memberof DealModelResult
   */
  DealId?: number;
  /**
   *
   * @type {string}
   * @memberof DealModelResult
   */
  DealReference?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum DealType {
  Asx = "ASX",
  Otc = "OTC",
  Ep = "EP",
  Sra = "SRA",
  Fex = "FEX",
  Gsh = "GSH"
}

/**
 *
 * @export
 * @enum {string}
 */

export enum DeliveryPeriod {
  TodayNonNetted = "TodayNonNetted",
  TomorrowNonNetted = "TomorrowNonNetted",
  TomorrowNetted = "TomorrowNetted",
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly"
}

/**
 *
 * @export
 * @enum {string}
 */

export enum EPInstrumentType {
  Spot = "SPOT",
  Forward = "FORWARD",
  Option = "OPTION"
}

/**
 *
 * @export
 * @interface EnabledKeyedValue
 */
export interface EnabledKeyedValue {
  /**
   *
   * @type {number}
   * @memberof EnabledKeyedValue
   */
  Id?: number;
  /**
   *
   * @type {string}
   * @memberof EnabledKeyedValue
   */
  Value?: string | null;
  /**
   *
   * @type {number}
   * @memberof EnabledKeyedValue
   */
  SortOrder?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof EnabledKeyedValue
   */
  Enabled?: boolean | null;
}
/**
 *
 * @export
 * @interface EnvironModel
 */
export interface EnvironModel {
  /**
   *
   * @type {number}
   * @memberof EnvironModel
   */
  EnvironProductId?: number | null;
  /**
   *
   * @type {string}
   * @memberof EnvironModel
   */
  EnvironProduct?: string | null;
  /**
   *
   * @type {number}
   * @memberof EnvironModel
   */
  CreationYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof EnvironModel
   */
  ExcludedSourceId?: number | null;
  /**
   *
   * @type {string}
   * @memberof EnvironModel
   */
  ExcludedSource?: string | null;
  /**
   *
   * @type {number}
   * @memberof EnvironModel
   */
  GenerationYear?: number | null;
  /**
   *
   * @type {string}
   * @memberof EnvironModel
   */
  SettlementDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof EnvironModel
   */
  DeliveryDate?: string | null;
  /**
   *
   * @type {number}
   * @memberof EnvironModel
   */
  OptionTypeId?: number | null;
  /**
   *
   * @type {string}
   * @memberof EnvironModel
   */
  OptionType?: string | null;
  /**
   *
   * @type {string}
   * @memberof EnvironModel
   */
  OptionExpiryDate?: string | null;
  /**
   *
   * @type {number}
   * @memberof EnvironModel
   */
  OptionPremium?: number | null;
  /**
   *
   * @type {string}
   * @memberof EnvironModel
   */
  OptionPremiumPaymentDate?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof EnvironModel
   */
  AmericanOption?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof EnvironModel
   */
  EuropeanOption?: boolean | null;

  CurrencyCode?: string;
}
/**
 *
 * @export
 * @interface EnvironValue
 */
export interface EnvironValue {
  /**
   *
   * @type {number}
   * @memberof EnvironValue
   */
  Id: number;
  /**
   *
   * @type {string}
   * @memberof EnvironValue
   */
  Value: string;
  /**
   *
   * @type {number}
   * @memberof EnvironValue
   */
  SortOrder?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof EnvironValue
   */
  CreationYear?: boolean | null;
  CurrencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof EnvironValue
   */
  CreationYearMessage?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof EnvironValue
   */
  GenerationYear?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof EnvironValue
   */
  Enabled?: boolean | null;
}
/**
 *
 * @export
 * @interface ExchangeFee
 */
export interface ExchangeFee {
  /**
   *
   * @type {number}
   * @memberof ExchangeFee
   */
  Id: number;
  /**
   *
   * @type {number}
   * @memberof ExchangeFee
   */
  Price: number;
  /**
   *
   * @type {string}
   * @memberof ExchangeFee
   */
  ProductName: string;
  /**
   *
   * @type {Period}
   * @memberof ExchangeFee
   */
  Period: Period;
  /**
   *
   * @type {KeyedValue}
   * @memberof ExchangeFee
   */
  UnitOfMeasure: KeyedValue;
  /**
   *
   * @type {string}
   * @memberof ExchangeFee
   */
  EffectiveDate: string;
  /**
   *
   * @type {number}
   * @memberof ExchangeFee
   */
  SortOrder?: number | null;
}
/**
 *
 * @export
 * @interface ExchangeModel
 */
export interface ExchangeModel {
  /**
   *
   * @type {KeyedValue}
   * @memberof ExchangeModel
   */
  Name: KeyedValue;
  /**
   *
   * @type {Array<ExchangeFee>}
   * @memberof ExchangeModel
   */
  Fees?: Array<ExchangeFee> | null;
}
/**
 *
 * @export
 * @interface ExchangeStripProductBreakdownRequesttModel
 */
export interface ExchangeStripProductBreakdownRequesttModel {
  /**
   *
   * @type {string}
   * @memberof ExchangeStripProductBreakdownRequesttModel
   */
  ProductCode: string;
  /**
   *
   * @type {string}
   * @memberof ExchangeStripProductBreakdownRequesttModel
   */
  EffectiveDate: string;
  /**
   *
   * @type {number}
   * @memberof ExchangeStripProductBreakdownRequesttModel
   */
  Price: number;
  /**
   *
   * @type {number}
   * @memberof ExchangeStripProductBreakdownRequesttModel
   */
  Volume: number;
  /**
   *
   * @type {string}
   * @memberof ExchangeStripProductBreakdownRequesttModel
   */
  Exchange?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ExchangeStripProductBreakdownRequesttModel
   */
  ThirtyMinutes?: boolean | null;
}
/**
 *
 * @export
 * @interface ExercisedLegModel
 */
export interface ExercisedLegModel {
  /**
   *
   * @type {number}
   * @memberof ExercisedLegModel
   */
  LegId?: number;
  /**
   *
   * @type {string}
   * @memberof ExercisedLegModel
   */
  Ticker: string;
  /**
   *
   * @type {number}
   * @memberof ExercisedLegModel
   */
  Volume: number;
  /**
   *
   * @type {number}
   * @memberof ExercisedLegModel
   */
  Price: number;
  /**
   *
   * @type {number}
   * @memberof ExercisedLegModel
   */
  Premium?: number;
  /**
   *
   * @type {number}
   * @memberof ExercisedLegModel
   */
  Hours?: number;
  /**
   *
   * @type {number}
   * @memberof ExercisedLegModel
   */
  OptionType?: number | null;
}
/**
 *
 * @export
 * @interface ExercisedOption
 */
export interface ExercisedOption {
  /**
   *
   * @type {string}
   * @memberof ExercisedOption
   */
  Action?: string | null;
  /**
   *
   * @type {number}
   * @memberof ExercisedOption
   */
  DealId?: number | null;
  /**
   *
   * @type {string}
   * @memberof ExercisedOption
   */
  ExerciseDate?: string;
  /**
   *
   * @type {Array<ExercisedLegModel>}
   * @memberof ExercisedOption
   */
  Legs?: Array<ExercisedLegModel> | null;
  /**
   *
   * @type {DealType}
   * @memberof ExercisedOption
   */
  DealType?: DealType;
}
/**
 *
 * @export
 * @interface ExpiryModel
 */
export interface ExpiryModel {
  /**
   *
   * @type {number}
   * @memberof ExpiryModel
   */
  Month?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExpiryModel
   */
  Year?: number | null;
}
/**
 *
 * @export
 * @interface Fee
 */
export interface Fee {
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  Id: number;
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  Price: number;
  /**
   *
   * @type {string}
   * @memberof Fee
   */
  ProductName: string;
  /**
   *
   * @type {KeyedValue}
   * @memberof Fee
   */
  UnitOfMeasure: KeyedValue;
  /**
   *
   * @type {string}
   * @memberof Fee
   */
  EffectiveDate: string;
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  SortOrder?: number | null;
}
/**
 *
 * @export
 * @interface FeesModel
 */
export interface FeesModel {
  /**
   *
   * @type {Array<BrokerModel>}
   * @memberof FeesModel
   */
  Brokers: Array<BrokerModel>;
  /**
   *
   * @type {Array<ClearerModel>}
   * @memberof FeesModel
   */
  Clearers: Array<ClearerModel>;
  /**
   *
   * @type {ExchangeModel}
   * @memberof FeesModel
   */
  ASX?: ExchangeModel;
  /**
   *
   * @type {ExchangeModel}
   * @memberof FeesModel
   */
  FEX?: ExchangeModel;
  /**
   *
   * @type {ExchangeModel}
   * @memberof FeesModel
   */
  AEMO?: ExchangeModel;
}
/**
 *
 * @export
 * @interface Floor
 */
export interface Floor {
  /**
   *
   * @type {number}
   * @memberof Floor
   */
  Strike?: number | null;
  /**
   *
   * @type {number}
   * @memberof Floor
   */
  Premium?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Floor
   */
  PaidAcrossPeriod?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Floor
   */
  UpfrontDate?: string | null;
}
/**
 *
 * @export
 * @interface ForgotModel
 */
export interface ForgotModel {
  /**
   *
   * @type {string}
   * @memberof ForgotModel
   */
  Email: string;
}
/**
 *
 * @export
 * @interface ForgotUpdateModel
 */
export interface ForgotUpdateModel {
  /**
   *
   * @type {string}
   * @memberof ForgotUpdateModel
   */
  Email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ForgotUpdateModel
   */
  Token?: string | null;
  /**
   *
   * @type {string}
   * @memberof ForgotUpdateModel
   */
  Password?: string | null;
  /**
   *
   * @type {string}
   * @memberof ForgotUpdateModel
   */
  ConfirmPassword?: string | null;
}
/**
 *
 * @export
 * @interface FromToTimeSpanModel
 */
export interface FromToTimeSpanModel {
  /**
   *
   * @type {TimeSpanModel}
   * @memberof FromToTimeSpanModel
   */
  From?: TimeSpanModel;
  /**
   *
   * @type {TimeSpanModel}
   * @memberof FromToTimeSpanModel
   */
  To?: TimeSpanModel;
  /**
   *
   * @type {number}
   * @memberof FromToTimeSpanModel
   */
  ProfileLegMappingId?: number;
}
/**
 *
 * @export
 * @interface FutureModel
 */
export interface FutureModel {
  /**
   *
   * @type {Region}
   * @memberof FutureModel
   */
  Region?: Region;
  /**
   *
   * @type {Period}
   * @memberof FutureModel
   */
  Period?: Period;
  /**
   *
   * @type {ProductType}
   * @memberof FutureModel
   */
  ProductType?: ProductType;
  /**
   *
   * @type {ExpiryModel}
   * @memberof FutureModel
   */
  Expiry?: ExpiryModel;
  /**
   *
   * @type {string}
   * @memberof FutureModel
   */
  Ticker?: string | null;
  /**
   *
   * @type {Quarter}
   * @memberof FutureModel
   */
  Quarter?: Quarter;
  /**
   *
   * @type {Strip}
   * @memberof FutureModel
   */
  Strip?: Strip;
  /**
   *
   * @type {number}
   * @memberof FutureModel
   */
  Hours?: number | null;
  /**
   *
   * @type {number}
   * @memberof FutureModel
   */
  CapStrike?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum GSHInstrumentType {
  Forward = "FORWARD",
  Spread = "SPREAD"
}

/**
 *
 * @export
 * @interface GSHTrade
 */
export interface GSHTrade {
  /**
   *
   * @type {number}
   * @memberof GSHTrade
   */
  DealId?: number;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  TradeDate?: string | null;
  /**
   *
   * @type {Side}
   * @memberof GSHTrade
   */
  Side?: Side;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  ProductLocation?: string | null;
  /**
   *
   * @type {DeliveryPeriod}
   * @memberof GSHTrade
   */
  DeliveryPeriod?: DeliveryPeriod;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  FromGasDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  ToGasDate?: string | null;
  /**
   *
   * @type {number}
   * @memberof GSHTrade
   */
  Quantity?: number;
  /**
   *
   * @type {number}
   * @memberof GSHTrade
   */
  Price?: number;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  ProductType?: string | null;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  DeliveryPoint?: string | null;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  ReceiptionPoint?: string | null;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  TradeType?: string | null;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  NettedNonNetted?: string | null;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  PhysicalParty?: string | null;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  SpreadProductLocation?: string | null;
  /**
   *
   * @type {number}
   * @memberof GSHTrade
   */
  SpreadDealId?: number | null;
  /**
   *
   * @type {string}
   * @memberof GSHTrade
   */
  SpreadProductType?: string | null;
}
/**
 *
 * @export
 * @interface GasSupplyHubDealModel
 */
export interface GasSupplyHubDealModel {
  /**
   *
   * @type {number}
   * @memberof GasSupplyHubDealModel
   */
  GasSupplyHubDealId?: number;
  /**
   *
   * @type {number}
   * @memberof GasSupplyHubDealModel
   */
  AemoTradeId?: number;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  PhysicalParty?: string | null;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  ProductType?: string | null;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  ProductLocation?: string | null;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  TradeType?: string | null;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  FromGasDate?: string;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  ToGasDate?: string;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  DeliveryPoint?: string | null;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  ReceiptPoint?: string | null;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  DeliveryPeriod?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GasSupplyHubDealModel
   */
  Netted?: boolean;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  SpreadProductType?: string | null;
  /**
   *
   * @type {number}
   * @memberof GasSupplyHubDealModel
   */
  SpreadAemoTradeId?: number | null;
  /**
   *
   * @type {string}
   * @memberof GasSupplyHubDealModel
   */
  SpreadProductLocation?: string | null;
}
/**
 *
 * @export
 * @interface IErrorDetail
 */
export interface IErrorDetail {
  /**
   *
   * @type {string}
   * @memberof IErrorDetail
   */
  Code?: string | null;
  /**
   *
   * @type {string}
   * @memberof IErrorDetail
   */
  Message?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum InstrumentType {
  Future = "FUTURE",
  Cap = "CAP",
  Asian = "ASIAN",
  Option = "OPTION"
}

/**
 *
 * @export
 * @interface InterconnectorModel
 */
export interface InterconnectorModel {
  /**
   *
   * @type {number}
   * @memberof InterconnectorModel
   */
  Id: number;
  /**
   *
   * @type {string}
   * @memberof InterconnectorModel
   */
  Value: string;
  /**
   *
   * @type {string}
   * @memberof InterconnectorModel
   */
  From: string;
  /**
   *
   * @type {string}
   * @memberof InterconnectorModel
   */
  To: string;
  /**
   *
   * @type {number}
   * @memberof InterconnectorModel
   */
  SortOrder?: number | null;
}
/**
 *
 * @export
 * @interface KeyedValue
 */
export interface KeyedValue {
  /**
   *
   * @type {number}
   * @memberof KeyedValue
   */
  Id?: number;
  /**
   *
   * @type {string}
   * @memberof KeyedValue
   */
  Value?: string | null;
  /**
   *
   * @type {number}
   * @memberof KeyedValue
   */
  SortOrder?: number | null;
}
/**
 *
 * @export
 * @interface KeyedValue
 */
export interface StringKeyedValue {
  /**
   *
   * @type {number}
   * @memberof KeyedValue
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof KeyedValue
   */
  Value?: string | null;
  /**
   *
   * @type {number}
   * @memberof KeyedValue
   */
  SortOrder?: number | null;
}
/**
 *
 * @export
 * @interface Link
 */
export interface Link {
  /**
   *
   * @type {string}
   * @memberof Link
   */
  Href?: string | null;
  /**
   *
   * @type {string}
   * @memberof Link
   */
  Rel?: string | null;
  /**
   *
   * @type {string}
   * @memberof Link
   */
  Description?: string | null;
  /**
   *
   * @type {string}
   * @memberof Link
   */
  Method?: string | null;
  /**
   *
   * @type {number}
   * @memberof Link
   */
  DisplayOrder?: number;
  /**
   *
   * @type {string}
   * @memberof Link
   */
  ThemeColour?: string | null;
}
/**
 *
 * @export
 * @interface LoginModel
 */
export interface LoginModel {
  /**
   *
   * @type {string}
   * @memberof LoginModel
   */
  Email: string;
  /**
   *
   * @type {string}
   * @memberof LoginModel
   */
  Password: string;
}
/**
 *
 * @export
 * @interface MarketSettingsModel
 */
export interface MarketSettingsModel {
  /**
   *
   * @type {number}
   * @memberof MarketSettingsModel
   */
  OrganizationId: number;
  /**
   *
   * @type {string}
   * @memberof MarketSettingsModel
   */
  OrganizationName?: string | null;
  /**
   *
   * @type {Array<InterconnectorModel>}
   * @memberof MarketSettingsModel
   */
  Interconnectors?: Array<InterconnectorModel> | null;
  /**
   *
   * @type {Array<KeyedValue>}
   * @memberof MarketSettingsModel
   */
  FloorStrikes?: Array<KeyedValue> | null;
  /**
   *
   * @type {Array<KeyedValue>}
   * @memberof MarketSettingsModel
   */
  CapStrikes?: Array<KeyedValue> | null;
  /**
   *
   * @type {Array<KeyedValue>}
   * @memberof MarketSettingsModel
   */
  UnitOfMeasures?: Array<KeyedValue> | null;
}
/**
 *
 * @export
 * @interface OTCFee
 */
export interface OTCFee {
  /**
   *
   * @type {number}
   * @memberof OTCFee
   */
  Id: number;
  /**
   *
   * @type {number}
   * @memberof OTCFee
   */
  Price: number;
  /**
   *
   * @type {string}
   * @memberof OTCFee
   */
  ProductName: string;
  /**
   *
   * @type {string}
   * @memberof OTCFee
   */
  Condition?: string | null;
  /**
   *
   * @type {number}
   * @memberof OTCFee
   */
  Volume?: number | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof OTCFee
   */
  UnitOfMeasure: KeyedValue;
  /**
   *
   * @type {string}
   * @memberof OTCFee
   */
  EffectiveDate: string;
  /**
   *
   * @type {number}
   * @memberof OTCFee
   */
  SortOrder?: number | null;
}
/**
 *
 * @export
 * @interface ObjectIApiResponse
 */
export interface ObjectIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ObjectIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ObjectIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {any}
   * @memberof ObjectIApiResponse
   */
  Body?: any | null;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ObjectIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ObjectIApiResponse
 */
export interface TypedApiResponse<T> {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ObjectIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ObjectIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {any}
   * @memberof ObjectIApiResponse
   */
  Body?: T | null;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ObjectIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface OptionModel
 */
export interface OptionModel {
  /**
   *
   * @type {Region}
   * @memberof OptionModel
   */
  Region?: Region;
  /**
   *
   * @type {Period}
   * @memberof OptionModel
   */
  Period?: Period;
  /**
   *
   * @type {ProductType}
   * @memberof OptionModel
   */
  ProductType?: ProductType;
  /**
   *
   * @type {ExpiryModel}
   * @memberof OptionModel
   */
  Expiry?: ExpiryModel;
  /**
   *
   * @type {string}
   * @memberof OptionModel
   */
  Ticker?: string | null;
  /**
   *
   * @type {Quarter}
   * @memberof OptionModel
   */
  Quarter?: Quarter;
  /**
   *
   * @type {Strip}
   * @memberof OptionModel
   */
  Strip?: Strip;
  /**
   *
   * @type {OptionType}
   * @memberof OptionModel
   */
  OptionType?: OptionType;
  /**
   *
   * @type {number}
   * @memberof OptionModel
   */
  Strike?: number;
  /**
   *
   * @type {string}
   * @memberof OptionModel
   */
  OptionExpiry?: string | null;
  /**
   *
   * @type {number}
   * @memberof OptionModel
   */
  Hours?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof OptionModel
   */
  AmericanOption?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof OptionModel
   */
  EuropeanOption?: boolean | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum OptionType {
  Call = "CALL",
  Put = "PUT"
}

/**
 *
 * @export
 * @enum {string}
 */

export enum OtcInstrumentType {
  Swap = "SWAP",
  Cap = "CAP",
  Floor = "FLOOR",
  AsianOption = "ASIAN_OPTION",
  Swaption = "SWAPTION",
  Caption = "CAPTION",
  Floortion = "FLOORTION",
  SwaptionCollar = "SWAPTION_COLLAR",
  HalfHourlyCollar = "HALF_HOURLY_COLLAR",
  AsianCollar = "ASIAN_COLLAR"
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Period {
  Base = "Base",
  Peak = "Peak"
}

/**
 *
 * @export
 * @interface PeriodModel
 */
export interface PeriodModel {
  /**
   *
   * @type {number}
   * @memberof PeriodModel
   */
  PeriodId?: number;
  /**
   *
   * @type {string}
   * @memberof PeriodModel
   */
  Name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PeriodModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {Array<DayModel>}
   * @memberof PeriodModel
   */
  Days?: Array<DayModel> | null;
}
/**
 *
 * @export
 * @interface PermissionModel
 */
export interface PermissionModel {
  /**
   *
   * @type {number}
   * @memberof PermissionModel
   */
  PolicyId?: number;
  /**
   *
   * @type {string}
   * @memberof PermissionModel
   */
  PolicyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof PermissionModel
   */
  Scope?: string | null;
  /**
   *
   * @type {number}
   * @memberof PermissionModel
   */
  PermissionId?: number;
  /**
   *
   * @type {string}
   * @memberof PermissionModel
   */
  Name?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ProductType {
  MonthFutures = "MONTH_FUTURES",
  QuarterFutures = "QUARTER_FUTURES",
  FuturesStrips = "FUTURES_STRIPS",
  CapQuarters = "CAP_QUARTERS",
  CapStrips = "CAP_STRIPS",
  Options = "OPTIONS",
  Asians = "ASIANS"
}

/**
 *
 * @export
 * @interface ProfileCreateRequestIntervalModel
 */
export interface ProfileCreateRequestIntervalModel {
  /**
   *
   * @type {number}
   * @memberof ProfileCreateRequestIntervalModel
   */
  Interval?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProfileCreateRequestIntervalModel
   */
  Value?: number | null;
}
/**
 *
 * @export
 * @interface ProfileCreateRequestLegModel
 */
export interface ProfileCreateRequestLegModel {
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestLegModel
   */
  NormalisationStrategyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestLegModel
   */
  NormalisationStrategyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestLegModel
   */
  DayTypeId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestLegModel
   */
  DayTypeName?: string | null;
  /**
   *
   * @type {ProfileCreateRequestLegSeasonModel}
   * @memberof ProfileCreateRequestLegModel
   */
  Season?: ProfileCreateRequestLegSeasonModel;
  /**
   *
   * @type {Array<ProfileCreateRequestIntervalModel>}
   * @memberof ProfileCreateRequestLegModel
   */
  Intervals?: Array<ProfileCreateRequestIntervalModel> | null;
  /**
   *
   * @type {Array<ProfileCreateRequestRangeModel>}
   * @memberof ProfileCreateRequestLegModel
   */
  Ranges?: Array<ProfileCreateRequestRangeModel> | null;
}
/**
 *
 * @export
 * @interface ProfileCreateRequestLegSeasonModel
 */
export interface ProfileCreateRequestLegSeasonModel {
  /**
   *
   * @type {Array<string>}
   * @memberof ProfileCreateRequestLegSeasonModel
   */
  SeasonIds?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestLegSeasonModel
   */
  StartDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestLegSeasonModel
   */
  EndDate?: string | null;
}
/**
 *
 * @export
 * @interface ProfileCreateRequestModel
 */
export interface ProfileCreateRequestModel {
  ProfileTypeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestModel
   */
  ProfileName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileCreateRequestModel
   */
  IsActive?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestModel
   */
  ProfileTypeId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestModel
   */
  ResolutionId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileCreateRequestModel
   */
  Enabled?: boolean | null;
  /**
   *
   * @type {Array<ProfileCreateRequestLegModel>}
   * @memberof ProfileCreateRequestModel
   */
  Legs?: Array<ProfileCreateRequestLegModel> | null;
  /**
   *
   * @type {ProfileCreateRequestTraceModel}
   * @memberof ProfileCreateRequestModel
   */
  Trace?: ProfileCreateRequestTraceModel;
  /**
   *
   * @type {Array<ProfileCreateRequestUsageDisplayModel>}
   * @memberof ProfileCreateRequestModel
   */
  Priorities?: Array<ProfileCreateRequestUsageDisplayModel> | null;
}
/**
 *
 * @export
 * @interface ProfileCreateRequestRangeModel
 */
export interface ProfileCreateRequestRangeModel {
  /**
   *
   * @type {number}
   * @memberof ProfileCreateRequestRangeModel
   */
  StartInterval?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProfileCreateRequestRangeModel
   */
  EndInterval?: number | null;
}
/**
 *
 * @export
 * @interface ProfileCreateRequestTraceModel
 */
export interface ProfileCreateRequestTraceModel {
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestTraceModel
   */
  NormalisationId?: string | null;
  /**
   *
   * @type {Array<ProfileCreateRequestTraceIntervalModel>}
   * @memberof ProfileCreateRequestTraceModel
   */
  Intervals?: Array<ProfileCreateRequestIntervalModel> | null;
}
/**
 *
 * @export
 * @interface ProfileCreateRequestUsageDisplayModel
 */
export interface ProfileCreateRequestUsageDisplayModel {
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestUsageDisplayModel
   */
  UsageId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateRequestUsageDisplayModel
   */
  DisplayId?: string | null;
}
/**
 *
 * @export
 * @interface ProfileCreateResponseModel
 */
export interface ProfileCreateResponseModel {
  /**
   *
   * @type {string}
   * @memberof ProfileCreateResponseModel
   */
  ProfileIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateResponseModel
   */
  ProfileName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileCreateResponseModel
   */
  Path?: string | null;
}
/**
 *
 * @export
 * @interface ProfileCreateResponseModelIApiResponse
 */
export interface ProfileCreateResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileCreateResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileCreateResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileCreateResponseModel}
   * @memberof ProfileCreateResponseModelIApiResponse
   */
  Body?: ProfileCreateResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileCreateResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileDeleteRequestResponseModel
 */
export interface ProfileDeleteRequestResponseModel {
  /**
   *
   * @type {string}
   * @memberof ProfileDeleteRequestResponseModel
   */
  ProfileId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileDeleteRequestResponseModel
   */
  AuthorisationKey?: string | null;
}
/**
 *
 * @export
 * @interface ProfileDeleteRequestResponseModelIApiResponse
 */
export interface ProfileDeleteRequestResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileDeleteRequestResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileDeleteRequestResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileDeleteRequestResponseModel}
   * @memberof ProfileDeleteRequestResponseModelIApiResponse
   */
  Body?: ProfileDeleteRequestResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileDeleteRequestResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileDetailsModel
 */
export interface ProfileDetailsModel {
  /**
   *
   * @type {number}
   * @memberof ProfileDetailsModel
   */
  ProfileLegId?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  IsValidLeg?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Jan?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Feb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Mar?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Apr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  May?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Jun?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Jul?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Aug?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Sep?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Oct?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Nov?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileDetailsModel
   */
  Dec?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileDetailsModel
   */
  FromDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileDetailsModel
   */
  ToDate?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProfileDetailsModel
   */
  PeriodId?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileDetailsModel
   */
  ResolutionId?: number;
  /**
   *
   * @type {Array<FromToTimeSpanModel>}
   * @memberof ProfileDetailsModel
   */
  Spans?: Array<FromToTimeSpanModel> | null;
}
/**
 *
 * @export
 * @interface ProfileDisplayPriorityGetResponseModel
 */
export interface ProfileDisplayPriorityGetResponseModel {
  /**
   *
   * @type {Array<ProfileDisplayPriorityGetResponsePriorityModel>}
   * @memberof ProfileDisplayPriorityGetResponseModel
   */
  Priorities?: Array<ProfileDisplayPriorityGetResponsePriorityModel> | null;
}
/**
 *
 * @export
 * @interface ProfileDisplayPriorityGetResponseModelIApiResponse
 */
export interface ProfileDisplayPriorityGetResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileDisplayPriorityGetResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileDisplayPriorityGetResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileDisplayPriorityGetResponseModel}
   * @memberof ProfileDisplayPriorityGetResponseModelIApiResponse
   */
  Body?: ProfileDisplayPriorityGetResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileDisplayPriorityGetResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileDisplayPriorityGetResponsePriorityModel
 */
export interface ProfileDisplayPriorityGetResponsePriorityModel {
  /**
   *
   * @type {string}
   * @memberof ProfileDisplayPriorityGetResponsePriorityModel
   */
  PriorityIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileDisplayPriorityGetResponsePriorityModel
   */
  PriorityName?: string | null;
}
/**
 *
 * @export
 * @interface ProfileGetResponseIntervalModel
 */
export interface ProfileGetResponseIntervalModel {
  /**
   *
   * @type {number}
   * @memberof ProfileGetResponseIntervalModel
   */
  Interval?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileGetResponseIntervalModel
   */
  Value?: number;
}
/**
 *
 * @export
 * @interface ProfileGetResponseLegModel
 */
export interface ProfileGetResponseLegModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseLegModel
   */
  NormalisationStrategyId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseLegModel
   */
  NormalisationStrategyName?: string | null;
  NormalisationUnitOfMeasure?: NormalisationUnitOfMeasure;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseLegModel
   */
  DayTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseLegModel
   */
  DayTypeName?: string | null;
  /**
   *
   * @type {ProfileGetResponseLegSeasonModel}
   * @memberof ProfileGetResponseLegModel
   */
  Season?: ProfileGetResponseLegSeasonModel;
  /**
   *
   * @type {Array<ProfileGetResponseIntervalModel>}
   * @memberof ProfileGetResponseLegModel
   */
  Intervals?: Array<ProfileGetResponseIntervalModel> | null;
  /**
   *
   * @type {Array<ProfileGetResponseRangeModel>}
   * @memberof ProfileGetResponseLegModel
   */
  Ranges?: Array<ProfileGetResponseRangeModel> | null;
}
/**
 *
 * @export
 * @interface ProfileGetResponseLegSeasonModel
 */
export interface ProfileGetResponseLegSeasonModel {
  /**
   *
   * @type {Array<string>}
   * @memberof ProfileGetResponseLegSeasonModel
   */
  SeasonIds?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseLegSeasonModel
   */
  StartDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseLegSeasonModel
   */
  EndDate?: string | null;
}
/**
 *
 * @export
 * @interface ProfileGetResponseModel
 */
export interface ProfileGetResponseModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseModel
   */
  ProfileId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseModel
   */
  ProfileName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileGetResponseModel
   */
  IsActive?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseModel
   */
  ProfileTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseModel
   */
  ProfileTypeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseModel
   */
  ResolutionId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseModel
   */
  ResolutionName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileGetResponseModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {Array<ProfileGetResponseLegModel>}
   * @memberof ProfileGetResponseModel
   */
  Legs?: Array<ProfileGetResponseLegModel> | null;
  /**
   *
   * @type {ProfileGetResponseTraceModel}
   * @memberof ProfileGetResponseModel
   */
  Traces?: Array<ProfileGetResponseTraceModel> | null;
  /**
   *
   * @type {Array<ProfileGetResponseUsageDisplayModel>}
   * @memberof ProfileGetResponseModel
   */
  DisplayPriority?: Array<ProfileGetResponseUsageDisplayModel> | null;
}
/**
 *
 * @export
 * @interface ProfileGetResponseModelIApiResponse
 */
export interface ProfileGetResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileGetResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileGetResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileGetResponseModel}
   * @memberof ProfileGetResponseModelIApiResponse
   */
  Body?: ProfileGetResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileGetResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileGetResponseRangeModel
 */
export interface ProfileGetResponseRangeModel {
  /**
   *
   * @type {number}
   * @memberof ProfileGetResponseRangeModel
   */
  StartInterval?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileGetResponseRangeModel
   */
  EndInterval?: number;
}
/**
 *
 * @export
 * @interface ProfileGetResponseTraceModel
 */
export interface ProfileGetResponseTraceModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseTraceModel
   */
  NormalisationId?: string;
  /**
   *
   * @type {Array<ProfileGetResponseTraceIntervalModel>}
   * @memberof ProfileGetResponseTraceModel
   */
  Intervals?: Array<ProfileGetResponseIntervalModel> | null;
}
/**
 *
 * @export
 * @interface ProfileGetResponseUsageDisplayModel
 */
export interface ProfileGetResponseUsageDisplayModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseUsageDisplayModel
   */
  UsageId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseUsageDisplayModel
   */
  UsageName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseUsageDisplayModel
   */
  DisplayId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetResponseUsageDisplayModel
   */
  DisplayName?: string | null;
}
/**
 *
 * @export
 * @interface ProfileGetSummaryResponseModel
 */
export interface ProfileGetSummaryResponseModel {
  /**
   *
   * @type {Array<ProfileGetSummaryResponseProfileModel>}
   * @memberof ProfileGetSummaryResponseModel
   */
  Profiles?: Array<ProfileGetSummaryResponseProfileModel> | null;
  /**
   *
   * @type {Array<ProfileGetSummaryResponseSeasonModel>}
   * @memberof ProfileGetSummaryResponseModel
   */
  Seasons?: Array<ProfileGetSummaryResponseSeasonModel> | null;
  /**
   *
   * @type {Array<ProfileGetSummaryResponseTypeModel>}
   * @memberof ProfileGetSummaryResponseModel
   */
  Types?: Array<ProfileGetSummaryResponseTypeModel> | null;
  /**
   *
   * @type {Array<ProfileGetSummaryResponseResolutionModel>}
   * @memberof ProfileGetSummaryResponseModel
   */
  Resolutions?: Array<ProfileGetSummaryResponseResolutionModel> | null;
  /**
   *
   * @type {Array<ProfileGetSummaryResponseNormalisationModel>}
   * @memberof ProfileGetSummaryResponseModel
   */
  Normalisations?: Array<ProfileGetSummaryResponseNormalisationModel> | null;
  /**
   *
   * @type {Array<ProfileGetSummaryResponsePriorityModel>}
   * @memberof ProfileGetSummaryResponseModel
   */
  Priorities?: Array<ProfileGetSummaryResponsePriorityModel> | null;
  /**
   *
   * @type {Array<ProfileGetSummaryResponseUsageModel>}
   * @memberof ProfileGetSummaryResponseModel
   */
  Usages?: Array<ProfileGetSummaryResponseUsageModel> | null;
}
/**
 *
 * @export
 * @interface ProfileGetSummaryResponseModelIApiResponse
 */
export interface ProfileGetSummaryResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileGetSummaryResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileGetSummaryResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileGetSummaryResponseModel}
   * @memberof ProfileGetSummaryResponseModelIApiResponse
   */
  Body?: ProfileGetSummaryResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileGetSummaryResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileGetSummaryResponseNormalisationModel
 */
export interface ProfileGetSummaryResponseNormalisationModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseNormalisationModel
   */
  NormalisationIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseNormalisationModel
   */
  NormalisationName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseNormalisationModel
   */
  Description?: string | null;
}
/**
 *
 * @export
 * @interface ProfileGetSummaryResponsePriorityModel
 */
export interface ProfileGetSummaryResponsePriorityModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponsePriorityModel
   */
  PriorityIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponsePriorityModel
   */
  PriorityName?: string | null;
}

export enum NormalisationUnitOfMeasure {
  Percentage = "Percent",
  Megawatts = "MW"
}

/**
 *
 * @export
 * @interface ProfileGetSummaryResponseProfileModel
 */
export interface ProfileGetSummaryResponseProfileModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseProfileModel
   */
  ProfileId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseProfileModel
   */
  ProfileName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileGetSummaryResponseProfileModel
   */
  IsActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileGetSummaryResponseProfileModel
   */
  Enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseProfileModel
   */
  ProfileTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseProfileModel
   */
  ProfileTypeName?: string | null;

  DisplayPriority?: Array<any> | null;

  NormalisationMethod?: {
    Description?: string;
    InvariantCode?: string;
    NormalisationIdentifier?: string;
    NormalisationName?: string;
    UnitOfMeasure?: NormalisationUnitOfMeasure;
  };
}
/**
 *
 * @export
 * @interface ProfileGetSummaryResponseResolutionModel
 */
export interface ProfileGetSummaryResponseResolutionModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseResolutionModel
   */
  ResolutionIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseResolutionModel
   */
  ResolutionName?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProfileGetSummaryResponseResolutionModel
   */
  Frequency?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileGetSummaryResponseResolutionModel
   */
  DurationInMinutes?: number;
}
/**
 *
 * @export
 * @interface ProfileGetSummaryResponseSeasonModel
 */
export interface ProfileGetSummaryResponseSeasonModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseSeasonModel
   */
  SeasonIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseSeasonModel
   */
  SeasonName?: string | null;
}
/**
 *
 * @export
 * @interface ProfileGetSummaryResponseTypeModel
 */
export interface ProfileGetSummaryResponseTypeModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseTypeModel
   */
  TypeIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseTypeModel
   */
  TypeName?: string | null;
}
/**
 *
 * @export
 * @interface ProfileGetSummaryResponseUsageModel
 */
export interface ProfileGetSummaryResponseUsageModel {
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseUsageModel
   */
  UsageIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileGetSummaryResponseUsageModel
   */
  UsageName?: string | null;
}
/**
 *
 * @export
 * @interface ProfileHoursRequestModel
 */
export interface ProfileHoursRequestModel {
  /**
   *
   * @type {Region}
   * @memberof ProfileHoursRequestModel
   */
  Region?: Region;
  /**
   *
   * @type {DealType}
   * @memberof ProfileHoursRequestModel
   */
  DealType?: DealType;
  VolumeProfileId?: string | null;
  PriceProfileId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileHoursRequestModel
   */
  From?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileHoursRequestModel
   */
  To?: string;
}
/**
 *
 * @export
 * @interface ProfileModel
 */
export interface ProfileModel {
  /**
   *
   * @type {Array<CustomProfileModel>}
   * @memberof ProfileModel
   */
  Profiles?: Array<CustomProfileModel> | null;
  /**
   *
   * @type {Array<ResolutionModel>}
   * @memberof ProfileModel
   */
  AllResolutions?: Array<ResolutionModel> | null;
  /**
   *
   * @type {Array<DayModel>}
   * @memberof ProfileModel
   */
  AllDays?: Array<DayModel> | null;
  /**
   *
   * @type {Array<PeriodModel>}
   * @memberof ProfileModel
   */
  Periods?: Array<PeriodModel> | null;
}
/**
 *
 * @export
 * @interface ProfileNormalisationsGetResponseModel
 */
export interface ProfileNormalisationsGetResponseModel {
  /**
   *
   * @type {Array<ProfileNormalisationsGetResponseNormalisationModel>}
   * @memberof ProfileNormalisationsGetResponseModel
   */
  Normalisations?: Array<ProfileNormalisationsGetResponseNormalisationModel> | null;
}
/**
 *
 * @export
 * @interface ProfileNormalisationsGetResponseModelIApiResponse
 */
export interface ProfileNormalisationsGetResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileNormalisationsGetResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileNormalisationsGetResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileNormalisationsGetResponseModel}
   * @memberof ProfileNormalisationsGetResponseModelIApiResponse
   */
  Body?: ProfileNormalisationsGetResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileNormalisationsGetResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileNormalisationsGetResponseNormalisationModel
 */
export interface ProfileNormalisationsGetResponseNormalisationModel {
  Multiple?: boolean;
  InvariantCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileNormalisationsGetResponseNormalisationModel
   */
  NormalisationIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileNormalisationsGetResponseNormalisationModel
   */
  NormalisationName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileNormalisationsGetResponseNormalisationModel
   */
  Description?: string | null;

  /**
   *
   * @type {string}
   * @memberof ProfileNormalisationsGetResponseNormalisationModel
   */
  UnitOfMeasure?: string | null;
}
/**
 *
 * @export
 * @interface ProfileResolutionsGetResponseModel
 */
export interface ProfileResolutionsGetResponseModel {
  /**
   *
   * @type {Array<ProfileResolutionsGetResponseResolutionModel>}
   * @memberof ProfileResolutionsGetResponseModel
   */
  Resolutions?: Array<ProfileResolutionsGetResponseResolutionModel> | null;
}
/**
 *
 * @export
 * @interface ProfileResolutionsGetResponseModelIApiResponse
 */
export interface ProfileResolutionsGetResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileResolutionsGetResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileResolutionsGetResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileResolutionsGetResponseModel}
   * @memberof ProfileResolutionsGetResponseModelIApiResponse
   */
  Body?: ProfileResolutionsGetResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileResolutionsGetResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileResolutionsGetResponseResolutionModel
 */
export interface ProfileResolutionsGetResponseResolutionModel {
  /**
   *
   * @type {string}
   * @memberof ProfileResolutionsGetResponseResolutionModel
   */
  ResolutionIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResolutionsGetResponseResolutionModel
   */
  ResolutionName?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProfileResolutionsGetResponseResolutionModel
   */
  Frequency?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileResolutionsGetResponseResolutionModel
   */
  DurationInMinutes?: number;
}
/**
 *
 * @export
 * @interface ProfileSeasonsGetResponseModel
 */
export interface ProfileSeasonsGetResponseModel {
  /**
   *
   * @type {Array<ProfileSeasonsGetResponseSeasonModel>}
   * @memberof ProfileSeasonsGetResponseModel
   */
  Seasons?: Array<ProfileSeasonsGetResponseSeasonModel> | null;
}
/**
 *
 * @export
 * @interface ProfileSeasonsGetResponseModelIApiResponse
 */
export interface ProfileSeasonsGetResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileSeasonsGetResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileSeasonsGetResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileSeasonsGetResponseModel}
   * @memberof ProfileSeasonsGetResponseModelIApiResponse
   */
  Body?: ProfileSeasonsGetResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileSeasonsGetResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileSeasonsGetResponseSeasonModel
 */
export interface ProfileSeasonsGetResponseSeasonModel {
  /**
   *
   * @type {string}
   * @memberof ProfileSeasonsGetResponseSeasonModel
   */
  SeasonIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileSeasonsGetResponseSeasonModel
   */
  SeasonName?: string | null;
}
/**
 *
 * @export
 * @interface ProfileTypesGetResponseModel
 */
export interface ProfileTypesGetResponseModel {
  /**
   *
   * @type {Array<ProfileTypesGetResponseTypeModel>}
   * @memberof ProfileTypesGetResponseModel
   */
  ProfileTypes?: Array<ProfileTypesGetResponseTypeModel> | null;
}
/**
 *
 * @export
 * @interface ProfileTypesGetResponseModelIApiResponse
 */
export interface ProfileTypesGetResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileTypesGetResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileTypesGetResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileTypesGetResponseModel}
   * @memberof ProfileTypesGetResponseModelIApiResponse
   */
  Body?: ProfileTypesGetResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileTypesGetResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileTypesGetResponseTypeModel
 */
export interface ProfileTypesGetResponseTypeModel {
  /**
   *
   * @type {string}
   * @memberof ProfileTypesGetResponseTypeModel
   */
  TypeIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileTypesGetResponseTypeModel
   */
  TypeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileTypesGetResponseTypeModel
   */
  TypeDescription?: string | null;
}
/**
 *
 * @export
 * @interface ProfileUpdateRequestIntervalModel
 */
export interface ProfileUpdateRequestIntervalModel {
  /**
   *
   * @type {number}
   * @memberof ProfileUpdateRequestIntervalModel
   */
  Interval?: number | null;
  /**
   *
   * @type {number}
   * @memberof ProfileUpdateRequestIntervalModel
   */
  Value?: number | null;
}
/**
 *
 * @export
 * @interface ProfileUpdateRequestLegModel
 */
export interface ProfileUpdateRequestLegModel {
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestLegModel
   */
  NormalisationStrategyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestLegModel
   */
  DayTypeId?: string | null;
  /**
   *
   * @type {ProfileUpdateRequestLegSeasonModel}
   * @memberof ProfileUpdateRequestLegModel
   */
  Season?: ProfileUpdateRequestLegSeasonModel;
  /**
   *
   * @type {Array<ProfileUpdateRequestIntervalModel>}
   * @memberof ProfileUpdateRequestLegModel
   */
  Intervals?: Array<ProfileUpdateRequestIntervalModel> | null;
  /**
   *
   * @type {Array<ProfileUpdateRequestRangeModel>}
   * @memberof ProfileUpdateRequestLegModel
   */
  Ranges?: Array<ProfileUpdateRequestRangeModel> | null;
}
/**
 *
 * @export
 * @interface ProfileUpdateRequestLegSeasonModel
 */
export interface ProfileUpdateRequestLegSeasonModel {
  /**
   *
   * @type {Array<string>}
   * @memberof ProfileUpdateRequestLegSeasonModel
   */
  SeasonId?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestLegSeasonModel
   */
  StartDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestLegSeasonModel
   */
  EndDate?: string | null;
}
/**
 *
 * @export
 * @interface ProfileUpdateRequestModel
 */
export interface ProfileUpdateRequestModel {
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestModel
   */
  ProfileName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileUpdateRequestModel
   */
  IsActive?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestModel
   */
  ProfileTypeId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestModel
   */
  ResolutionId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileUpdateRequestModel
   */
  Enabled?: boolean | null;
  /**
   *
   * @type {Array<ProfileUpdateRequestLegModel>}
   * @memberof ProfileUpdateRequestModel
   */
  Legs?: Array<ProfileUpdateRequestLegModel> | null;
  /**
   *
   * @type {ProfileUpdateRequestTraceModel}
   * @memberof ProfileUpdateRequestModel
   */
  Traces?: Array<ProfileUpdateRequestTraceModel>;
  /**
   *
   * @type {Array<ProfileUpdateRequestUsageDisplayModel>}
   * @memberof ProfileUpdateRequestModel
   */
  Priorities?: Array<ProfileUpdateRequestUsageDisplayModel> | null;
}
/**
 *
 * @export
 * @interface ProfileUpdateRequestRangeModel
 */
export interface ProfileUpdateRequestRangeModel {
  /**
   *
   * @type {number}
   * @memberof ProfileUpdateRequestRangeModel
   */
  StartInterval?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileUpdateRequestRangeModel
   */
  EndInterval?: number;
}
/**
 *
 * @export
 * @interface ProfileUpdateRequestTraceModel
 */
export interface ProfileUpdateRequestTraceModel {
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestTraceModel
   */
  StartDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestTraceModel
   */
  EndDate?: string | null;
  /**
   *
   * @type {Array<ProfileUpdateRequestIntervalModel>}
   * @memberof ProfileUpdateRequestTraceModel
   */
  Intervals?: Array<ProfileUpdateRequestIntervalModel> | null;
}
/**
 *
 * @export
 * @interface ProfileUpdateRequestUsageDisplayModel
 */
export interface ProfileUpdateRequestUsageDisplayModel {
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestUsageDisplayModel
   */
  UsageId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateRequestUsageDisplayModel
   */
  DisplayId?: string | null;
}
/**
 *
 * @export
 * @interface ProfileUpdateResponseModel
 */
export interface ProfileUpdateResponseModel {
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateResponseModel
   */
  ProfileIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateResponseModel
   */
  ProfileName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUpdateResponseModel
   */
  Path?: string | null;
}
/**
 *
 * @export
 * @interface ProfileUpdateResponseModelIApiResponse
 */
export interface ProfileUpdateResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileUpdateResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileUpdateResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileUpdateResponseModel}
   * @memberof ProfileUpdateResponseModelIApiResponse
   */
  Body?: ProfileUpdateResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileUpdateResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}

export interface ProfileExpandQueryModel {
  profileId?: string | null;
  StartDate?: string | null;
  EndDate?: string | null;
  Market?: string | null;
  State?: string | null;
}

export interface ProfileExpandedGetResponseIntervalDataModel {
  Interval?: string | null;
  Date?: string | null;
  Period?: number | null;
  Value?: number | null;
}

export interface ProfileExpandedGetResponseModel {
  Data?: Array<ProfileExpandedGetResponseIntervalDataModel> | null;
  ProfileId?: string | null;
  StartDate?: string | null;
  EndDate?: string | null;
  Frequency?: number | null;
  IntervalDuration?: number | null;
  HasExtrapolatedDates: boolean;
  DataCoverage: {
    IncludedDays: number;
    ExcludedDays: number;
    TotalDays: number;
    IsValid: boolean;
    IsCovered: boolean;
    PercentageOfDaysIncluded: number;
  };
}

/**
 *
 * @export
 * @interface ProfileUsageGetResponseModel
 */
export interface ProfileUsageGetResponseModel {
  /**
   *
   * @type {Array<ProfileUsageGetResponseUsageModel>}
   * @memberof ProfileUsageGetResponseModel
   */
  Usages?: Array<ProfileUsageGetResponseUsageModel> | null;
}
/**
 *
 * @export
 * @interface ProfileUsageGetResponseModelIApiResponse
 */
export interface ProfileUsageGetResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ProfileUsageGetResponseModelIApiResponse
   */
  Status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ProfileUsageGetResponseModelIApiResponse
   */
  Header?: any | null;
  /**
   *
   * @type {ProfileUsageGetResponseModel}
   * @memberof ProfileUsageGetResponseModelIApiResponse
   */
  Body?: ProfileUsageGetResponseModel;
  /**
   *
   * @type {Array<IErrorDetail>}
   * @memberof ProfileUsageGetResponseModelIApiResponse
   */
  Error?: Array<IErrorDetail> | null;
}
/**
 *
 * @export
 * @interface ProfileUsageGetResponseUsageModel
 */
export interface ProfileUsageGetResponseUsageModel {
  /**
   *
   * @type {string}
   * @memberof ProfileUsageGetResponseUsageModel
   */
  UsageIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUsageGetResponseUsageModel
   */
  UsageName?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum Quarter {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4"
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Region {
  Nsw = "NSW",
  Qld = "QLD",
  Vic = "VIC",
  Sa = "SA",
  Tas = "TAS"
}

/**
 *
 * @export
 * @interface RegisterModel
 */
export interface RegisterModel {
  /**
   *
   * @type {string}
   * @memberof RegisterModel
   */
  Email: string;
  /**
   *
   * @type {string}
   * @memberof RegisterModel
   */
  Password: string;
  /**
   *
   * @type {string}
   * @memberof RegisterModel
   */
  FirstName: string;
  /**
   *
   * @type {string}
   * @memberof RegisterModel
   */
  LastName: string;
  /**
   *
   * @type {string}
   * @memberof RegisterModel
   */
  Phone: string;
}
/**
 *
 * @export
 * @interface Reply
 */
export interface Reply {
  /**
   *
   * @type {string}
   * @memberof Reply
   */
  message?: string | null;
}
/**
 *
 * @export
 * @interface ReportModel
 */
export interface ReportModel {
  /**
   *
   * @type {number}
   * @memberof ReportModel
   */
  ContractYear?: number;
  /**
   *
   * @type {number}
   * @memberof ReportModel
   */
  WeekNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  From?: string;
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  To?: string;
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  ReportName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  Email?: string | null;
}
/**
 *
 * @export
 * @interface ResolutionModel
 */
export interface ResolutionModel {
  /**
   *
   * @type {number}
   * @memberof ResolutionModel
   */
  ResolutionId?: number;
  /**
   *
   * @type {string}
   * @memberof ResolutionModel
   */
  Name?: string | null;
  /**
   *
   * @type {TimeSpanModel}
   * @memberof ResolutionModel
   */
  TimeSpan?: TimeSpanModel;
}
/**
 *
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
  /**
   *
   * @type {number}
   * @memberof RoleModel
   */
  RoleId?: number;
  /**
   *
   * @type {string}
   * @memberof RoleModel
   */
  RoleName?: string | null;
  /**
   *
   * @type {string}
   * @memberof RoleModel
   */
  groupIdentifier?: string | null;
  /**
   *
   * @type {string}
   * @memberof RoleModel
   */
  roleIdentifier?: string | null;
  /**
   *
   * @type {Array<PermissionModel>}
   * @memberof RoleModel
   */
  Permissions?: Array<PermissionModel> | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum SRAInstrumentType {
  Purchaseatauction = "PURCHASEATAUCTION",
  Forward = "FORWARD",
  Option = "OPTION"
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Side {
  Buy = "Buy",
  Sell = "Sell"
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Strip {
  Fin = "FIN",
  Cal = "CAL"
}

/**
 *
 * @export
 * @interface Swap
 */
export interface Swap {
  /**
   *
   * @type {Region}
   * @memberof Swap
   */
  Region?: Region;
  /**
   *
   * @type {Period}
   * @memberof Swap
   */
  Period?: Period;
  /**
   *
   * @type {string}
   * @memberof Swap
   */
  Profile?: string | null;
  /**
   *
   * @type {KeyedValue}
   * @memberof Swap
   */
  OtherProfile?: StringKeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof Swap
   */
  VolumeProfile?: StringKeyedValue;
  /**
   *
   * @type {KeyedValue}
   * @memberof Swap
   */
  PriceProfile?: StringKeyedValue;
  /**
   *
   * @type {string}
   * @memberof Swap
   */
  ProductType?: string | null;
  /**
   *
   * @type {Quarter}
   * @memberof Swap
   */
  Quarter?: Quarter;
  /**
   *
   * @type {Strip}
   * @memberof Swap
   */
  Strip?: Strip;
  /**
   *
   * @type {number}
   * @memberof Swap
   */
  Year?: number | null;
  /**
   *
   * @type {string}
   * @memberof Swap
   */
  FromDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Swap
   */
  ToDate?: string | null;
  /**
   *
   * @type {number}
   * @memberof Swap
   */
  Hours?: number | null;
  /**
   *
   * @type {number}
   * @memberof Swap
   */
  SettlementFrequency?: number | null;
}
/**
 *
 * @export
 * @interface Swaption
 */
export interface Swaption {
  /**
   *
   * @type {boolean}
   * @memberof Swaption
   */
  PaidAcrossPeriod?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Swaption
   */
  UpfrontDate?: string | null;
  /**
   *
   * @type {number}
   * @memberof Swaption
   */
  Strike?: number | null;
  /**
   *
   * @type {number}
   * @memberof Swaption
   */
  Premium?: number | null;
  /**
   *
   * @type {number}
   * @memberof Swaption
   */
  OptionPremium?: number | null;
  /**
   *
   * @type {string}
   * @memberof Swaption
   */
  UnderlyingOption?: string | null;
  /**
   *
   * @type {string}
   * @memberof Swaption
   */
  PremiumPaymentDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Swaption
   */
  OptionPremiumPaymentDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Swaption
   */
  ExpiryDate?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Swaption
   */
  AmericanOption?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Swaption
   */
  EuropeanOption?: boolean | null;
}
/**
 *
 * @export
 * @interface TimeSpanModel
 */
export interface TimeSpanModel {
  /**
   *
   * @type {number}
   * @memberof TimeSpanModel
   */
  Hours?: number;
  /**
   *
   * @type {number}
   * @memberof TimeSpanModel
   */
  Minutes?: number;
}
/**
 *
 * @export
 * @interface TradableModel
 */
export interface TradableModel {
  /**
   *
   * @type {string}
   * @memberof TradableModel
   */
  Ticker: string;
  /**
   *
   * @type {number}
   * @memberof TradableModel
   */
  Volume: number;
  /**
   *
   * @type {number}
   * @memberof TradableModel
   */
  Price: number;
  /**
   *
   * @type {number}
   * @memberof TradableModel
   */
  Hours?: number;
}
/**
 *
 * @export
 * @interface UpdateProfile
 */
export interface UpdateProfile {
  /**
   *
   * @type {number}
   * @memberof UpdateProfile
   */
  CustomProfileId?: number;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProfile
   */
  Enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProfile
   */
  Valid?: boolean;
}
/**
 *
 * @export
 * @interface UserModel
 */
export interface UserModel {
  /**
   *
   * @type {number}
   * @memberof UserModel
   */
  UserId?: number;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  userIdentifier?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  Email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  LastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  Phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  PositionTitle?: string | null;
  /**
   *
   * @type {Array<RoleModel>}
   * @memberof UserModel
   */
  Roles?: Array<RoleModel> | null;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ForgotModel} [forgotModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthForgotPost: async (forgotModel?: ForgotModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/forgot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(forgotModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {ForgotUpdateModel} [forgotUpdateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthForgotupdatePost: async (forgotUpdateModel?: ForgotUpdateModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/forgotupdate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(forgotUpdateModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthGetallrolesPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/getallroles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthGetpermissionsPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/getpermissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthGetrolesPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/getroles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthGetusersPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/getusers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLinkuserPost: async (loginModel?: LoginModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/linkuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLoginPost: async (loginModel?: LoginModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRefreshPost: async (token?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {RegisterModel} [registerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRegisterPost: async (registerModel?: RegisterModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(registerModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {Array<RoleModel>} [roleModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthUpdaterolesPost: async (roleModel?: Array<RoleModel>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/updateroles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(roleModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {Array<UserModel>} [userModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthUpdateusersPost: async (userModel?: Array<UserModel>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/updateusers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [user]
     * @param {AllowedGroups} [role]
     * @param {boolean} [linked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthValidateuserGet: async (user?: string, role?: AllowedGroups, linked?: boolean, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/validateuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (user !== undefined) {
        localVarQueryParameter["user"] = user;
      }

      if (role !== undefined) {
        localVarQueryParameter["role"] = role;
      }

      if (linked !== undefined) {
        localVarQueryParameter["linked"] = linked;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ForgotModel} [forgotModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthForgotPost(
      forgotModel?: ForgotModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthForgotPost(forgotModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ForgotUpdateModel} [forgotUpdateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthForgotupdatePost(
      forgotUpdateModel?: ForgotUpdateModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthForgotupdatePost(forgotUpdateModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthGetallrolesPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthGetallrolesPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthGetpermissionsPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthGetpermissionsPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthGetrolesPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthGetrolesPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthGetusersPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthGetusersPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthLinkuserPost(
      loginModel?: LoginModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLinkuserPost(loginModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthLoginPost(
      loginModel?: LoginModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLoginPost(loginModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthRefreshPost(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRefreshPost(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RegisterModel} [registerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthRegisterPost(
      registerModel?: RegisterModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRegisterPost(registerModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<RoleModel>} [roleModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthUpdaterolesPost(
      roleModel?: Array<RoleModel>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthUpdaterolesPost(roleModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<UserModel>} [userModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthUpdateusersPost(
      userModel?: Array<UserModel>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthUpdateusersPost(userModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [user]
     * @param {AllowedGroups} [role]
     * @param {boolean} [linked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthValidateuserGet(
      user?: string,
      role?: AllowedGroups,
      linked?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reply>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthValidateuserGet(user, role, linked, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthenticationApiFp(configuration);
  return {
    /**
     *
     * @param {ForgotModel} [forgotModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthForgotPost(forgotModel?: ForgotModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthForgotPost(forgotModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ForgotUpdateModel} [forgotUpdateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthForgotupdatePost(forgotUpdateModel?: ForgotUpdateModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthForgotupdatePost(forgotUpdateModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthGetallrolesPost(options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthGetallrolesPost(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthGetpermissionsPost(options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthGetpermissionsPost(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthGetrolesPost(options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthGetrolesPost(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthGetusersPost(options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthGetusersPost(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLinkuserPost(loginModel?: LoginModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthLinkuserPost(loginModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLoginPost(loginModel?: LoginModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthLoginPost(loginModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRefreshPost(token?: string, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthRefreshPost(token, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {RegisterModel} [registerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRegisterPost(registerModel?: RegisterModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthRegisterPost(registerModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Array<RoleModel>} [roleModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthUpdaterolesPost(roleModel?: Array<RoleModel>, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthUpdaterolesPost(roleModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Array<UserModel>} [userModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthUpdateusersPost(userModel?: Array<UserModel>, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuthUpdateusersPost(userModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [user]
     * @param {AllowedGroups} [role]
     * @param {boolean} [linked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthValidateuserGet(user?: string, role?: AllowedGroups, linked?: boolean, options?: any): AxiosPromise<Reply> {
      return localVarFp.apiAuthValidateuserGet(user, role, linked, options).then(request => request(axios, basePath));
    }
  };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
  /**
   *
   * @param {ForgotModel} [forgotModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthForgotPost(forgotModel?: ForgotModel, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthForgotPost(forgotModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ForgotUpdateModel} [forgotUpdateModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthForgotupdatePost(forgotUpdateModel?: ForgotUpdateModel, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthForgotupdatePost(forgotUpdateModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthGetallrolesPost(options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthGetallrolesPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthGetpermissionsPost(options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthGetpermissionsPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthGetrolesPost(options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthGetrolesPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthGetusersPost(options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthGetusersPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginModel} [loginModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthLinkuserPost(loginModel?: LoginModel, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthLinkuserPost(loginModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginModel} [loginModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthLoginPost(loginModel?: LoginModel, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthLoginPost(loginModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthRefreshPost(token?: string, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthRefreshPost(token, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RegisterModel} [registerModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthRegisterPost(registerModel?: RegisterModel, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthRegisterPost(registerModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<RoleModel>} [roleModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthUpdaterolesPost(roleModel?: Array<RoleModel>, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthUpdaterolesPost(roleModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<UserModel>} [userModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthUpdateusersPost(userModel?: Array<UserModel>, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthUpdateusersPost(userModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [user]
   * @param {AllowedGroups} [role]
   * @param {boolean} [linked]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public apiAuthValidateuserGet(user?: string, role?: AllowedGroups, linked?: boolean, options?: any) {
    return AuthenticationApiFp(this.configuration)
      .apiAuthValidateuserGet(user, role, linked, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetallprofilesPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/getallprofiles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetconfigdataPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/getconfigdata`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetdefaultfeesPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/getdefaultfees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {ProfileHoursRequestModel} [profileHoursRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGethoursinperiodPost: async (profileHoursRequestModel?: ProfileHoursRequestModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/gethoursinperiod`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(profileHoursRequestModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetmarketsettingsPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/getmarketsettings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {ExchangeStripProductBreakdownRequesttModel} [exchangeStripProductBreakdownRequesttModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetunderlyingquartersPost: async (
      exchangeStripProductBreakdownRequesttModel?: ExchangeStripProductBreakdownRequesttModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/getunderlyingquarters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        exchangeStripProductBreakdownRequesttModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigNotificationsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {ConfigModel} [configModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdateconfigdataPost: async (configModel?: ConfigModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/updateconfigdata`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(configModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {FeesModel} [feesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdatedefaultfeesPost: async (feesModel?: FeesModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/updatedefaultfees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(feesModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {MarketSettingsModel} [marketSettingsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdatemarketsettingsPost: async (marketSettingsModel?: MarketSettingsModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/updatemarketsettings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(marketSettingsModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {Array<PeriodModel>} [periodModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdateperiodsPost: async (periodModel?: Array<PeriodModel>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/updateperiods`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(periodModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {UpdateProfile} [updateProfile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdateprofilePost: async (updateProfile?: UpdateProfile, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/updateprofile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateProfile, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {CustomProfileModel} [customProfileModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdateprofiledetailsPost: async (customProfileModel?: CustomProfileModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/updateprofiledetails`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(customProfileModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigGetallprofilesPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigGetallprofilesPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigGetconfigdataPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigGetconfigdataPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigGetdefaultfeesPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeesModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigGetdefaultfeesPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ProfileHoursRequestModel} [profileHoursRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigGethoursinperiodPost(
      profileHoursRequestModel?: ProfileHoursRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractPeriodModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigGethoursinperiodPost(profileHoursRequestModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigGetmarketsettingsPost(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypedApiResponse<MarketSettingsModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigGetmarketsettingsPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExchangeStripProductBreakdownRequesttModel} [exchangeStripProductBreakdownRequesttModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigGetunderlyingquartersPost(
      exchangeStripProductBreakdownRequesttModel?: ExchangeStripProductBreakdownRequesttModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradableModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigGetunderlyingquartersPost(
        exchangeStripProductBreakdownRequesttModel,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigNotificationsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigNotificationsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ConfigModel} [configModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigUpdateconfigdataPost(
      configModel?: ConfigModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigUpdateconfigdataPost(configModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FeesModel} [feesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigUpdatedefaultfeesPost(
      feesModel?: FeesModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigUpdatedefaultfeesPost(feesModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {MarketSettingsModel} [marketSettingsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigUpdatemarketsettingsPost(
      marketSettingsModel?: MarketSettingsModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigUpdatemarketsettingsPost(marketSettingsModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<PeriodModel>} [periodModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigUpdateperiodsPost(
      periodModel?: Array<PeriodModel>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigUpdateperiodsPost(periodModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdateProfile} [updateProfile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigUpdateprofilePost(
      updateProfile?: UpdateProfile,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigUpdateprofilePost(updateProfile, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CustomProfileModel} [customProfileModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConfigUpdateprofiledetailsPost(
      customProfileModel?: CustomProfileModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigUpdateprofiledetailsPost(customProfileModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ConfigApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetallprofilesPost(options?: any): AxiosPromise<ProfileModel> {
      return localVarFp.apiConfigGetallprofilesPost(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetconfigdataPost(options?: any): AxiosPromise<ConfigModel> {
      return localVarFp.apiConfigGetconfigdataPost(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetdefaultfeesPost(options?: any): AxiosPromise<FeesModel> {
      return localVarFp.apiConfigGetdefaultfeesPost(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ProfileHoursRequestModel} [profileHoursRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGethoursinperiodPost(profileHoursRequestModel?: ProfileHoursRequestModel, options?: any): AxiosPromise<ContractPeriodModel> {
      return localVarFp.apiConfigGethoursinperiodPost(profileHoursRequestModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetmarketsettingsPost(options?: any): AxiosPromise<TypedApiResponse<MarketSettingsModel>> {
      return localVarFp.apiConfigGetmarketsettingsPost(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ExchangeStripProductBreakdownRequesttModel} [exchangeStripProductBreakdownRequesttModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigGetunderlyingquartersPost(
      exchangeStripProductBreakdownRequesttModel?: ExchangeStripProductBreakdownRequesttModel,
      options?: any
    ): AxiosPromise<Array<TradableModel>> {
      return localVarFp
        .apiConfigGetunderlyingquartersPost(exchangeStripProductBreakdownRequesttModel, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigNotificationsGet(options?: any): AxiosPromise<void> {
      return localVarFp.apiConfigNotificationsGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ConfigModel} [configModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdateconfigdataPost(configModel?: ConfigModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiConfigUpdateconfigdataPost(configModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {FeesModel} [feesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdatedefaultfeesPost(feesModel?: FeesModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiConfigUpdatedefaultfeesPost(feesModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {MarketSettingsModel} [marketSettingsModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdatemarketsettingsPost(marketSettingsModel?: MarketSettingsModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiConfigUpdatemarketsettingsPost(marketSettingsModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Array<PeriodModel>} [periodModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdateperiodsPost(periodModel?: Array<PeriodModel>, options?: any): AxiosPromise<void> {
      return localVarFp.apiConfigUpdateperiodsPost(periodModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateProfile} [updateProfile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdateprofilePost(updateProfile?: UpdateProfile, options?: any): AxiosPromise<void> {
      return localVarFp.apiConfigUpdateprofilePost(updateProfile, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CustomProfileModel} [customProfileModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConfigUpdateprofiledetailsPost(customProfileModel?: CustomProfileModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiConfigUpdateprofiledetailsPost(customProfileModel, options).then(request => request(axios, basePath));
    }
  };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigGetallprofilesPost(options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigGetallprofilesPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigGetconfigdataPost(options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigGetconfigdataPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigGetdefaultfeesPost(options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigGetdefaultfeesPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProfileHoursRequestModel} [profileHoursRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigGethoursinperiodPost(profileHoursRequestModel?: ProfileHoursRequestModel, options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigGethoursinperiodPost(profileHoursRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigGetmarketsettingsPost(options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigGetmarketsettingsPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExchangeStripProductBreakdownRequesttModel} [exchangeStripProductBreakdownRequesttModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigGetunderlyingquartersPost(
    exchangeStripProductBreakdownRequesttModel?: ExchangeStripProductBreakdownRequesttModel,
    options?: any
  ) {
    return ConfigApiFp(this.configuration)
      .apiConfigGetunderlyingquartersPost(exchangeStripProductBreakdownRequesttModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigNotificationsGet(options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigNotificationsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConfigModel} [configModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigUpdateconfigdataPost(configModel?: ConfigModel, options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigUpdateconfigdataPost(configModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FeesModel} [feesModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigUpdatedefaultfeesPost(feesModel?: FeesModel, options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigUpdatedefaultfeesPost(feesModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MarketSettingsModel} [marketSettingsModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigUpdatemarketsettingsPost(marketSettingsModel?: MarketSettingsModel, options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigUpdatemarketsettingsPost(marketSettingsModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<PeriodModel>} [periodModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigUpdateperiodsPost(periodModel?: Array<PeriodModel>, options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigUpdateperiodsPost(periodModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateProfile} [updateProfile]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigUpdateprofilePost(updateProfile?: UpdateProfile, options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigUpdateprofilePost(updateProfile, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CustomProfileModel} [customProfileModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public apiConfigUpdateprofiledetailsPost(customProfileModel?: CustomProfileModel, options?: any) {
    return ConfigApiFp(this.configuration)
      .apiConfigUpdateprofiledetailsPost(customProfileModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DayTypeApi - axios parameter creator
 * @export
 */
export const DayTypeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} dayTypeId
     * @param {string} [eEDELETEAUTHORISATIONKEY]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDayTypeIdDelete: async (dayTypeId: string, eEDELETEAUTHORISATIONKEY?: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dayTypeId' is not null or undefined
      assertParamExists("apiV2DayTypesDayTypeIdDelete", "dayTypeId", dayTypeId);
      const localVarPath = `/api/v1/v2/dayTypes/{dayTypeId}`.replace(`{${"dayTypeId"}}`, encodeURIComponent(String(dayTypeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (eEDELETEAUTHORISATIONKEY !== undefined && eEDELETEAUTHORISATIONKEY !== null) {
        localVarHeaderParameter["EE-DELETE-AUTHORISATION-KEY"] = String(eEDELETEAUTHORISATIONKEY);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDayTypeIdDeletePost: async (dayTypeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dayTypeId' is not null or undefined
      assertParamExists("apiV2DayTypesDayTypeIdDeletePost", "dayTypeId", dayTypeId);
      const localVarPath = `/api/v1/v2/dayTypes/{dayTypeId}/delete`.replace(`{${"dayTypeId"}}`, encodeURIComponent(String(dayTypeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDayTypeIdGet: async (dayTypeId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dayTypeId' is not null or undefined
      assertParamExists("apiV2DayTypesDayTypeIdGet", "dayTypeId", dayTypeId);
      const localVarPath = `/api/v1/v2/dayTypes/{dayTypeId}`.replace(`{${"dayTypeId"}}`, encodeURIComponent(String(dayTypeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {DayTypeUpdateRequestModel} [dayTypeUpdateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDayTypeIdPut: async (
      dayTypeId: string,
      dayTypeUpdateRequestModel?: DayTypeUpdateRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dayTypeId' is not null or undefined
      assertParamExists("apiV2DayTypesDayTypeIdPut", "dayTypeId", dayTypeId);
      const localVarPath = `/api/v1/v2/dayTypes/{dayTypeId}`.replace(`{${"dayTypeId"}}`, encodeURIComponent(String(dayTypeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dayTypeUpdateRequestModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDaysGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/dayTypes/days`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/dayTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {DayTypeCreateRequestModel} [dayTypeCreateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesPost: async (dayTypeCreateRequestModel?: DayTypeCreateRequestModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/dayTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dayTypeCreateRequestModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DayTypeApi - functional programming interface
 * @export
 */
export const DayTypeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DayTypeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} dayTypeId
     * @param {string} [eEDELETEAUTHORISATIONKEY]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2DayTypesDayTypeIdDelete(
      dayTypeId: string,
      eEDELETEAUTHORISATIONKEY?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2DayTypesDayTypeIdDelete(dayTypeId, eEDELETEAUTHORISATIONKEY, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2DayTypesDayTypeIdDeletePost(
      dayTypeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayTypeDeleteRequestResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2DayTypesDayTypeIdDeletePost(dayTypeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2DayTypesDayTypeIdGet(
      dayTypeId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayTypeGetSingularResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2DayTypesDayTypeIdGet(dayTypeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {DayTypeUpdateRequestModel} [dayTypeUpdateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2DayTypesDayTypeIdPut(
      dayTypeId: string,
      dayTypeUpdateRequestModel?: DayTypeUpdateRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayTypeUpdateResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2DayTypesDayTypeIdPut(dayTypeId, dayTypeUpdateRequestModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2DayTypesDaysGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayTypeDaysResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2DayTypesDaysGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2DayTypesGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayTypeGetResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2DayTypesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DayTypeCreateRequestModel} [dayTypeCreateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2DayTypesPost(
      dayTypeCreateRequestModel?: DayTypeCreateRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayTypeCreateResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2DayTypesPost(dayTypeCreateRequestModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * DayTypeApi - factory interface
 * @export
 */
export const DayTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DayTypeApiFp(configuration);
  return {
    /**
     *
     * @param {string} dayTypeId
     * @param {string} [eEDELETEAUTHORISATIONKEY]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDayTypeIdDelete(dayTypeId: string, eEDELETEAUTHORISATIONKEY?: string, options?: any): AxiosPromise<ObjectIApiResponse> {
      return localVarFp
        .apiV2DayTypesDayTypeIdDelete(dayTypeId, eEDELETEAUTHORISATIONKEY, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDayTypeIdDeletePost(dayTypeId: string, options?: any): AxiosPromise<DayTypeDeleteRequestResponseModelIApiResponse> {
      return localVarFp.apiV2DayTypesDayTypeIdDeletePost(dayTypeId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDayTypeIdGet(dayTypeId: string, options?: any): AxiosPromise<DayTypeGetSingularResponseModelIApiResponse> {
      return localVarFp.apiV2DayTypesDayTypeIdGet(dayTypeId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} dayTypeId
     * @param {DayTypeUpdateRequestModel} [dayTypeUpdateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDayTypeIdPut(
      dayTypeId: string,
      dayTypeUpdateRequestModel?: DayTypeUpdateRequestModel,
      options?: any
    ): AxiosPromise<DayTypeUpdateResponseModelIApiResponse> {
      return localVarFp.apiV2DayTypesDayTypeIdPut(dayTypeId, dayTypeUpdateRequestModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesDaysGet(options?: any): AxiosPromise<DayTypeDaysResponseModelIApiResponse> {
      return localVarFp.apiV2DayTypesDaysGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesGet(options?: any): AxiosPromise<DayTypeGetResponseModelIApiResponse> {
      return localVarFp.apiV2DayTypesGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {DayTypeCreateRequestModel} [dayTypeCreateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2DayTypesPost(
      dayTypeCreateRequestModel?: DayTypeCreateRequestModel,
      options?: any
    ): AxiosPromise<DayTypeCreateResponseModelIApiResponse> {
      return localVarFp.apiV2DayTypesPost(dayTypeCreateRequestModel, options).then(request => request(axios, basePath));
    }
  };
};

/**
 * DayTypeApi - object-oriented interface
 * @export
 * @class DayTypeApi
 * @extends {BaseAPI}
 */
export class DayTypeApi extends BaseAPI {
  /**
   *
   * @param {string} dayTypeId
   * @param {string} [eEDELETEAUTHORISATIONKEY]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DayTypeApi
   */
  public apiV2DayTypesDayTypeIdDelete(dayTypeId: string, eEDELETEAUTHORISATIONKEY?: string, options?: any) {
    return DayTypeApiFp(this.configuration)
      .apiV2DayTypesDayTypeIdDelete(dayTypeId, eEDELETEAUTHORISATIONKEY, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dayTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DayTypeApi
   */
  public apiV2DayTypesDayTypeIdDeletePost(dayTypeId: string, options?: any) {
    return DayTypeApiFp(this.configuration)
      .apiV2DayTypesDayTypeIdDeletePost(dayTypeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dayTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DayTypeApi
   */
  public apiV2DayTypesDayTypeIdGet(dayTypeId: string, options?: any) {
    return DayTypeApiFp(this.configuration)
      .apiV2DayTypesDayTypeIdGet(dayTypeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dayTypeId
   * @param {DayTypeUpdateRequestModel} [dayTypeUpdateRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DayTypeApi
   */
  public apiV2DayTypesDayTypeIdPut(dayTypeId: string, dayTypeUpdateRequestModel?: DayTypeUpdateRequestModel, options?: any) {
    return DayTypeApiFp(this.configuration)
      .apiV2DayTypesDayTypeIdPut(dayTypeId, dayTypeUpdateRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DayTypeApi
   */
  public apiV2DayTypesDaysGet(options?: any) {
    return DayTypeApiFp(this.configuration)
      .apiV2DayTypesDaysGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DayTypeApi
   */
  public apiV2DayTypesGet(options?: any) {
    return DayTypeApiFp(this.configuration)
      .apiV2DayTypesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DayTypeCreateRequestModel} [dayTypeCreateRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DayTypeApi
   */
  public apiV2DayTypesPost(dayTypeCreateRequestModel?: DayTypeCreateRequestModel, options?: any) {
    return DayTypeApiFp(this.configuration)
      .apiV2DayTypesPost(dayTypeCreateRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DealsApi - axios parameter creator
 * @export
 */
export const DealsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {DealModel} [dealModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsCreatedealPost: async (dealModel?: DealModel[], options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/deals/createdeal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dealModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} dealId
     * @param {CommentModel} [commentModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdAddcommentPost: async (dealId: string, commentModel?: CommentModel, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("apiDealsDealIdAddcommentPost", "dealId", dealId);
      const localVarPath = `/api/v1/deals/{dealId}/addcomment`.replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(commentModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdCommentsGet: async (dealId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("apiDealsDealIdCommentsGet", "dealId", dealId);
      const localVarPath = `/api/v1/deals/{dealId}/comments`.replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdConfirmationPost: async (dealId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("apiDealsDealIdConfirmationPost", "dealId", dealId);
      const localVarPath = `/api/v1/deals/{dealId}/confirmation`.replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdGet: async (dealId: number | string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("apiDealsDealIdGet", "dealId", dealId);
      const localVarPath = `/api/v1/deals/{dealId}`.replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdLogsGet: async (dealId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("apiDealsDealIdLogsGet", "dealId", dealId);
      const localVarPath = `/api/v1/deals/{dealId}/logs`.replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} dealId
     * @param {boolean} [expired]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdWorkflowPost: async (dealId: number, expired?: boolean, status?: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("apiDealsDealIdWorkflowPost", "dealId", dealId);
      const localVarPath = `/api/v1/deals/{dealId}/workflow`.replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (expired !== undefined) {
        localVarQueryParameter["expired"] = expired;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {ExercisedOption} [exercisedOption]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsExerciseoptionPost: async (exercisedOption?: ExercisedOption, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/deals/exerciseoption`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(exercisedOption, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/deals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} [dealId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsGetexercisedlegsPost: async (dealId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/deals/getexercisedlegs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dealId !== undefined) {
        localVarQueryParameter["dealId"] = dealId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} [dealId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsReverseexerciseoptionPost: async (dealId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/deals/reverseexerciseoption`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dealId !== undefined) {
        localVarQueryParameter["dealId"] = dealId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {ReportModel} [reportModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsRunreportsPost: async (reportModel?: ReportModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/deals/runreports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(reportModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DealsApi - functional programming interface
 * @export
 */
export const DealsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DealsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {DealModel} [dealModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsCreatedealPost(
      dealModel?: DealModel[],
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealModelResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsCreatedealPost(dealModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealId
     * @param {CommentModel} [commentModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsDealIdAddcommentPost(
      dealId: string,
      commentModel?: CommentModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reply>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsDealIdAddcommentPost(dealId, commentModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsDealIdCommentsGet(
      dealId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommentModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsDealIdCommentsGet(dealId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsDealIdConfirmationPost(
      dealId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsDealIdConfirmationPost(dealId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsDealIdGet(
      dealId: number | string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsDealIdGet(dealId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsDealIdLogsGet(
      dealId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLogModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsDealIdLogsGet(dealId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} dealId
     * @param {boolean} [expired]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsDealIdWorkflowPost(
      dealId: number,
      expired?: boolean,
      status?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsDealIdWorkflowPost(dealId, expired, status, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExercisedOption} [exercisedOption]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsExerciseoptionPost(
      exercisedOption?: ExercisedOption,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsExerciseoptionPost(exercisedOption, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DealModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [dealId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsGetexercisedlegsPost(
      dealId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExercisedOption>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsGetexercisedlegsPost(dealId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [dealId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsReverseexerciseoptionPost(
      dealId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsReverseexerciseoptionPost(dealId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReportModel} [reportModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDealsRunreportsPost(
      reportModel?: ReportModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsRunreportsPost(reportModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * DealsApi - factory interface
 * @export
 */
export const DealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DealsApiFp(configuration);
  return {
    /**
     *
     * @param {DealModel} [dealModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsCreatedealPost(dealModel?: DealModel[], options?: any): AxiosPromise<DealModelResult> {
      return localVarFp.apiDealsCreatedealPost(dealModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealId
     * @param {CommentModel} [commentModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdAddcommentPost(dealId: string, commentModel?: CommentModel, options?: any): AxiosPromise<Reply> {
      return localVarFp.apiDealsDealIdAddcommentPost(dealId, commentModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdCommentsGet(dealId: number, options?: any): AxiosPromise<Array<CommentModel>> {
      return localVarFp.apiDealsDealIdCommentsGet(dealId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdConfirmationPost(dealId: number, options?: any): AxiosPromise<void> {
      return localVarFp.apiDealsDealIdConfirmationPost(dealId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdGet(dealId: number | string, options?: any): AxiosPromise<DealModel> {
      return localVarFp.apiDealsDealIdGet(dealId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdLogsGet(dealId: number, options?: any): AxiosPromise<Array<AuditLogModel>> {
      return localVarFp.apiDealsDealIdLogsGet(dealId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} dealId
     * @param {boolean} [expired]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsDealIdWorkflowPost(dealId: number, expired?: boolean, status?: string, options?: any): AxiosPromise<void> {
      return localVarFp.apiDealsDealIdWorkflowPost(dealId, expired, status, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ExercisedOption} [exercisedOption]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsExerciseoptionPost(exercisedOption?: ExercisedOption, options?: any): AxiosPromise<void> {
      return localVarFp.apiDealsExerciseoptionPost(exercisedOption, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsGet(options?: any): AxiosPromise<Array<DealModel>> {
      return localVarFp.apiDealsGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [dealId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsGetexercisedlegsPost(dealId?: number, options?: any): AxiosPromise<Array<ExercisedOption>> {
      return localVarFp.apiDealsGetexercisedlegsPost(dealId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [dealId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsReverseexerciseoptionPost(dealId?: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.apiDealsReverseexerciseoptionPost(dealId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ReportModel} [reportModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDealsRunreportsPost(reportModel?: ReportModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiDealsRunreportsPost(reportModel, options).then(request => request(axios, basePath));
    }
  };
};

/**
 * DealsApi - object-oriented interface
 * @export
 * @class DealsApi
 * @extends {BaseAPI}
 */
export class DealsApi extends BaseAPI {
  /**
   *
   * @param {DealModel} [dealModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsCreatedealPost(dealModel?: DealModel[], options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsCreatedealPost(dealModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealId
   * @param {CommentModel} [commentModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsDealIdAddcommentPost(dealId: string, commentModel?: CommentModel, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsDealIdAddcommentPost(dealId, commentModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} dealId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsDealIdCommentsGet(dealId: number, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsDealIdCommentsGet(dealId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} dealId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsDealIdConfirmationPost(dealId: number, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsDealIdConfirmationPost(dealId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} dealId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsDealIdGet(dealId: number, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsDealIdGet(dealId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} dealId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsDealIdLogsGet(dealId: number, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsDealIdLogsGet(dealId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} dealId
   * @param {boolean} [expired]
   * @param {string} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsDealIdWorkflowPost(dealId: number, expired?: boolean, status?: string, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsDealIdWorkflowPost(dealId, expired, status, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExercisedOption} [exercisedOption]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsExerciseoptionPost(exercisedOption?: ExercisedOption, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsExerciseoptionPost(exercisedOption, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsGet(options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [dealId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsGetexercisedlegsPost(dealId?: number, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsGetexercisedlegsPost(dealId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [dealId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsReverseexerciseoptionPost(dealId?: number, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsReverseexerciseoptionPost(dealId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReportModel} [reportModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealsApi
   */
  public apiDealsRunreportsPost(reportModel?: ReportModel, options?: any) {
    return DealsApiFp(this.configuration)
      .apiDealsRunreportsPost(reportModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GasSupplyHubDealsApi - axios parameter creator
 * @export
 */
export const GasSupplyHubDealsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [tradeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiGshSearchaemoGet: async (tradeId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/gsh/searchaemo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (tradeId !== undefined) {
        localVarQueryParameter["tradeId"] = tradeId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * GasSupplyHubDealsApi - functional programming interface
 * @export
 */
export const GasSupplyHubDealsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GasSupplyHubDealsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [tradeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiGshSearchaemoGet(
      tradeId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GSHTrade>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiGshSearchaemoGet(tradeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * GasSupplyHubDealsApi - factory interface
 * @export
 */
export const GasSupplyHubDealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GasSupplyHubDealsApiFp(configuration);
  return {
    /**
     *
     * @param {number} [tradeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiGshSearchaemoGet(tradeId?: number, options?: any): AxiosPromise<Array<GSHTrade>> {
      return localVarFp.apiGshSearchaemoGet(tradeId, options).then(request => request(axios, basePath));
    }
  };
};

/**
 * GasSupplyHubDealsApi - object-oriented interface
 * @export
 * @class GasSupplyHubDealsApi
 * @extends {BaseAPI}
 */
export class GasSupplyHubDealsApi extends BaseAPI {
  /**
   *
   * @param {number} [tradeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GasSupplyHubDealsApi
   */
  public apiGshSearchaemoGet(tradeId?: number, options?: any) {
    return GasSupplyHubDealsApiFp(this.configuration)
      .apiGshSearchaemoGet(tradeId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PrivateApiApi - axios parameter creator
 * @export
 */
export const PrivateApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPrivateClearcachePost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/private/clearcache`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * PrivateApiApi - functional programming interface
 * @export
 */
export const PrivateApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PrivateApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPrivateClearcachePost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateClearcachePost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * PrivateApiApi - factory interface
 * @export
 */
export const PrivateApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PrivateApiApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPrivateClearcachePost(options?: any): AxiosPromise<void> {
      return localVarFp.apiPrivateClearcachePost(options).then(request => request(axios, basePath));
    }
  };
};

/**
 * PrivateApiApi - object-oriented interface
 * @export
 * @class PrivateApiApi
 * @extends {BaseAPI}
 */
export class PrivateApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivateApiApi
   */
  public apiPrivateClearcachePost(options?: any) {
    return PrivateApiApiFp(this.configuration)
      .apiPrivateClearcachePost(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ProfilesApi - axios parameter creator
 * @export
 */
export const ProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/profiles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesNormalisationsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/profiles/normalisations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {ProfileCreateRequestModel} [profileCreateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesPost: async (profileCreateRequestModel?: ProfileCreateRequestModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/profiles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(profileCreateRequestModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesPrioritiesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/profiles/priorities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} profileId
     * @param {string} [eEDELETEAUTHORISATIONKEY]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesProfileIdDelete: async (profileId: string, eEDELETEAUTHORISATIONKEY?: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'profileId' is not null or undefined
      assertParamExists("apiV2ProfilesProfileIdDelete", "profileId", profileId);
      const localVarPath = `/api/v1/v2/profiles/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (eEDELETEAUTHORISATIONKEY !== undefined && eEDELETEAUTHORISATIONKEY !== null) {
        localVarHeaderParameter["EE-DELETE-AUTHORISATION-KEY"] = String(eEDELETEAUTHORISATIONKEY);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesProfileIdDeletePost: async (profileId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'profileId' is not null or undefined
      assertParamExists("apiV2ProfilesProfileIdDeletePost", "profileId", profileId);
      const localVarPath = `/api/v1/v2/profiles/{profileId}/delete`.replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesProfileIdGet: async (profileId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'profileId' is not null or undefined
      assertParamExists("apiV2ProfilesProfileIdGet", "profileId", profileId);
      const localVarPath = `/api/v1/v2/profiles/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} profileId
     * @param {ProfileUpdateRequestModel} [profileUpdateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesProfileIdPut: async (
      profileId: string,
      profileUpdateRequestModel?: ProfileUpdateRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'profileId' is not null or undefined
      assertParamExists("apiV2ProfilesProfileIdPut", "profileId", profileId);
      const localVarPath = `/api/v1/v2/profiles/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateRequestModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesResolutionsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/profiles/resolutions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesSeasonsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/profiles/seasons`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesTypesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/profiles/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesUsagesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/v2/profiles/usages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ProfilesApi - functional programming interface
 * @export
 */
export const ProfilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProfilesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileGetSummaryResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesNormalisationsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileNormalisationsGetResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesNormalisationsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ProfileCreateRequestModel} [profileCreateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesPost(
      profileCreateRequestModel?: ProfileCreateRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileCreateResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesPost(profileCreateRequestModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesPrioritiesGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDisplayPriorityGetResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesPrioritiesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} profileId
     * @param {string} [eEDELETEAUTHORISATIONKEY]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesProfileIdDelete(
      profileId: string,
      eEDELETEAUTHORISATIONKEY?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesProfileIdDelete(profileId, eEDELETEAUTHORISATIONKEY, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesProfileIdDeletePost(
      profileId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDeleteRequestResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesProfileIdDeletePost(profileId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesProfileIdGet(
      profileId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileGetResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesProfileIdGet(profileId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} profileId
     * @param {ProfileUpdateRequestModel} [profileUpdateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesProfileIdPut(
      profileId: string,
      profileUpdateRequestModel?: ProfileUpdateRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileUpdateResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesProfileIdPut(profileId, profileUpdateRequestModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesResolutionsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResolutionsGetResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesResolutionsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesSeasonsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSeasonsGetResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesSeasonsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesTypesGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileTypesGetResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesTypesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV2ProfilesUsagesGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileUsageGetResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProfilesUsagesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ProfilesApi - factory interface
 * @export
 */
export const ProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ProfilesApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesGet(options?: any): AxiosPromise<ProfileGetSummaryResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesNormalisationsGet(options?: any): AxiosPromise<ProfileNormalisationsGetResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesNormalisationsGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ProfileCreateRequestModel} [profileCreateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesPost(
      profileCreateRequestModel?: ProfileCreateRequestModel,
      options?: any
    ): AxiosPromise<ProfileCreateResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesPost(profileCreateRequestModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesPrioritiesGet(options?: any): AxiosPromise<ProfileDisplayPriorityGetResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesPrioritiesGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} profileId
     * @param {string} [eEDELETEAUTHORISATIONKEY]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesProfileIdDelete(profileId: string, eEDELETEAUTHORISATIONKEY?: string, options?: any): AxiosPromise<ObjectIApiResponse> {
      return localVarFp
        .apiV2ProfilesProfileIdDelete(profileId, eEDELETEAUTHORISATIONKEY, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesProfileIdDeletePost(profileId: string, options?: any): AxiosPromise<ProfileDeleteRequestResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesProfileIdDeletePost(profileId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesProfileIdGet(profileId: string, options?: any): AxiosPromise<ProfileGetResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesProfileIdGet(profileId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} profileId
     * @param {ProfileUpdateRequestModel} [profileUpdateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesProfileIdPut(
      profileId: string,
      profileUpdateRequestModel?: ProfileUpdateRequestModel,
      options?: any
    ): AxiosPromise<ProfileUpdateResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesProfileIdPut(profileId, profileUpdateRequestModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesResolutionsGet(options?: any): AxiosPromise<ProfileResolutionsGetResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesResolutionsGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesSeasonsGet(options?: any): AxiosPromise<ProfileSeasonsGetResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesSeasonsGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesTypesGet(options?: any): AxiosPromise<ProfileTypesGetResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesTypesGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV2ProfilesUsagesGet(options?: any): AxiosPromise<ProfileUsageGetResponseModelIApiResponse> {
      return localVarFp.apiV2ProfilesUsagesGet(options).then(request => request(axios, basePath));
    }
  };
};

/**
 * ProfilesApi - object-oriented interface
 * @export
 * @class ProfilesApi
 * @extends {BaseAPI}
 */
export class ProfilesApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesGet(options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesNormalisationsGet(options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesNormalisationsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProfileCreateRequestModel} [profileCreateRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesPost(profileCreateRequestModel?: ProfileCreateRequestModel, options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesPost(profileCreateRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesPrioritiesGet(options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesPrioritiesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} profileId
   * @param {string} [eEDELETEAUTHORISATIONKEY]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesProfileIdDelete(profileId: string, eEDELETEAUTHORISATIONKEY?: string, options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesProfileIdDelete(profileId, eEDELETEAUTHORISATIONKEY, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} profileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesProfileIdDeletePost(profileId: string, options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesProfileIdDeletePost(profileId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} profileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesProfileIdGet(profileId: string, options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesProfileIdGet(profileId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} profileId
   * @param {ProfileUpdateRequestModel} [profileUpdateRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesProfileIdPut(profileId: string, profileUpdateRequestModel?: ProfileUpdateRequestModel, options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesProfileIdPut(profileId, profileUpdateRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesResolutionsGet(options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesResolutionsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesSeasonsGet(options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesSeasonsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesTypesGet(options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesTypesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public apiV2ProfilesUsagesGet(options?: any) {
    return ProfilesApiFp(this.configuration)
      .apiV2ProfilesUsagesGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}
