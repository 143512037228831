import config from "./appConfig";
import { MsalProvider } from "@azure/msal-react";
import { MsalApp } from "app/auth";
import { ConnectedRouter } from "connected-react-router";
import FreshworksWidgetHelper from "new/helpers/FreshworksWidgetHelper";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store, { history } from "store/configureStore";

import "internal/dcc-theme/reset.scss";
import insights from "app/insights";
import { StylesProvider } from "@material-ui/core/styles";

insights?.loadAppInsights();

const NewRootComponent = React.lazy(() => import(/* webpackChunkName: "deal-capture" */ "new/RootComponent"));

const siteHostname = `${config.App.Hostname}`;
if (!!siteHostname && document.location.origin !== siteHostname) {
  document.location.replace(`${siteHostname}${document.location.toString().substring(document.location.origin.length)}`);
}

const errorHandler = (error: Event | string) => {
  try {
    FreshworksWidgetHelper.captureError(error instanceof ErrorEvent ? error.error : (error as any));
  } catch {
    // We've tried. Let's just walk away now.
  }
};

ReactDOM.render(
  React.createElement(() => {
    const abortController = new AbortController();

    React.useEffect(() => {
      window.addEventListener("error", errorHandler, { signal: abortController.signal });
      window.addEventListener("unhandledrejection", errorHandler, { signal: abortController.signal });
      window.addEventListener("invalid", errorHandler, { signal: abortController.signal });

      return () => abortController.abort();
    });

    return (
      <StylesProvider>
        <MsalProvider instance={MsalApp}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <React.Suspense fallback={<>Loading...</>}>
                <NewRootComponent appInsights={insights} />
              </React.Suspense>
            </ConnectedRouter>
          </Provider>
        </MsalProvider>
      </StylesProvider>
    );
  }),
  document.getElementById("root")
);
