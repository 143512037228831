import { KeyValue } from "sagas/types/config";

// REDUX STATE
export interface SelectedDetails {
  Book: KeyValue | null;
  LinkedDeal: string | null;
  Strategy: string | null;
}
export interface InternalDetailsSelectorState {
  SelectedDetails: SelectedDetails;
  SelectedTrader: KeyValue | null;
  SelectedDate: Date | null;
}

// action
export const DETAILS_SELECTED = "DEAL_DETALS_SELECTOR/DETAILS_SELECTED";
interface DetailsSelectedAction {
  type: typeof DETAILS_SELECTED;
  payload: SelectedDetails;
}
export const TRADER_SELECTED = "DEAL_DETALS_SELECTOR/TRADER_SELECTED";
interface TraderSelectedAction {
  type: typeof TRADER_SELECTED;
  payload: KeyValue | null;
}
export const DATE_SELECTED = "DEAL_DETALS_SELECTOR/DATE_SELECTED";
interface DateSelectedAction {
  type: typeof DATE_SELECTED;
  payload: Date | null;
}
export const DEAL_DETALS_CLEARED = "DEAL_DETALS_SELECTOR/DEAL_DETALS_CLEARED";
interface DetailsClearedAction {
  type: typeof DEAL_DETALS_CLEARED;
  payload: boolean;
}

export type InternalDetailsSelectorActionTypes = DetailsSelectedAction | TraderSelectedAction | DetailsClearedAction | DateSelectedAction;

// action creators
export function selectInternalDetails(p: SelectedDetails): InternalDetailsSelectorActionTypes {
  return {
    type: DETAILS_SELECTED,
    payload: p
  };
}

export function selectTrader(p: KeyValue | null): InternalDetailsSelectorActionTypes {
  return {
    type: TRADER_SELECTED,
    payload: p
  };
}
export function selectTradingDate(p: Date | null): InternalDetailsSelectorActionTypes {
  return {
    type: DATE_SELECTED,
    payload: p
  };
}
export function clearInternalDetails(p: boolean): InternalDetailsSelectorActionTypes {
  return {
    type: DEAL_DETALS_CLEARED,
    payload: p
  };
}

const initialState: InternalDetailsSelectorState = {
  SelectedDetails: {
    Book: null,
    LinkedDeal: null,
    Strategy: null
  },
  SelectedTrader: null,
  SelectedDate: null
};

export function dealDetailsSelectorReducer(state = initialState, action: InternalDetailsSelectorActionTypes): InternalDetailsSelectorState {
  switch (action.type) {
    case DEAL_DETALS_CLEARED: {
      return initialState;
    }
    case DETAILS_SELECTED: {
      return {
        ...state,
        SelectedDetails: action.payload
      };
    }
    case TRADER_SELECTED: {
      return {
        ...state,
        SelectedTrader: action.payload
      };
    }
    case DATE_SELECTED: {
      return {
        ...state,
        SelectedDate: action.payload
      };
    }
    default:
      return state;
  }
}
