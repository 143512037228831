import { Region, Period, ProductType, Quarter, Strip, Month } from "./enums";
import { Expiry } from "./dates";
import { Exchange, OptionType, SettlementPeriod } from "./enums";

export interface Future {
  Region: Region;
  Period: Period;
  ProductType: ProductType;
  Expiry: Expiry;
  Quarter?: Quarter;
  Strip?: Strip;
  Ticker?: string;
  SettlementPeriod?: SettlementPeriod;
  Exchange?: Exchange;
  FromQuickSelector?: boolean | null;
  Hours?: number | null;
  CapStrike?: number | null;
}

export interface Option extends Future {
  OptionType: OptionType;
  Strike: number | null;
  ExpiryDate?: Date | null;
  American?: boolean | null;
  European?: boolean | null;
}

export type ExchangeProduct = Future | Option | null;

export const emptyFuture: Future = {
  Expiry: { Month: Month.Undefined, Year: 0 },
  Strip: Strip.Undefined,
  Region: Region.Undefined,
  ProductType: ProductType.Undefined,
  Period: Period.Undefined,
  Ticker: ""
};

export const emptyOption: Option = {
  Expiry: { Month: Month.Undefined, Year: 0 },
  Strike: null,
  OptionType: OptionType.Undefined,
  Region: Region.Undefined,
  ProductType: ProductType.Undefined,
  Ticker: "",
  Period: Period.Base
};

const RegionLookup: Map<Region, string> = new Map<Region, string>();
RegionLookup.set(Region.NSW, "N");
RegionLookup.set(Region.QLD, "Q");
RegionLookup.set(Region.VIC, "V");
RegionLookup.set(Region.SA, "S");

export { RegionLookup };

const RegionReverseLookup: Map<string, Region> = new Map<string, Region>();
RegionReverseLookup.set("N", Region.NSW);
RegionReverseLookup.set("Q", Region.QLD);
RegionReverseLookup.set("V", Region.VIC);
RegionReverseLookup.set("S", Region.SA);

export { RegionReverseLookup };

const OptionProductTypePeriodLookup: Map<string, [ProductType, Period]> = new Map<string, [ProductType, Period]>();
OptionProductTypePeriodLookup.set("B", [ProductType.ASIANS, Period.Base]);
OptionProductTypePeriodLookup.set("H", [ProductType.OPTIONS, Period.Base]);

export { OptionProductTypePeriodLookup };

const ProductTypePeriodLookup: Map<string, [ProductType, Period]> = new Map<string, [ProductType, Period]>();
ProductTypePeriodLookup.set("E", [ProductType.MONTH_FUTURES, Period.Base]);
ProductTypePeriodLookup.set("B", [ProductType.QUARTER_FUTURES, Period.Base]);
ProductTypePeriodLookup.set("P", [ProductType.QUARTER_FUTURES, Period.Peak]);
ProductTypePeriodLookup.set("H", [ProductType.FUTURES_STRIPS, Period.Base]);
ProductTypePeriodLookup.set("D", [ProductType.FUTURES_STRIPS, Period.Peak]);

ProductTypePeriodLookup.set("G", [ProductType.CAP_QUARTERS, Period.Base]);
ProductTypePeriodLookup.set("R", [ProductType.CAP_STRIPS, Period.Base]);
ProductTypePeriodLookup.set("F", [ProductType.CAP_QUARTERS, Period.Base]);
ProductTypePeriodLookup.set("Q", [ProductType.CAP_STRIPS, Period.Base]);

export { ProductTypePeriodLookup };

const QuarterPeriodLookup: Map<Period, string> = new Map<Period, string>();
QuarterPeriodLookup.set(Period.Base, "B");
QuarterPeriodLookup.set(Period.Peak, "P");

export { QuarterPeriodLookup };

const StripPeriodLookup: Map<Period, string> = new Map<Period, string>();
StripPeriodLookup.set(Period.Base, "H");
StripPeriodLookup.set(Period.Peak, "D");

export { StripPeriodLookup };

const OptionTypeReverseLookup: Map<string, OptionType> = new Map<string, OptionType>();
OptionTypeReverseLookup.set("C", OptionType.CALL);
OptionTypeReverseLookup.set("P", OptionType.PUT);

export { OptionTypeReverseLookup };

const OptionTypeLookup: Map<OptionType, string> = new Map<OptionType, string>();
OptionTypeLookup.set(OptionType.CALL, "C");
OptionTypeLookup.set(OptionType.PUT, "P");

export { OptionTypeLookup };
