import * as React from "react";

const usePageTitle = (title: string) => {
  React.useEffect(() => {
    if (!title || title == null) return;

    const currentTitle = document.title;
    document.title = `${title} - Deal Capture`.replace(/(^[\s-]+)|([\s-]+$)/gi, "");

    return () => {
      document.title = currentTitle;
    };
  }, [title]);
};

export default usePageTitle;
