import { MarketDealIntermediariesApi } from "api/intermediariesApiClient";
import { Period } from "internal/dcc-types";
import { CounterpartyModel } from "sagas/api";

export interface HTTPReply {
  data: IApiResponse;
}

export interface Reply {
  message: string;
}

export interface IApiResponse {
  Status: "Success" | "Error" | "Failed";
  Header: any;
  Body: any;
  Error: any;
}

export interface IApiError {
  Message: string;
  Status: number;
  StatusText: string;
}

export interface Requestor {
  RequestorId?: number;
  Clear?: boolean;
}

export interface KeyValue<T = number> {
  id: T;
  value: string;
  sortOrder?: number | null;
}

export interface EnabledKeyValue extends KeyValue {
  Enabled?: boolean | null;
}

export interface EnabledSKeyValue extends KeyValue<string> {
  sortOrder?: number | null;
  Enabled?: boolean | null;
}

export interface ClearerAccount {
  id: number;
  value: string;
  sortOrder?: number | null;
  CloseoutMethodology?: MarketDealIntermediariesApi.CloseoutMethodologyType | null;
}

export enum creationYearValue {
  Applicable = "Applicable",
  NotApplicable = "Not Applicable"
}

export interface EnvironValue extends KeyValue {
  creationYear?: creationYearValue | null;
  generationYear?: creationYearValue | null;
  creationMessage?: string | null;
  CurrencyCode?: string | null;
  Enabled?: boolean | null;
}

export interface InternalDetailsConfig {
  LoggedOnUserEmail?: string | null;
  LoggedOnUser: KeyValue | null;
  Books: EnabledKeyValue[];
  Traders: KeyValue[];
  Counterparties: Array<CounterpartyModel> | null;
  FirmnessDependencies: KeyValue[];
  EnvironExclusions: EnabledKeyValue[];
  EnvironCreationYears: EnabledKeyValue[];
  EnvironProducts: EnvironValue[];
}

export interface InterconnectorValue {
  id: number;
  value: string;
  from: string;
  to: string;
  sortOrder?: number | null;
}

export interface MarketSettings {
  OrgId: number;
  OrgName: string;
  Interconnectors: InterconnectorValue[];
  FloorStrikes: KeyValue[];
  CapStrikes: KeyValue[];
  UnitOfMeasures: KeyValue[];
}

export interface Fee {
  id: number;
  Price: number;
  value: string;
  UnitOfMeasure: KeyValue;
  EffectiveDate: string;
  sortOrder?: number | null;
}

export interface ExchangeFee extends Fee {
  Period: Period;
}

export interface OTCFee extends Fee {
  Condition?: string | null;
  Volume?: number | null;
}

export interface FeeChargingEntity {
  Name: KeyValue;
  ASXFees: ExchangeFee[];
  FEXFees: ExchangeFee[];
  Enabled?: boolean;
  EnabledOnASX?: boolean;
  EnabledOnFEX?: boolean;
}

export interface Broker extends FeeChargingEntity {
  EnabledOnOTC?: boolean;
  EnabledOnGSH?: boolean;
  EnabledOnENV?: boolean;
  EPFees: Fee[];
  SRAFees: Fee[];
  AEMOFees: ExchangeFee[];
  OTCFees: OTCFee[];
}

export interface Exchange {
  Name: KeyValue;
  Fees: ExchangeFee[];
}

export interface Clearer extends FeeChargingEntity {
  Accounts: ClearerAccount[];
}

export interface DefaultFees {
  Brokers: Broker[];
  Clearers: Clearer[];
  ASX: Exchange | null;
  FEX: Exchange | null;
  AEMO: Exchange | null;
}

export enum EditAction {
  Add = "Add",
  Edit = "Edit",
  Remove = "Remove"
}

export interface Tradable {
  Ticker: string;
  Volume: number;
  Price: number;
  Premium?: number;
  Hours?: number;
  EffectiveDate?: Date | null;
  Exchange?: string | null;
  ThirtyMinutes?: boolean | null;
}

export interface TradableAction {
  Action: EditAction;
  isPending?: boolean | null;
  Items: TradableExerciseLeg[];
}

export interface TradableExerciseLeg extends Tradable {
  LegId?: number | null;
  OptionType?: number | null;
}

export interface TradableLeg extends Tradable {
  DealLegId?: number | null;
}
