/* tslint:disable */
/* eslint-disable */
/**
 * Deal Capture Gateway
 * Gateway to deal capture services.
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @enum {string}
 */

export const ApiResponseStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2
} as const;

export type ApiResponseStatus = (typeof ApiResponseStatus)[keyof typeof ApiResponseStatus];

/**
 *
 * @export
 * @interface CreateEndpointRequestDataModel
 */
export interface CreateEndpointRequestDataModel {
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestDataModel
   */
  startDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestDataModel
   */
  endDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestDataModel
   */
  periodName?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateEndpointRequestDataModel
   */
  forecastValue?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateEndpointRequestDataModel
   */
  actualValue?: number | null;
}
/**
 *
 * @export
 * @interface CreateEndpointRequestModel
 */
export interface CreateEndpointRequestModel {
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestModel
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestModel
   */
  source?: string | null;
  /**
   *
   * @type {Array<CreateEndpointRequestDataModel>}
   * @memberof CreateEndpointRequestModel
   */
  data?: Array<CreateEndpointRequestDataModel> | null;
  /**
   *
   * @type {Array<CreateEndpointRequestNotificationModel>}
   * @memberof CreateEndpointRequestModel
   */
  notifications?: Array<CreateEndpointRequestNotificationModel> | null;
}
/**
 *
 * @export
 * @interface CreateEndpointRequestNotificationModel
 */
export interface CreateEndpointRequestNotificationModel {
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestNotificationModel
   */
  roleId?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateEndpointRequestNotificationModel
   */
  periodDuration?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestNotificationModel
   */
  periodName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateEndpointRequestNotificationModel
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @interface CreateEndpointResponseModel
 */
export interface CreateEndpointResponseModel {
  /**
   *
   * @type {string}
   * @memberof CreateEndpointResponseModel
   */
  escalationIndexId?: string;
}
/**
 *
 * @export
 * @interface CreateEndpointResponseModelIApiResponse
 */
export interface CreateEndpointResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof CreateEndpointResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof CreateEndpointResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {CreateEndpointResponseModel}
   * @memberof CreateEndpointResponseModelIApiResponse
   */
  body?: CreateEndpointResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof CreateEndpointResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface DeleteEndpointResponseModel
 */
export interface DeleteEndpointResponseModel {
  /**
   *
   * @type {string}
   * @memberof DeleteEndpointResponseModel
   */
  escalationId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeleteEndpointResponseModel
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteEndpointResponseModelIApiResponse
 */
export interface DeleteEndpointResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DeleteEndpointResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DeleteEndpointResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {DeleteEndpointResponseModel}
   * @memberof DeleteEndpointResponseModelIApiResponse
   */
  body?: DeleteEndpointResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof DeleteEndpointResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface DeleteRequestEndpointResponseModel
 */
export interface DeleteRequestEndpointResponseModel {
  /**
   *
   * @type {string}
   * @memberof DeleteRequestEndpointResponseModel
   */
  escalationId?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRequestEndpointResponseModel
   */
  deleteToken?: string | null;
}
/**
 *
 * @export
 * @interface DeleteRequestEndpointResponseModelIApiResponse
 */
export interface DeleteRequestEndpointResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DeleteRequestEndpointResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DeleteRequestEndpointResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {DeleteRequestEndpointResponseModel}
   * @memberof DeleteRequestEndpointResponseModelIApiResponse
   */
  body?: DeleteRequestEndpointResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof DeleteRequestEndpointResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface DisableEndpointResponseDataModel
 */
export interface DisableEndpointResponseDataModel {
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseDataModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseDataModel
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseDataModel
   */
  periodName?: string | null;
  /**
   *
   * @type {number}
   * @memberof DisableEndpointResponseDataModel
   */
  forecastValue?: number;
  /**
   *
   * @type {number}
   * @memberof DisableEndpointResponseDataModel
   */
  actualValue?: number;
}
/**
 *
 * @export
 * @interface DisableEndpointResponseModel
 */
export interface DisableEndpointResponseModel {
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseModel
   */
  escalationId?: string;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseModel
   */
  escalationName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DisableEndpointResponseModel
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseModel
   */
  source?: string | null;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseModel
   */
  forecastEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseModel
   */
  actualEndDate?: string;
  /**
   *
   * @type {Array<DisableEndpointResponseDataModel>}
   * @memberof DisableEndpointResponseModel
   */
  data?: Array<DisableEndpointResponseDataModel> | null;
  /**
   *
   * @type {Array<DisableEndpointResponseNotificationModel>}
   * @memberof DisableEndpointResponseModel
   */
  notifications?: Array<DisableEndpointResponseNotificationModel> | null;
}
/**
 *
 * @export
 * @interface DisableEndpointResponseModelIApiResponse
 */
export interface DisableEndpointResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof DisableEndpointResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof DisableEndpointResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {DisableEndpointResponseModel}
   * @memberof DisableEndpointResponseModelIApiResponse
   */
  body?: DisableEndpointResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof DisableEndpointResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface DisableEndpointResponseNotificationModel
 */
export interface DisableEndpointResponseNotificationModel {
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseNotificationModel
   */
  roleId?: string;
  /**
   *
   * @type {number}
   * @memberof DisableEndpointResponseNotificationModel
   */
  periodDuration?: number;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseNotificationModel
   */
  periodName?: string | null;
  /**
   *
   * @type {string}
   * @memberof DisableEndpointResponseNotificationModel
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @interface EnableEndpointResponseDataModel
 */
export interface EnableEndpointResponseDataModel {
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseDataModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseDataModel
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseDataModel
   */
  periodName?: string | null;
  /**
   *
   * @type {number}
   * @memberof EnableEndpointResponseDataModel
   */
  forecastValue?: number;
  /**
   *
   * @type {number}
   * @memberof EnableEndpointResponseDataModel
   */
  actualValue?: number;
}
/**
 *
 * @export
 * @interface EnableEndpointResponseModel
 */
export interface EnableEndpointResponseModel {
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseModel
   */
  escalationId?: string;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseModel
   */
  escalationName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof EnableEndpointResponseModel
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseModel
   */
  source?: string | null;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseModel
   */
  forecastEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseModel
   */
  actualEndDate?: string;
  /**
   *
   * @type {Array<EnableEndpointResponseDataModel>}
   * @memberof EnableEndpointResponseModel
   */
  data?: Array<EnableEndpointResponseDataModel> | null;
  /**
   *
   * @type {Array<EnableEndpointResponseNotificationModel>}
   * @memberof EnableEndpointResponseModel
   */
  notifications?: Array<EnableEndpointResponseNotificationModel> | null;
}
/**
 *
 * @export
 * @interface EnableEndpointResponseModelIApiResponse
 */
export interface EnableEndpointResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof EnableEndpointResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof EnableEndpointResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {EnableEndpointResponseModel}
   * @memberof EnableEndpointResponseModelIApiResponse
   */
  body?: EnableEndpointResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof EnableEndpointResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface EnableEndpointResponseNotificationModel
 */
export interface EnableEndpointResponseNotificationModel {
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseNotificationModel
   */
  roleId?: string;
  /**
   *
   * @type {number}
   * @memberof EnableEndpointResponseNotificationModel
   */
  periodDuration?: number;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseNotificationModel
   */
  periodName?: string | null;
  /**
   *
   * @type {string}
   * @memberof EnableEndpointResponseNotificationModel
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @interface ErrorDetail
 */
export interface ErrorDetail {
  /**
   *
   * @type {string}
   * @memberof ErrorDetail
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof ErrorDetail
   */
  message?: string | null;
}
/**
 *
 * @export
 * @interface GetEndpointResponseModel
 */
export interface GetEndpointResponseModel {
  /**
   *
   * @type {string}
   * @memberof GetEndpointResponseModel
   */
  escalationId?: string;
  /**
   *
   * @type {string}
   * @memberof GetEndpointResponseModel
   */
  escalationName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetEndpointResponseModel
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetEndpointResponseModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetEndpointResponseModel
   */
  source?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetEndpointResponseModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof GetEndpointResponseModel
   */
  forecastEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof GetEndpointResponseModel
   */
  actualEndDate?: string;
  /**
   *
   * @type {Array<GetResponseDataModel>}
   * @memberof GetEndpointResponseModel
   */
  data?: Array<GetResponseDataModel> | null;
  /**
   *
   * @type {Array<GetResponseNotificationModel>}
   * @memberof GetEndpointResponseModel
   */
  notifications?: Array<GetResponseNotificationModel> | null;
}
/**
 *
 * @export
 * @interface GetEndpointResponseModelIApiResponse
 */
export interface GetEndpointResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof GetEndpointResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof GetEndpointResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {GetEndpointResponseModel}
   * @memberof GetEndpointResponseModelIApiResponse
   */
  body?: GetEndpointResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof GetEndpointResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface GetResponseDataModel
 */
export interface GetResponseDataModel {
  /**
   *
   * @type {string}
   * @memberof GetResponseDataModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof GetResponseDataModel
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof GetResponseDataModel
   */
  periodName?: string | null;
  /**
   *
   * @type {number}
   * @memberof GetResponseDataModel
   */
  forecastValue?: number;
  /**
   *
   * @type {number}
   * @memberof GetResponseDataModel
   */
  actualValue?: number;
}
/**
 *
 * @export
 * @interface GetResponseNotificationModel
 */
export interface GetResponseNotificationModel {
  /**
   *
   * @type {string}
   * @memberof GetResponseNotificationModel
   */
  roleId?: string;
  /**
   *
   * @type {number}
   * @memberof GetResponseNotificationModel
   */
  periodDuration?: number;
  /**
   *
   * @type {string}
   * @memberof GetResponseNotificationModel
   */
  periodName?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetResponseNotificationModel
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @interface HistoryResponseCurrentDataModel
 */
export interface HistoryResponseCurrentDataModel {
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentDataModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentDataModel
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentDataModel
   */
  periodName?: string | null;
  /**
   *
   * @type {number}
   * @memberof HistoryResponseCurrentDataModel
   */
  forecastValue?: number;
  /**
   *
   * @type {number}
   * @memberof HistoryResponseCurrentDataModel
   */
  actualValue?: number;
}
/**
 *
 * @export
 * @interface HistoryResponseCurrentModel
 */
export interface HistoryResponseCurrentModel {
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentModel
   */
  escalationId?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentModel
   */
  escalationName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof HistoryResponseCurrentModel
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentModel
   */
  source?: string | null;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentModel
   */
  forecastEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentModel
   */
  actualEndDate?: string;
  /**
   *
   * @type {Array<HistoryResponseCurrentDataModel>}
   * @memberof HistoryResponseCurrentModel
   */
  data?: Array<HistoryResponseCurrentDataModel> | null;
  /**
   *
   * @type {Array<HistoryResponseCurrentNotificationModel>}
   * @memberof HistoryResponseCurrentModel
   */
  notifications?: Array<HistoryResponseCurrentNotificationModel> | null;
}
/**
 *
 * @export
 * @interface HistoryResponseCurrentNotificationModel
 */
export interface HistoryResponseCurrentNotificationModel {
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentNotificationModel
   */
  roleId?: string;
  /**
   *
   * @type {number}
   * @memberof HistoryResponseCurrentNotificationModel
   */
  periodDuration?: number;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentNotificationModel
   */
  periodName?: string | null;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseCurrentNotificationModel
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @interface HistoryResponseHistoryModel
 */
export interface HistoryResponseHistoryModel {
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  escalationId?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  escalationName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof HistoryResponseHistoryModel
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  source?: string | null;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  forecastEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  actualEndDate?: string;
  /**
   *
   * @type {Array<HistoryResponseCurrentDataModel>}
   * @memberof HistoryResponseHistoryModel
   */
  data?: Array<HistoryResponseCurrentDataModel> | null;
  /**
   *
   * @type {Array<HistoryResponseCurrentNotificationModel>}
   * @memberof HistoryResponseHistoryModel
   */
  notifications?: Array<HistoryResponseCurrentNotificationModel> | null;
  /**
   *
   * @type {number}
   * @memberof HistoryResponseHistoryModel
   */
  versionNo?: number;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  updatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryResponseHistoryModel
   */
  reason?: string | null;
}
/**
 *
 * @export
 * @interface HistoryResponseModel
 */
export interface HistoryResponseModel {
  /**
   *
   * @type {HistoryResponseCurrentModel}
   * @memberof HistoryResponseModel
   */
  current?: HistoryResponseCurrentModel;
  /**
   *
   * @type {Array<HistoryResponseHistoryModel>}
   * @memberof HistoryResponseModel
   */
  history?: Array<HistoryResponseHistoryModel> | null;
}
/**
 *
 * @export
 * @interface HistoryResponseModelIApiResponse
 */
export interface HistoryResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof HistoryResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof HistoryResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {HistoryResponseModel}
   * @memberof HistoryResponseModelIApiResponse
   */
  body?: HistoryResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof HistoryResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface IBadRequestResponse
 */
export interface IBadRequestResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof IBadRequestResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof IBadRequestResponse
   */
  header?: any | null;
  /**
   *
   * @type {any}
   * @memberof IBadRequestResponse
   */
  body?: any | null;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof IBadRequestResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface ListEndpointResponseModel
 */
export interface ListEndpointResponseModel {
  /**
   *
   * @type {Array<ListEndpointResponseSummaryModel>}
   * @memberof ListEndpointResponseModel
   */
  indices?: Array<ListEndpointResponseSummaryModel> | null;
}
/**
 *
 * @export
 * @interface ListEndpointResponseModelIApiResponse
 */
export interface ListEndpointResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof ListEndpointResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof ListEndpointResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {ListEndpointResponseModel}
   * @memberof ListEndpointResponseModelIApiResponse
   */
  body?: ListEndpointResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof ListEndpointResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface ListEndpointResponseSummaryModel
 */
export interface ListEndpointResponseSummaryModel {
  /**
   *
   * @type {string}
   * @memberof ListEndpointResponseSummaryModel
   */
  escalationId?: string;
  /**
   *
   * @type {string}
   * @memberof ListEndpointResponseSummaryModel
   */
  escalationName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ListEndpointResponseSummaryModel
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ListEndpointResponseSummaryModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof ListEndpointResponseSummaryModel
   */
  source?: string | null;
  /**
   *
   * @type {string}
   * @memberof ListEndpointResponseSummaryModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof ListEndpointResponseSummaryModel
   */
  forecastEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ListEndpointResponseSummaryModel
   */
  actualEndDate?: string;
}
/**
 *
 * @export
 * @interface UpdateEndpointRequestDataModel
 */
export interface UpdateEndpointRequestDataModel {
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestDataModel
   */
  startDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestDataModel
   */
  endDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestDataModel
   */
  periodName?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEndpointRequestDataModel
   */
  forecastValue?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEndpointRequestDataModel
   */
  actualValue?: number | null;
}
/**
 *
 * @export
 * @interface UpdateEndpointRequestModel
 */
export interface UpdateEndpointRequestModel {
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestModel
   */
  escalationId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestModel
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateEndpointRequestModel
   */
  enabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestModel
   */
  source?: string | null;
  /**
   *
   * @type {Array<UpdateEndpointRequestDataModel>}
   * @memberof UpdateEndpointRequestModel
   */
  data?: Array<UpdateEndpointRequestDataModel> | null;
  /**
   *
   * @type {Array<UpdateEndpointRequestNotificationModel>}
   * @memberof UpdateEndpointRequestModel
   */
  notifications?: Array<UpdateEndpointRequestNotificationModel> | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestModel
   */
  reason?: string | null;
}
/**
 *
 * @export
 * @interface UpdateEndpointRequestNotificationModel
 */
export interface UpdateEndpointRequestNotificationModel {
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestNotificationModel
   */
  roleId?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEndpointRequestNotificationModel
   */
  periodDuration?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestNotificationModel
   */
  periodName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointRequestNotificationModel
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @interface UpdateEndpointResponseModel
 */
export interface UpdateEndpointResponseModel {
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointResponseModel
   */
  escalationId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointResponseModel
   */
  escalationName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateEndpointResponseModel
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointResponseModel
   */
  frequency?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointResponseModel
   */
  source?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointResponseModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointResponseModel
   */
  forecastEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateEndpointResponseModel
   */
  actualEndDate?: string;
  /**
   *
   * @type {Array<UpdateResponseDataModel>}
   * @memberof UpdateEndpointResponseModel
   */
  data?: Array<UpdateResponseDataModel> | null;
  /**
   *
   * @type {Array<UpdateResponseNotificationModel>}
   * @memberof UpdateEndpointResponseModel
   */
  notifications?: Array<UpdateResponseNotificationModel> | null;
}
/**
 *
 * @export
 * @interface UpdateEndpointResponseModelIApiResponse
 */
export interface UpdateEndpointResponseModelIApiResponse {
  /**
   *
   * @type {ApiResponseStatus}
   * @memberof UpdateEndpointResponseModelIApiResponse
   */
  status?: ApiResponseStatus;
  /**
   *
   * @type {any}
   * @memberof UpdateEndpointResponseModelIApiResponse
   */
  header?: any | null;
  /**
   *
   * @type {UpdateEndpointResponseModel}
   * @memberof UpdateEndpointResponseModelIApiResponse
   */
  body?: UpdateEndpointResponseModel;
  /**
   *
   * @type {Array<ErrorDetail>}
   * @memberof UpdateEndpointResponseModelIApiResponse
   */
  error?: Array<ErrorDetail> | null;
}

/**
 *
 * @export
 * @interface UpdateResponseDataModel
 */
export interface UpdateResponseDataModel {
  /**
   *
   * @type {string}
   * @memberof UpdateResponseDataModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateResponseDataModel
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateResponseDataModel
   */
  periodName?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateResponseDataModel
   */
  forecastValue?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateResponseDataModel
   */
  actualValue?: number;
}
/**
 *
 * @export
 * @interface UpdateResponseNotificationModel
 */
export interface UpdateResponseNotificationModel {
  /**
   *
   * @type {string}
   * @memberof UpdateResponseNotificationModel
   */
  roleId?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateResponseNotificationModel
   */
  periodDuration?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateResponseNotificationModel
   */
  periodName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateResponseNotificationModel
   */
  reference?: string | null;
}

/**
 * IndexApi - axios parameter creator
 * @export
 */
export const IndexApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateEndpointRequestModel} createEndpointRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationCreate: async (
      createEndpointRequestModel: CreateEndpointRequestModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createEndpointRequestModel' is not null or undefined
      assertParamExists("indicesEscalationCreate", "createEndpointRequestModel", createEndpointRequestModel);
      const localVarPath = `/api/v2/indices/escalation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createEndpointRequestModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} escalationId
     * @param {string} eEDELETETOKEN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationDelete: async (
      escalationId: string,
      eEDELETETOKEN: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'escalationId' is not null or undefined
      assertParamExists("indicesEscalationDelete", "escalationId", escalationId);
      // verify required parameter 'eEDELETETOKEN' is not null or undefined
      assertParamExists("indicesEscalationDelete", "eEDELETETOKEN", eEDELETETOKEN);
      const localVarPath = `/api/v2/indices/escalation/{escalationId}`.replace(
        `{${"escalationId"}}`,
        encodeURIComponent(String(escalationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (eEDELETETOKEN != null) {
        localVarHeaderParameter["EE-DELETE-TOKEN"] = String(eEDELETETOKEN);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationDeleteRequest: async (escalationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'escalationId' is not null or undefined
      assertParamExists("indicesEscalationDeleteRequest", "escalationId", escalationId);
      const localVarPath = `/api/v2/indices/escalation/{escalationId}/delete`.replace(
        `{${"escalationId"}}`,
        encodeURIComponent(String(escalationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationDisable: async (escalationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'escalationId' is not null or undefined
      assertParamExists("indicesEscalationDisable", "escalationId", escalationId);
      const localVarPath = `/api/v2/indices/escalation/{escalationId}/disable`.replace(
        `{${"escalationId"}}`,
        encodeURIComponent(String(escalationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationEnable: async (escalationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'escalationId' is not null or undefined
      assertParamExists("indicesEscalationEnable", "escalationId", escalationId);
      const localVarPath = `/api/v2/indices/escalation/{escalationId}/enable`.replace(
        `{${"escalationId"}}`,
        encodeURIComponent(String(escalationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationGet: async (escalationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'escalationId' is not null or undefined
      assertParamExists("indicesEscalationGet", "escalationId", escalationId);
      const localVarPath = `/api/v2/indices/escalation/{escalationId}`.replace(
        `{${"escalationId"}}`,
        encodeURIComponent(String(escalationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationHistory: async (escalationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'escalationId' is not null or undefined
      assertParamExists("indicesEscalationHistory", "escalationId", escalationId);
      const localVarPath = `/api/v2/indices/escalation/{escalationId}/history`.replace(
        `{${"escalationId"}}`,
        encodeURIComponent(String(escalationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {boolean} [includeDisabled]
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationList: async (
      includeDisabled?: boolean,
      includeInactive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/indices/escalation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeDisabled !== undefined) {
        localVarQueryParameter["includeDisabled"] = includeDisabled;
      }

      if (includeInactive !== undefined) {
        localVarQueryParameter["includeInactive"] = includeInactive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationLogs: async (escalationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'escalationId' is not null or undefined
      assertParamExists("indicesEscalationLogs", "escalationId", escalationId);
      const localVarPath = `/api/v2/indices/escalation/{escalationId}/logs`.replace(
        `{${"escalationId"}}`,
        encodeURIComponent(String(escalationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} escalationId
     * @param {UpdateEndpointRequestModel} updateEndpointRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationUpdate: async (
      escalationId: string,
      updateEndpointRequestModel: UpdateEndpointRequestModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'escalationId' is not null or undefined
      assertParamExists("indicesEscalationUpdate", "escalationId", escalationId);
      // verify required parameter 'updateEndpointRequestModel' is not null or undefined
      assertParamExists("indicesEscalationUpdate", "updateEndpointRequestModel", updateEndpointRequestModel);
      const localVarPath = `/api/v2/indices/escalation/{escalationId}`.replace(
        `{${"escalationId"}}`,
        encodeURIComponent(String(escalationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateEndpointRequestModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * IndexApi - functional programming interface
 * @export
 */
export const IndexApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = IndexApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateEndpointRequestModel} createEndpointRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationCreate(
      createEndpointRequestModel: CreateEndpointRequestModel,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateEndpointResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationCreate(createEndpointRequestModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} escalationId
     * @param {string} eEDELETETOKEN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationDelete(
      escalationId: string,
      eEDELETETOKEN: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteEndpointResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationDelete(escalationId, eEDELETETOKEN, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationDeleteRequest(
      escalationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteRequestEndpointResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationDeleteRequest(escalationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationDisable(
      escalationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisableEndpointResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationDisable(escalationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationEnable(
      escalationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnableEndpointResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationEnable(escalationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationGet(
      escalationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEndpointResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationGet(escalationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationHistory(
      escalationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationHistory(escalationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {boolean} [includeDisabled]
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationList(
      includeDisabled?: boolean,
      includeInactive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEndpointResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationList(includeDisabled, includeInactive, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationLogs(
      escalationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationLogs(escalationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} escalationId
     * @param {UpdateEndpointRequestModel} updateEndpointRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indicesEscalationUpdate(
      escalationId: string,
      updateEndpointRequestModel: UpdateEndpointRequestModel,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateEndpointResponseModelIApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indicesEscalationUpdate(escalationId, updateEndpointRequestModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * IndexApi - factory interface
 * @export
 */
export const IndexApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = IndexApiFp(configuration);
  return {
    /**
     *
     * @param {CreateEndpointRequestModel} createEndpointRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationCreate(
      createEndpointRequestModel: CreateEndpointRequestModel,
      options?: any
    ): AxiosPromise<CreateEndpointResponseModelIApiResponse> {
      return localVarFp.indicesEscalationCreate(createEndpointRequestModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} escalationId
     * @param {string} eEDELETETOKEN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationDelete(
      escalationId: string,
      eEDELETETOKEN: string,
      options?: any
    ): AxiosPromise<DeleteEndpointResponseModelIApiResponse> {
      return localVarFp.indicesEscalationDelete(escalationId, eEDELETETOKEN, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationDeleteRequest(escalationId: string, options?: any): AxiosPromise<DeleteRequestEndpointResponseModelIApiResponse> {
      return localVarFp.indicesEscalationDeleteRequest(escalationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationDisable(escalationId: string, options?: any): AxiosPromise<DisableEndpointResponseModelIApiResponse> {
      return localVarFp.indicesEscalationDisable(escalationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationEnable(escalationId: string, options?: any): AxiosPromise<EnableEndpointResponseModelIApiResponse> {
      return localVarFp.indicesEscalationEnable(escalationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationGet(escalationId: string, options?: any): AxiosPromise<GetEndpointResponseModelIApiResponse> {
      return localVarFp.indicesEscalationGet(escalationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationHistory(escalationId: string, options?: any): AxiosPromise<HistoryResponseModelIApiResponse> {
      return localVarFp.indicesEscalationHistory(escalationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [includeDisabled]
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationList(
      includeDisabled?: boolean,
      includeInactive?: boolean,
      options?: any
    ): AxiosPromise<ListEndpointResponseModelIApiResponse> {
      return localVarFp.indicesEscalationList(includeDisabled, includeInactive, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} escalationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationLogs(escalationId: string, options?: any): AxiosPromise<HistoryResponseModelIApiResponse> {
      return localVarFp.indicesEscalationLogs(escalationId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} escalationId
     * @param {UpdateEndpointRequestModel} updateEndpointRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indicesEscalationUpdate(
      escalationId: string,
      updateEndpointRequestModel: UpdateEndpointRequestModel,
      options?: any
    ): AxiosPromise<UpdateEndpointResponseModelIApiResponse> {
      return localVarFp
        .indicesEscalationUpdate(escalationId, updateEndpointRequestModel, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * IndexApi - object-oriented interface
 * @export
 * @class IndexApi
 * @extends {BaseAPI}
 */
export class IndexApi extends BaseAPI {
  /**
   *
   * @param {CreateEndpointRequestModel} createEndpointRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationCreate(createEndpointRequestModel: CreateEndpointRequestModel, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationCreate(createEndpointRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} escalationId
   * @param {string} eEDELETETOKEN
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationDelete(escalationId: string, eEDELETETOKEN: string, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationDelete(escalationId, eEDELETETOKEN, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} escalationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationDeleteRequest(escalationId: string, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationDeleteRequest(escalationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} escalationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationDisable(escalationId: string, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationDisable(escalationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} escalationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationEnable(escalationId: string, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationEnable(escalationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} escalationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationGet(escalationId: string, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationGet(escalationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} escalationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationHistory(escalationId: string, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationHistory(escalationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} [includeDisabled]
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationList(includeDisabled?: boolean, includeInactive?: boolean, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationList(includeDisabled, includeInactive, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} escalationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationLogs(escalationId: string, options?: AxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .indicesEscalationLogs(escalationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} escalationId
   * @param {UpdateEndpointRequestModel} updateEndpointRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public indicesEscalationUpdate(
    escalationId: string,
    updateEndpointRequestModel: UpdateEndpointRequestModel,
    options?: AxiosRequestConfig
  ) {
    return IndexApiFp(this.configuration)
      .indicesEscalationUpdate(escalationId, updateEndpointRequestModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}
