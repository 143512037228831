import React, { useRef, KeyboardEvent } from "react";
import { SelectButton } from "app/components/Button";
import { Flex } from "internal/dcc-components";
import styled from "styled-components/macro";
import { Label } from "internal/dcc-components/styled";

export interface ToggleButtonItem {
  label: string;
  primary: boolean;
  disable?: boolean;
  sortIndex?: number;
}

export interface ToggleButtonProps {
  id?: string;
  items: ToggleButtonItem[];
  width: string;
  selectedLabel: string;
  onSelected(e: string): void;
  groupLabel?: string;
  tabIndex?: number;
}

const FlexWrapper = styled(Flex)`
  flex-flow: row wrap;
  width: 100%;
  margin: 0 auto;
  border-radius: 3px;

  background-color: none};
`;
//

export function ToggleButton(props: ToggleButtonProps) {
  const buttonsRef = useRef<HTMLButtonElement[]>([]);

  const handleOnKeyDown = (e: KeyboardEvent<HTMLButtonElement>): void => {
    const t = e.target as Element;
    if (e.key === "ArrowRight") {
      buttonsRef.current[(Number(t.id) + 1) % items.length]?.focus();
    }
    if (e.key === "ArrowLeft") {
      const indx = Number(t.id) === 0 ? items.length - 1 : (Number(t.id) - 1) % items.length;
      buttonsRef.current[indx]?.focus();
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>) => {
    let selected = (event.target as HTMLInputElement).innerText;
    props.onSelected(selected);
  };

  const toggleId = props.id || Math.round(Math.random() * 9999999).toString(16);

  const items = props.items
    .sort((a, b) => (a.sortIndex && b.sortIndex ? (a.sortIndex > b.sortIndex ? 1 : -1) : 0))
    .map((i, inx) => (
      <SelectButton
        onKeyDown={handleOnKeyDown}
        ref={(el: any) => (buttonsRef.current[inx] = el)}
        id={`${toggleId}-item-${inx + 1}`}
        tabIndex={inx === 0 ? props.tabIndex : -1}
        width={props.width}
        key={inx}
        label={i.label}
        primary={i.primary}
        clicked={i.label === props.selectedLabel}
        onClick={handleChange}
        disable={i.disable}
        buttonProps={{ type: "button" }}
      />
    ));

  return (
    <FlexWrapper justifyContent="flex-start" alignItems="center" id={toggleId}>
      <Label>{props.groupLabel}</Label>
      <FlexWrapper justifyContent="flex-start" alignItems="center">
        {items}
      </FlexWrapper>
    </FlexWrapper>
  );
}
