import {
  ExpiryLookup,
  ExpiryReverseLookup,
  QuarterExpiryLookup,
  StripExpiryLookup,
  ExpiryConverter,
  QuarterReverseExpiryLookup,
  StripReverseExpiryLookup
} from "internal/dcc-types/dates";
import {
  OptionProductTypePeriodLookup,
  OptionTypeLookup,
  OptionTypeReverseLookup,
  ProductTypePeriodLookup,
  RegionReverseLookup
} from "internal/dcc-types/futures";
import {
  Future,
  Option,
  Month,
  ProductType,
  QuarterPeriodLookup,
  StripPeriodLookup,
  Region,
  RegionLookup,
  Period,
  Quarter,
  Strip,
  ValidFutureTickerCode,
  ValidCapTickerCode,
  ValidAsianOptionTickerCode,
  ValidStripOptionTickerCode,
  ValidFEXCapTickerCode
} from "internal/dcc-types";
import { Exchange, InstrumentType, OptionType, OtcProductType, SettlementPeriod } from "internal/dcc-types/enums";
import { Tradable, TradableLeg } from "sagas/types/config";
import { round } from "lodash";
import { DateService } from "./DateService";

export const TickerService = {
  pad(n: string, width: number, z: string) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  },
  getFutureName(f: Future) {
    switch (f.ProductType) {
      case ProductType.FUTURES_STRIPS:
      case ProductType.OPTIONS:
      case ProductType.CAP_STRIPS:
        return f.Strip;
      case ProductType.MONTH_FUTURES:
        return ExpiryConverter.toString(f.Expiry);
      case ProductType.QUARTER_FUTURES:
      case ProductType.CAP_QUARTERS:
      case ProductType.ASIANS:
        return f.Quarter;
      default:
        return "";
    }
  },
  getInstrumentName(i: InstrumentType) {
    switch (i) {
      case InstrumentType.ASIAN:
        return "Asian Options";
      case InstrumentType.CAP:
        return "Futures Cap";
      case InstrumentType.FUTURE:
        return "Futures";
      case InstrumentType.OPTION:
        return "Futures Option";
      default:
        return "";
    }
  },
  getProductName(product: ProductType) {
    switch (product) {
      case ProductType.FUTURES_STRIPS:
        return "Strip";
      case ProductType.MONTH_FUTURES:
        return "Monthly Futures";
      case ProductType.QUARTER_FUTURES:
        return "Quarterly Futures";
      case ProductType.CAP_QUARTERS:
      case ProductType.CAP_STRIPS:
        return "$300 Cap";
      case ProductType.ASIANS:
        return "Average Rate Options";
      case ProductType.OPTIONS:
        return "Futures Options";
      default:
        return "";
    }
  },
  validateCapCode(code: string) {
    if (!ValidCapTickerCode.test(code)) {
      return false;
    }
    return true;
  },
  validateFEXCapCode(code: string) {
    if (!ValidFEXCapTickerCode.test(code)) {
      return false;
    }
    return true;
  },
  validateQuarterlyPriceBreakdown(legs: Tradable[], price: number, volume: number) {
    const totalHours = legs.reduce((accumulator, { Hours }) => accumulator + (Hours ? Hours : 0), 0);

    var parent = totalHours * price * volume;

    var children = legs?.reduce((accumulator, { Hours, Volume, Price }) => accumulator + (Hours ? Hours : 0) * Volume * Price, 0) || 0;

    return round(parent, 2) === round(children, 2);
  },
  validateAsianQuarterOptionCode(code: string) {
    if (!ValidAsianOptionTickerCode.test(code)) {
      return false;
    }
    return true;
  },
  validateStripOptionCode(code: string) {
    if (!ValidStripOptionTickerCode.test(code)) {
      return false;
    }
    return true;
  },
  validateFutureCode(code: string) {
    if (!ValidFutureTickerCode.test(code)) {
      return false;
    }
    if (["B", "P"].includes(code[0]) && !["H", "M", "U", "Z"].includes(code[2])) {
      return false;
    }
    if (["H", "D"].includes(code[0]) && !["M", "Z"].includes(code[2])) {
      return false;
    }

    return true;
  },
  getOptionFromCode(code: string) {
    let option: Option = {
      Expiry: { Month: Month.Undefined, Year: 0 },
      Region: Region.Undefined,
      Ticker: "",
      ProductType: ProductType.Undefined,
      Period: Period.Undefined,
      Strike: null,
      OptionType: OptionType.Undefined
    };

    if (!this.validateAsianQuarterOptionCode(code) && !this.validateStripOptionCode(code)) {
      return option;
    }

    let pp = OptionProductTypePeriodLookup.get(code[0])!;
    option.Ticker = code;
    option.ProductType = pp[0];
    option.Period = pp[1];
    option.Region = RegionReverseLookup.get(code[1])!;
    option.Expiry.Month = ExpiryReverseLookup.get(code[2])!;
    option.Expiry.Year = ExpiryConverter.toLongYear(Number(code.slice(3, 7)));
    option.OptionType = OptionTypeReverseLookup.get(code[7]) || OptionType.Undefined;
    option.Strike = Number(Number(code.slice(8, 15)) / 100);

    if (option.ProductType === ProductType.OPTIONS) {
      option.Strip = StripReverseExpiryLookup.get(code[2]);

      const [start] = DateService.getPeriodDates(Quarter.Undefined, option.Strip || Strip.Undefined, option.Expiry.Year);
      const exp = DateService.getOptionExpiry(start as Date);
      option.ExpiryDate = exp;
    } else if (option.ProductType === ProductType.ASIANS) {
      option.Quarter = QuarterReverseExpiryLookup.get(code[2]);
    }

    return option;
  },
  getFutureFromCode(code: string, exchange?: Exchange | null) {
    let future: Future = {
      Expiry: { Month: Month.Undefined, Year: 0 },
      Region: Region.Undefined,
      Ticker: "",
      ProductType: ProductType.Undefined,
      Period: Period.Undefined
    };

    if (!this.validateFutureCode(code) && !this.validateCapCode(code) && !this.validateFEXCapCode(code)) {
      return future;
    }

    let pp = ProductTypePeriodLookup.get(code[0])!;
    future.Ticker = code;
    future.ProductType = pp[0];
    future.Period = pp[1];
    future.Region = RegionReverseLookup.get(code[1])!;
    future.Expiry.Month = ExpiryReverseLookup.get(code[2])!;
    future.Expiry.Year = ExpiryConverter.toLongYear(Number(code.slice(-2)));

    if (future.ProductType === ProductType.FUTURES_STRIPS || future.ProductType === ProductType.CAP_STRIPS) {
      future.Strip = StripReverseExpiryLookup.get(code[2]);
    } else if (future.ProductType === ProductType.QUARTER_FUTURES || future.ProductType === ProductType.CAP_QUARTERS) {
      future.Quarter = QuarterReverseExpiryLookup.get(code[2]);
    }

    if (exchange) {
      future.Exchange = exchange;
      if (exchange === Exchange.FEX && future.ProductType === ProductType.CAP_QUARTERS && code[0] === "G") {
        future.SettlementPeriod = SettlementPeriod.M30;
      }
      if (exchange === Exchange.FEX && future.ProductType === ProductType.CAP_STRIPS && code[0] === "R") {
        future.SettlementPeriod = SettlementPeriod.M30;
      }
      if (exchange === Exchange.FEX && future.ProductType === ProductType.CAP_QUARTERS && code[0] === "F") {
        future.SettlementPeriod = SettlementPeriod.M5;
      }
      if (exchange === Exchange.FEX && future.ProductType === ProductType.CAP_STRIPS && code[0] === "Q") {
        future.SettlementPeriod = SettlementPeriod.M5;
      }
    }

    return future;
  },
  getFutureUnderlyingFromOptionCode(option: string) {
    return `${option[0]}${option[1]}${option[2]}${option[5]}${option[6]}`;
  },
  getUnderlyingFromOTC(pt?: OtcProductType | string) {
    switch (pt) {
      case OtcProductType.CAPTION:
        return OtcProductType.CAP;
      case OtcProductType.FLOORTION:
        return OtcProductType.FLOOR;
      case OtcProductType.SWAPTION:
        return OtcProductType.SWAP;
      case OtcProductType.SWAPTIONCOLLAR:
        return OtcProductType.SWAP;
      default:
        return pt as string;
    }
  },
  getOptionCode(p: Option) {
    let code: string = "NA";
    let validAsian =
      p.ProductType === ProductType.ASIANS &&
      p.Quarter !== Quarter.Undefined &&
      p.OptionType !== OptionType.Undefined &&
      p.Strike !== null &&
      p.Region !== Region.Undefined &&
      p.Expiry.Month !== Month.Undefined &&
      p.Expiry.Year > 0;
    let validOption =
      p.ProductType === ProductType.OPTIONS &&
      p.Strip !== Strip.Undefined &&
      p.OptionType !== OptionType.Undefined &&
      p.Strike !== null &&
      p.Region !== Region.Undefined &&
      p.Expiry.Month !== Month.Undefined &&
      p.Expiry.Year > 0;

    if (!validAsian && !validOption) {
      return code;
    }

    if (p.ProductType === ProductType.ASIANS) {
      code = `B${RegionLookup.get(p.Region)}${QuarterExpiryLookup.get(p.Quarter!)}${ExpiryConverter.toLongYear(
        p.Expiry.Year
      )}${OptionTypeLookup.get(p.OptionType)}${this.pad(String(p.Strike! * 100), 7, "")}`;
    } else if (p.ProductType === ProductType.OPTIONS) {
      code = `H${RegionLookup.get(p.Region)}${StripExpiryLookup.get(p.Strip!)}${ExpiryConverter.toLongYear(
        p.Expiry.Year
      )}${OptionTypeLookup.get(p.OptionType)}${this.pad(String(p.Strike! * 100), 7, "")}`;
    }

    return code;
  },
  getFutureCode(p: Future) {
    let code: string = "NA";
    let validMonthly =
      p.ProductType === ProductType.MONTH_FUTURES &&
      p.Region !== Region.Undefined &&
      p.Expiry.Month !== Month.Undefined &&
      p.Expiry.Year > 0;

    let validQuarterly =
      p.ProductType === ProductType.QUARTER_FUTURES &&
      p.Period !== Period.Undefined &&
      p.Region !== Region.Undefined &&
      p.Quarter !== Quarter.Undefined &&
      p.Expiry.Year > 0;

    let validStrip =
      p.ProductType === ProductType.FUTURES_STRIPS &&
      p.Period !== Period.Undefined &&
      p.Region !== Region.Undefined &&
      p.Strip !== Strip.Undefined &&
      p.Expiry.Year > 0;

    let validQCap =
      p.ProductType === ProductType.CAP_QUARTERS &&
      p.Period === Period.Base &&
      p.Region !== Region.Undefined &&
      p.Quarter !== Quarter.Undefined &&
      p.Expiry.Year > 0;

    let validSCap =
      p.ProductType === ProductType.CAP_STRIPS &&
      p.Period === Period.Base &&
      p.Region !== Region.Undefined &&
      p.Strip !== Strip.Undefined &&
      p.Expiry.Year > 0;

    if (!validMonthly && !validQuarterly && !validStrip && !validQCap && !validSCap) {
      return code;
    }

    if (p.ProductType === ProductType.MONTH_FUTURES) {
      code = `E${RegionLookup.get(p.Region)}${ExpiryLookup.get(p.Expiry.Month)}${ExpiryConverter.toShortYear(p.Expiry.Year)}`;
    } else if (p.ProductType === ProductType.QUARTER_FUTURES) {
      code = `${QuarterPeriodLookup.get(p.Period)}${RegionLookup.get(p.Region)}${QuarterExpiryLookup.get(
        p.Quarter!
      )}${ExpiryConverter.toShortYear(p.Expiry.Year)}`;
    } else if (p.ProductType === ProductType.FUTURES_STRIPS) {
      code = `${StripPeriodLookup.get(p.Period)}${RegionLookup.get(p.Region)}${StripExpiryLookup.get(
        p.Strip!
      )}${ExpiryConverter.toShortYear(p.Expiry.Year)}`;
    } else if (p.ProductType === ProductType.CAP_QUARTERS) {
      const q = p.Exchange && p.Exchange === Exchange.FEX && p.SettlementPeriod && p.SettlementPeriod === SettlementPeriod.M5 ? "F" : "G";
      code = `${q}${RegionLookup.get(p.Region)}${QuarterExpiryLookup.get(p.Quarter!)}${ExpiryConverter.toShortYear(p.Expiry.Year)}`;
    } else if (p.ProductType === ProductType.CAP_STRIPS) {
      const q = p.Exchange && p.Exchange === Exchange.FEX && p.SettlementPeriod && p.SettlementPeriod === SettlementPeriod.M5 ? "Q" : "R";
      code = `${q}${RegionLookup.get(p.Region)}${StripExpiryLookup.get(p.Strip!)}${ExpiryConverter.toShortYear(p.Expiry.Year)}`;
    }

    return code;
  },
  orderQuarters(items: TradableLeg[]) {
    if (!items || items.length !== 4) return items;
    const march = items.find(i => ExpiryReverseLookup.get(i.Ticker[2]) === Month.MAR);
    const june = items.find(i => ExpiryReverseLookup.get(i.Ticker[2]) === Month.JUN);
    const sep = items.find(i => ExpiryReverseLookup.get(i.Ticker[2]) === Month.SEP);
    const dec = items.find(i => ExpiryReverseLookup.get(i.Ticker[2]) === Month.DEC);

    if (!march || !june || !sep! || !dec) return items;

    const years = items.map(i => i.Ticker.slice(-2));
    const calYear = years.every(i => i === years[0]);
    if (calYear) {
      return [march, june, sep, dec];
    } else {
      return [sep, dec, march, june];
    }
  }
};
