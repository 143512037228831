import { Side } from "internal/dcc-types/enums";
import { GSH } from "internal/dcc-types/gas";
import { Deal } from "internal/dcc-types/tradeStatus";

// REDUX STATE
export interface GASProductSelectorState {
  Side: Side;
  Product: GSH | null;
  ReviewProduct?: Deal | null;
  Price: number | null;
  Volume: number | null;
}

// ACTIONS
export const PRODUCT_SELECTED = "GAS_DEAL_SELECTOR/PRODUCT_SELECTED";
export const SIDE_SELECTED = "GAS_DEAL_SELECTOR/SIDE_SELECTED";
export const VOLUME_SELECTED = "GAS_DEAL_SELECTOR/VOLUME_SELECTED";
export const PRICE_SELECTED = "GAS_DEAL_SELECTOR/PRICE_SELECTED";
export const CLEAR_STATE = "GAS_DEAL_SELECTOR/CLEAR_STATE";

export const PRODUCT_REVIEW = "GAS_DEAL_SELECTOR/PRODUCT_REVIEW";

interface ProductSelectedAction {
  type: typeof PRODUCT_SELECTED;
  payload: GSH | null;
}

interface SideSelectedAction {
  type: typeof SIDE_SELECTED;
  payload: Side;
}

interface VolumeSelectedAction {
  type: typeof VOLUME_SELECTED;
  payload: number | null;
}

interface PriceSelectedAction {
  type: typeof PRICE_SELECTED;
  payload: number | null;
}

interface ClearStateAction {
  type: typeof CLEAR_STATE;
  payload: boolean;
}

interface ProductReviewAction {
  type: typeof PRODUCT_REVIEW;
  payload: Deal | null;
}

export type GASDealSelectorActionTypes =
  | ProductSelectedAction
  | SideSelectedAction
  | VolumeSelectedAction
  | PriceSelectedAction
  | ClearStateAction
  | ProductReviewAction;

// ACTION CREATORS

export function selectGASProduct(product: GSH | null): GASDealSelectorActionTypes {
  return {
    type: PRODUCT_SELECTED,
    payload: product
  };
}

export function selectGASSide(side: Side): GASDealSelectorActionTypes {
  return {
    type: SIDE_SELECTED,
    payload: side
  };
}

export function selectGASVolume(vol: number | null): GASDealSelectorActionTypes {
  return {
    type: VOLUME_SELECTED,
    payload: vol
  };
}

export function selectGASPrice(price: number | null): GASDealSelectorActionTypes {
  return {
    type: PRICE_SELECTED,
    payload: price
  };
}

export function clearGASState(p: boolean): GASDealSelectorActionTypes {
  return {
    type: CLEAR_STATE,
    payload: p
  };
}

export function reviewGASProduct(p: Deal | null): GASDealSelectorActionTypes {
  return {
    type: PRODUCT_REVIEW,
    payload: p
  };
}

// REDUCER
const initialState: GASProductSelectorState = {
  Side: Side.Undefined,
  Product: null,
  Volume: null,
  Price: null,
  ReviewProduct: null
};

export function gasDealSelectorReducer(state = initialState, action: GASDealSelectorActionTypes): GASProductSelectorState {
  switch (action.type) {
    case CLEAR_STATE: {
      return initialState;
    }
    case PRODUCT_SELECTED: {
      return {
        ...state,
        Product: action.payload
      };
    }
    case SIDE_SELECTED: {
      return {
        ...state,
        Side: action.payload
      };
    }
    case VOLUME_SELECTED: {
      return {
        ...state,
        Volume: action.payload
      };
    }
    case PRICE_SELECTED: {
      return {
        ...state,
        Price: action.payload
      };
    }
    case PRODUCT_REVIEW: {
      return {
        ...state,
        ReviewProduct: action.payload
      };
    }
    default:
      return state;
  }
}
