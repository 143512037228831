import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from "react-redux";
import { createSelector } from "reselect";
import type { RootState, AppDispatch } from "./configureStore";
import { Store } from "@reduxjs/toolkit";

type DispatchFunc = () => AppDispatch;

export const useAppStore: <T extends RootState>() => Store<T> = useStore;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const createAppSelector = createSelector.withTypes<RootState>();
