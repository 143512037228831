import { put, takeEvery, call } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { LoginService } from "internal/dcc-services/LoginService";
import {
  ObjectIApiResponse,
  DayTypeApiFactory,
  DayTypeCreateRequestModel,
  DayTypeCreateResponseModel,
  DayTypeCreateResponseModelIApiResponse,
  DayTypeGetSingularResponseModelIApiResponse,
  DayTypeGetSingularResponseModel,
  DayTypeDaysResponseModel,
  DayTypeDaysResponseModelIApiResponse,
  DayTypeGetResponseModel,
  DayTypeGetResponseModelIApiResponse,
  DayTypeUpdateResponseModelIApiResponse,
  DayTypeUpdateResponseModel,
  DayTypeDeleteRequestResponseModel,
  DayTypeDeleteRequestResponseModelIApiResponse,
  DayTypeUpdateRequestModel
} from "sagas";

import {
  CreateDayTypesFailed,
  CreateDayTypesSuccess,
  CREATE_DAY_TYPE_REQUEST_V2,
  DayTypesBaseAction,
  DeleteDayTypeFailed,
  DeleteDayTypeRequestFailed,
  DeleteDayTypeRequestSuccess,
  DeleteDayTypeSuccess,
  DELETE_DAY_TYPE_REQUEST_REQUEST_V2,
  DELETE_DAY_TYPE_REQUEST_V2,
  GetDaysFailed,
  GetDaysSuccess,
  GetDayTypeFailed,
  GetDayTypesFailed,
  GetDayTypesSuccess,
  GetDayTypeSuccess,
  GET_DAYS_REQUEST_V2,
  GET_DAY_TYPES_REQUEST_V2,
  GET_DAY_TYPE_REQUEST_V2,
  UpdateDayTypeFailed,
  UpdateDayTypeSuccess,
  UPDATE_DAY_TYPE_REQUEST_V2
} from "sagas/reducers/daytypes";

const dayTypeClient = DayTypeApiFactory();

function* createDayTypeWorker(action: DayTypesBaseAction<DayTypeCreateRequestModel>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<DayTypeCreateResponseModelIApiResponse> = yield call(dayTypeClient.apiV2DayTypesPost, action.payload);

    if (reply.data.Status === "Failed" && reply.data.Error && reply.data.Error?.length > 0) {
      for (let i = 0; i < reply.data.Error.length; i++) {
        yield put(
          CreateDayTypesFailed({
            Message: reply.data.Error[i]?.Message || "",
            Status: Number(reply.data.Error[i]?.Code),
            StatusText: "Failed"
          })
        );
      }
    }

    yield put(CreateDayTypesSuccess(reply?.data.Body as DayTypeCreateResponseModel));
  } catch (error) {
    const e = error as any;

    yield put(CreateDayTypesFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getDayTypeWorker(action: DayTypesBaseAction<string>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<DayTypeGetSingularResponseModelIApiResponse> = yield call(
      dayTypeClient.apiV2DayTypesDayTypeIdGet,
      action.payload || ""
    );

    yield put(GetDayTypeSuccess(reply?.data.Body as DayTypeGetSingularResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetDayTypeFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getDaysWorker(action: DayTypesBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<DayTypeDaysResponseModelIApiResponse> = yield call(dayTypeClient.apiV2DayTypesDaysGet, action.payload || "");

    yield put(GetDaysSuccess(reply?.data.Body as DayTypeDaysResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetDaysFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getDayTypesWorker(action: DayTypesBaseAction<string | null>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<DayTypeGetResponseModelIApiResponse> = yield call(dayTypeClient.apiV2DayTypesGet, action.payload || "");

    yield put(GetDayTypesSuccess(reply?.data.Body as DayTypeGetResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(GetDayTypesFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* updateDayTypeWorker(action: DayTypesBaseAction<DayTypeUpdateRequestModel>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<DayTypeUpdateResponseModelIApiResponse> = yield call(
      dayTypeClient.apiV2DayTypesDayTypeIdPut,
      action.payload.DayTypeIdentifier || "",
      action.payload
    );
    if (reply.data.Status === "Failed" && reply.data.Error && reply.data.Error?.length > 0) {
      for (let i = 0; i < reply.data.Error.length; i++) {
        yield put(
          UpdateDayTypeFailed({
            Message: reply.data.Error[i]?.Message || "",
            Status: Number(reply.data.Error[i]?.Code),
            StatusText: "Failed"
          })
        );
      }
    }
    yield put(UpdateDayTypeSuccess(reply?.data.Body as DayTypeUpdateResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(UpdateDayTypeFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* deleteDayTypeWorker(action: DayTypesBaseAction<{ dayTypeId: string | null; authKey: string | null }>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;
    axios.defaults.headers.common["EE-DELETE-AUTHORISATION-KEY"] = action.payload?.authKey || "";
    const reply: AxiosResponse<ObjectIApiResponse> = yield call(
      dayTypeClient.apiV2DayTypesDayTypeIdDelete,
      action.payload?.dayTypeId || ""
    );

    yield put(DeleteDayTypeRequestSuccess(null));
    yield put(DeleteDayTypeSuccess(reply?.data.Body as any));
  } catch (error) {
    const e = error as any;
    yield put(DeleteDayTypeFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  } finally {
    axios.defaults.headers.common["EE-DELETE-AUTHORISATION-KEY"] = null;
  }
}

function* deleteDayTypeRequestWorker(action: DayTypesBaseAction<string>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    const reply: AxiosResponse<DayTypeDeleteRequestResponseModelIApiResponse> = yield call(
      dayTypeClient.apiV2DayTypesDayTypeIdDeletePost,
      action.payload
    );

    yield put(DeleteDayTypeRequestSuccess(reply?.data.Body as DayTypeDeleteRequestResponseModel));
  } catch (error) {
    const e = error as any;
    yield put(DeleteDayTypeRequestFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

// watchers
export function* watchCreateDayType() {
  yield takeEvery(CREATE_DAY_TYPE_REQUEST_V2, createDayTypeWorker);
}
export function* watchGetDayTypes() {
  yield takeEvery(GET_DAY_TYPES_REQUEST_V2, getDayTypesWorker);
}
export function* watchGetDays() {
  yield takeEvery(GET_DAYS_REQUEST_V2, getDaysWorker);
}
export function* watchGetDayType() {
  yield takeEvery(GET_DAY_TYPE_REQUEST_V2, getDayTypeWorker);
}
export function* watchUpdateDayTypeRequest() {
  yield takeEvery(UPDATE_DAY_TYPE_REQUEST_V2, updateDayTypeWorker);
}
export function* watchDeleteDayType() {
  yield takeEvery(DELETE_DAY_TYPE_REQUEST_V2, deleteDayTypeWorker);
}
export function* watchDeleteDayTypeRequest() {
  yield takeEvery(DELETE_DAY_TYPE_REQUEST_REQUEST_V2, deleteDayTypeRequestWorker);
}
