import AppFeatures from "app/features/AppFeatures";
import {
  CreateEndpointRequestModel,
  CreateEndpointResponseModel,
  DeleteEndpointResponseModel,
  EnableEndpointResponseModel,
  GetEndpointResponseModel,
  ListEndpointResponseSummaryModel,
  UpdateEndpointRequestModel,
  UpdateEndpointResponseModel
} from "sagas-v2/api";
import { IApiError } from "sagas-v2/types";

export const ESCALATION_GET_REQUESTED = "INDEXES/ESCALATION_GET_REQUESTED";
export const ESCALATION_GET_SUCCEEDED = "INDEXES/ESCALATION_GET_SUCCEEDED";
export const ESCALATION_GET_FAILED = "INDEXES/ESCALATION_GET_FAILED";

export const ESCALATION_DELETE_REQUESTED = "INDEXES/ESCALATION_DELETE_REQUESTED";
export const ESCALATION_DELETE_SUCCEEDED = "INDEXES/ESCALATION_DELETE_SUCCEEDED";
export const ESCALATION_DELETE_FAILED = "INDEXES/ESCALATION_DELETE_FAILED";

export const ESCALATION_ENABLE_REQUESTED = "INDEXES/ESCALATION_ENABLE_REQUESTED";
export const ESCALATION_ENABLE_SUCCEEDED = "INDEXES/ESCALATION_ENABLE_SUCCEEDED";
export const ESCALATION_ENABLE_FAILED = "INDEXES/ESCALATION_ENABLE_FAILED";

export const ESCALATION_CREATE_REQUESTED = "INDEXES/ESCALATION_CREATE_REQUESTED";
export const ESCALATION_CREATE_SUCCEEDED = "INDEXES/ESCALATION_CREATE_SUCCEEDED";
export const ESCALATION_CREATE_FAILED = "INDEXES/ESCALATION_CREATE_FAILED";

export const ESCALATION_BY_ID_GET_REQUESTED = "INDEXES/ESCALATION_BY_ID_GET_REQUESTED";
export const ESCALATION_BY_ID_GET_SUCCEEDED = "INDEXES/ESCALATION_BY_ID_GET_SUCCEEDED";
export const ESCALATION_BY_ID_GET_FAILED = "INDEXES/ESCALATION_BY_ID_GET_FAILED";

export const ESCALATION_BY_ID_UPDATE_REQUESTED = "INDEXES/ESCALATION_BY_ID_UPDATE_REQUESTED";
export const ESCALATION_BY_ID_UPDATE_SUCCEEDED = "INDEXES/ESCALATION_BY_ID_UPDATE_SUCCEEDED";
export const ESCALATION_BY_ID_UPDATE_FAILED = "INDEXES/ESCALATION_BY_ID_UPDATE_FAILED";

export interface ConfigBaseAction<T> {
  type: string;
  payload?: T;
}

// action creators
export const EscalationGetRequest = (requestor: {
  includeDisabled?: boolean;
  includeInactive?: boolean;
}): ConfigBaseAction<{ includeDisabled?: boolean; includeInactive?: boolean }> => ({
  type: ESCALATION_GET_REQUESTED,
  payload: requestor
});

export const EscalationGetSuccess = (
  data: Array<ListEndpointResponseSummaryModel> | null | undefined
): ConfigBaseAction<Array<ListEndpointResponseSummaryModel> | null | undefined> => ({
  type: ESCALATION_GET_SUCCEEDED,
  payload: data
});

export const EscalationGetFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: ESCALATION_GET_FAILED,
  payload: message
});

export const EscalationDeleteRequest = (requestor: { escalationId: string }): ConfigBaseAction<{ escalationId: string }> => ({
  type: ESCALATION_DELETE_REQUESTED,
  payload: requestor
});

export const EscalationDeleteSuccess = (
  data: DeleteEndpointResponseModel | null | undefined
): ConfigBaseAction<DeleteEndpointResponseModel | null | undefined> => ({
  type: ESCALATION_DELETE_SUCCEEDED,
  payload: data
});

export const EscalationDeleteFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: ESCALATION_DELETE_FAILED,
  payload: message
});

export const EscalationEnableRequest = (requestor: {
  escalationId: string;
  enabled: boolean;
}): ConfigBaseAction<{ escalationId: string; enabled: boolean }> => ({
  type: ESCALATION_ENABLE_REQUESTED,
  payload: requestor
});

export const EscalationEnableSuccess = (data: EnableEndpointResponseModel): ConfigBaseAction<EnableEndpointResponseModel> => ({
  type: ESCALATION_ENABLE_SUCCEEDED,
  payload: data
});

export const EscalationEnableFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: ESCALATION_ENABLE_FAILED,
  payload: message
});

export const EscalationCreateRequest = (requestor: CreateEndpointRequestModel): ConfigBaseAction<CreateEndpointRequestModel> => ({
  type: ESCALATION_CREATE_REQUESTED,
  payload: requestor
});

export const EscalationCreateSuccess = (data: CreateEndpointResponseModel): ConfigBaseAction<CreateEndpointResponseModel> => ({
  type: ESCALATION_CREATE_SUCCEEDED,
  payload: data
});

export const EscalationCreateFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: ESCALATION_CREATE_FAILED,
  payload: message
});

export const EscalationGetByIdRequest = (requestor: string): ConfigBaseAction<string> => ({
  type: ESCALATION_BY_ID_GET_REQUESTED,
  payload: requestor
});

export const EscalationGetByIdSuccess = (data: GetEndpointResponseModel): ConfigBaseAction<GetEndpointResponseModel> => ({
  type: ESCALATION_BY_ID_GET_SUCCEEDED,
  payload: data
});

export const EscalationGetByIdFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: ESCALATION_BY_ID_GET_FAILED,
  payload: message
});

export const EscalationUpdateByIdRequest = (requestor: UpdateEndpointRequestModel): ConfigBaseAction<UpdateEndpointRequestModel> => ({
  type: ESCALATION_BY_ID_UPDATE_REQUESTED,
  payload: requestor
});

export const EscalationUpdateByIdSuccess = (data: UpdateEndpointResponseModel): ConfigBaseAction<UpdateEndpointResponseModel> => ({
  type: ESCALATION_BY_ID_UPDATE_SUCCEEDED,
  payload: data
});

export const EscalationUpdateByIdFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: ESCALATION_BY_ID_UPDATE_FAILED,
  payload: message
});

// reducer
export interface IndexesSagaState {
  EscalationIndices: {
    request?: { includeDisabled?: boolean; includeInactive?: boolean };
    reply?: Array<ListEndpointResponseSummaryModel> | null;
    error?: IApiError;
  };
  DeleteIndex: {
    request?: { escalationId: string };
    reply?: DeleteEndpointResponseModel;
    error?: IApiError;
  };
  EnableIndex: {
    request?: { escalationId: string; enabled: boolean };
    reply?: EnableEndpointResponseModel;
    error?: IApiError;
  };
  IndexCreated: {
    request?: CreateEndpointRequestModel;
    reply?: CreateEndpointResponseModel;
    error?: IApiError;
  };
  IndexGet: {
    request?: string;
    reply?: GetEndpointResponseModel;
    error?: IApiError;
  };
  IndexUpdate: {
    request?: UpdateEndpointRequestModel;
    reply?: UpdateEndpointResponseModel;
    error?: IApiError;
  };
}

const initState: IndexesSagaState = {
  EscalationIndices: {},
  DeleteIndex: {},
  EnableIndex: {},
  IndexCreated: {},
  IndexGet: {},
  IndexUpdate: {}
};

export const IndexesSagaReducer: (state: IndexesSagaState, action: ConfigBaseAction<any>) => Partial<IndexesSagaState> = (
  state = initState,
  action
) => {
  if (!AppFeatures.EscalationIndex) return {};

  switch (action.type) {
    case ESCALATION_GET_REQUESTED:
      return { ...state, EscalationIndices: { request: action.payload } };
    case ESCALATION_GET_SUCCEEDED:
      return { ...state, EscalationIndices: { request: state.EscalationIndices.request, reply: action.payload } };
    case ESCALATION_GET_FAILED:
      return { ...state, EscalationIndices: { request: state.EscalationIndices.request, error: action.payload } };

    case ESCALATION_DELETE_REQUESTED:
      return { ...state, DeleteIndex: { request: action.payload } };
    case ESCALATION_DELETE_SUCCEEDED:
      return { ...state, DeleteIndex: { request: state.DeleteIndex.request, reply: action.payload } };
    case ESCALATION_DELETE_FAILED:
      return { ...state, DeleteIndex: { request: state.DeleteIndex.request, error: action.payload } };

    case ESCALATION_ENABLE_REQUESTED:
      return { ...state, EnableIndex: { request: action.payload } };
    case ESCALATION_ENABLE_SUCCEEDED:
      return { ...state, EnableIndex: { request: state.EnableIndex.request, reply: action.payload } };
    case ESCALATION_ENABLE_FAILED:
      return { ...state, EnableIndex: { request: state.EnableIndex.request, error: action.payload } };

    case ESCALATION_CREATE_REQUESTED:
      return { ...state, IndexCreated: { request: action.payload } };
    case ESCALATION_CREATE_SUCCEEDED:
      return { ...state, IndexCreated: { request: state.IndexCreated.request, reply: action.payload } };
    case ESCALATION_CREATE_FAILED:
      return { ...state, IndexCreated: { request: state.IndexCreated.request, error: action.payload } };

    case ESCALATION_BY_ID_GET_REQUESTED:
      return { ...state, IndexGet: { request: action.payload } };
    case ESCALATION_BY_ID_GET_SUCCEEDED:
      return { ...state, IndexGet: { request: state.IndexGet.request, reply: action.payload } };
    case ESCALATION_BY_ID_GET_FAILED:
      return { ...state, IndexGet: { request: state.IndexGet.request, error: action.payload } };

    case ESCALATION_BY_ID_UPDATE_REQUESTED:
      return { ...state, IndexUpdate: { request: action.payload } };
    case ESCALATION_BY_ID_UPDATE_SUCCEEDED:
      return { ...state, IndexUpdate: { request: state.IndexUpdate.request, reply: action.payload } };
    case ESCALATION_BY_ID_UPDATE_FAILED:
      return { ...state, IndexUpdate: { request: state.IndexUpdate.request, error: action.payload } };
  }

  return state;
};
