import { KeyValue } from "sagas/types/config";

// REDUX STATE
export interface SelectedFees {
  Broker: KeyValue | null;
  BrokerReference?: string | null;
  Clearer: KeyValue | null;

  BrokerFee: { Price: number | null; UOM: KeyValue | null };
  ClearerFee: { Price: number | null; UOM: KeyValue | null };
  ExchangeFee: { Price: number | null; UOM: KeyValue | null };
  BlockExchangeFee?: { Price: number | null; UOM: KeyValue | null } | null;
  EFPFee?: { Price: number | null; UOM: KeyValue | null; LinkedEFT: string[] | null | undefined } | null;
}
export interface FeeSelectorState {
  SelectedFees: SelectedFees;
  Account: KeyValue | null;
}

// action
export const FEES_SELECTED = "FEE_SELECTOR/FEES_SELECTED";
export const ACCOUNT_SELECTED = "FEE_SELECTOR/ACCOUNT_SELECTED";
export const CLEARED = "FEE_SELECTOR/CLEARED";
interface FeesSelectedAction {
  type: typeof FEES_SELECTED;
  payload: SelectedFees;
}
interface AccountSelectedAction {
  type: typeof ACCOUNT_SELECTED;
  payload: KeyValue | null;
}
interface ClearedAction {
  type: typeof CLEARED;
  payload: boolean;
}

export type FeeSelectorActionTypes = FeesSelectedAction | AccountSelectedAction | ClearedAction;

// action creators
export function selectFees(fees: SelectedFees): FeeSelectorActionTypes {
  return {
    type: FEES_SELECTED,
    payload: fees
  };
}
export function selectAccount(account: KeyValue | null): FeeSelectorActionTypes {
  return {
    type: ACCOUNT_SELECTED,
    payload: account
  };
}
export function clearFees(p: boolean): FeeSelectorActionTypes {
  return {
    type: CLEARED,
    payload: p
  };
}

const initialState: FeeSelectorState = {
  SelectedFees: {
    Broker: null,
    Clearer: null,

    BrokerFee: { Price: null, UOM: null },
    ClearerFee: { Price: null, UOM: null },
    ExchangeFee: { Price: null, UOM: null }
  },
  Account: null
};

export function feeSelectorReducer(state = initialState, action: FeeSelectorActionTypes): FeeSelectorState {
  switch (action.type) {
    case CLEARED:
      return initialState;
    case FEES_SELECTED: {
      return {
        ...state,
        SelectedFees: action.payload
      };
    }
    case ACCOUNT_SELECTED: {
      return {
        ...state,
        Account: action.payload
      };
    }
    default:
      return state;
  }
}
