import { ContractPeriodModel, CustomProfileModel, PeriodModel, ProfileHoursRequestModel, ProfileModel, UpdateProfile } from "sagas/api";
import { RootState } from "store/configureStore";
import { DefaultFees, IApiError, InternalDetailsConfig, MarketSettings, Reply, Requestor, Tradable, TradableAction } from "../types/config";

export { rootSaga } from "../controllers/root";

export const CONFIG_CLEAR_ALL = "CONFIG/CLEAR_ALL";

export const INTERNAL_DETAILS_REQUESTED = "CONFIG/INTERNAL_DETAILS_REQUESTED";
export const INTERNAL_DETAILS_SUCCEEDED = "CONFIG/INTERNAL_DETAILS_SUCCEEDED";
export const INTERNAL_DETAILS_FAILED = "CONFIG/INTERNAL_DETAILS_FAILED";

export const INTERNAL_DETAILS_UPDATE_REQUESTED = "CONFIG/INTERNAL_DETAILS_UPDATE_REQUESTED";
export const INTERNAL_DETAILS_UPDATE_SUCCEEDED = "CONFIG/INTERNAL_DETAILS_UPDATE_SUCCEEDED";
export const INTERNAL_DETAILS_UPDATE_FAILED = "CONFIG/INTERNAL_DETAILS_UPDATE_FAILED";

export const INTERNAL_DETAILS_UPDATE_CLEARED = "CONFIG/INTERNAL_DETAILS_UPDATE_CLEARED";

export const DEFAULT_FEES_REQUESTED = "CONFIG/DEFAULT_FEES_REQUESTED";
export const DEFAULT_FEES_SUCCEEDED = "CONFIG/DEFAULT_FEES_SUCCEEDED";
export const DEFAULT_FEES_FAILED = "CONFIG/DEFAULT_FEES_FAILED";

export const DEFAULT_FEES_UPDATE_REQUESTED = "CONFIG/DEFAULT_FEES_UPDATE_REQUESTED";
export const DEFAULT_FEES_UPDATE_SUCCEEDED = "CONFIG/DEFAULT_FEES_UPDATE_SUCCEEDED";
export const DEFAULT_FEES_UPDATE_FAILED = "CONFIG/DEFAULT_FEES_UPDATE_FAILED";

export const DEFAULT_FEES_UPDATE_CLEARED = "CONFIG/DEFAULT_FEES_UPDATE_CLEARED";

export const UNDERLYING_QUARTERLY_REQUESTED = "CONFIG/UNDERLYING_QUARTERLY_REQUESTED";
export const UNDERLYING_QUARTERLY_SUCCEEDED = "CONFIG/UNDERLYING_QUARTERLY_SUCCEEDED";
export const UNDERLYING_QUARTERLY_FAILED = "CONFIG/UNDERLYING_QUARTERLY_FAILED";

export const UNDERLYING_QUARTERLY_UPDATE = "CONFIG/UNDERLYING_QUARTERLY_UPDATE";
export const UNDERLYING_QUARTERLY_CLEAR = "CONFIG/UNDERLYING_QUARTERLY_CLEAR";

export const EXPIRY_OPTIONS_UPDATE = "CONFIG/EXPIRY_OPTIONS_UPDATE";
export const EXPIRY_OPTIONS_CLEAR = "CONFIG/EXPIRY_OPTIONS_CLEAR";

export const PERIOD_HOURS_REQUESTED = "CONFIG/PERIOD_HOURS_REQUESTED";
export const PERIOD_HOURS_SUCCEEDED = "CONFIG/PERIOD_HOURS_SUCCEEDED";
export const PERIOD_HOURS_FAILED = "CONFIG/PERIOD_HOURS_FAILED";

export const MARKET_SETTINGS_REQUESTED = "CONFIG/MARKET_SETTINGS_REQUESTED";
export const MARKET_SETTINGS_SUCCEEDED = "CONFIG/MARKET_SETTINGS_SUCCEEDED";
export const MARKET_SETTINGS_FAILED = "CONFIG/MARKET_SETTINGS_FAILED";

export const MARKET_SETTINGS_UPDATE_REQUESTED = "CONFIG/MARKET_SETTINGS_UPDATE_REQUESTED";
export const MARKET_SETTINGS_UPDATE_SUCCEEDED = "CONFIG/MARKET_SETTINGS_UPDATE_SUCCEEDED";
export const MARKET_SETTINGS_UPDATE_FAILED = "CONFIG/MARKET_SETTINGS_UPDATE_FAILED";

export const MARKET_SETTINGS_UPDATE_CLEARED = "CONFIG/MARKET_SETTINGS_UPDATE_CLEARED";

export const PROFILE_GET_ALL_REQUEST = "CONFIG/PROFILE_GET_ALL_REQUEST";
export const PROFILE_GET_ALL_SUCCEEDED = "CONFIG/PROFILE_GET_ALL_SUCCEEDED";
export const PROFILE_GET_ALL_FAILED = "CONFIG/PROFILE_GET_ALL_FAILED";

export const PROFILE_UPDATE_REQUEST = "CONFIG/PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCEEDED = "CONFIG/PROFILE_UPDATE_SUCCEEDED";
export const PROFILE_UPDATE_FAILED = "CONFIG/PROFILE_UPDATE_FAILED";

export const PROFILE_UPDATE_DETAILS_REQUEST = "CONFIG/PROFILE_UPDATE_DETAILS_REQUEST";
export const PROFILE_UPDATE_DETAILS_SUCCEEDED = "CONFIG/PROFILE_UPDATE_DETAILS_SUCCEEDED";
export const PROFILE_UPDATE_DETAILS_FAILED = "CONFIG/PROFILE_UPDATE_DETAILS_FAILED";

export const PROFILE_UPDATE_PERIODS_REQUEST = "CONFIG/PROFILE_UPDATE_PERIODS_REQUEST";
export const PROFILE_UPDATE_PERIODS_SUCCEEDED = "CONFIG/PROFILE_UPDATE_PERIODS_SUCCEEDED";
export const PROFILE_UPDATE_PERIODS_FAILED = "CONFIG/PROFILE_UPDATE_PERIODS_FAILED";

export interface ConfigBaseAction<T> {
  type: string;
  payload?: T;
}

// action creators
export const ConfigClearAll = (i: boolean): ConfigBaseAction<boolean> => ({
  type: CONFIG_CLEAR_ALL,
  payload: i
});

export const InternalDetailsRequest = (requestor: Requestor): ConfigBaseAction<Requestor> => ({
  type: INTERNAL_DETAILS_REQUESTED,
  payload: requestor
});

export const InternalDetailsSuccess = (data: InternalDetailsConfig): ConfigBaseAction<InternalDetailsConfig> => ({
  type: INTERNAL_DETAILS_SUCCEEDED,
  payload: data
});

export const InternalDetailsFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: INTERNAL_DETAILS_FAILED,
  payload: message
});

export const InternalDetailsUpdateRequest = (data: InternalDetailsConfig): ConfigBaseAction<InternalDetailsConfig> => ({
  type: INTERNAL_DETAILS_UPDATE_REQUESTED,
  payload: data
});

export const InternalDetailsUpdateSuccess = (data: string): ConfigBaseAction<string> => ({
  type: INTERNAL_DETAILS_UPDATE_SUCCEEDED,
  payload: data
});

export const InternalDetailsUpdateFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: INTERNAL_DETAILS_UPDATE_FAILED,
  payload: message
});

export const InternalDetailsUpdateClear = (message: boolean): ConfigBaseAction<boolean> => ({
  type: INTERNAL_DETAILS_UPDATE_CLEARED,
  payload: message
});

export const DefaultFeesRequest = (requestor: Requestor): ConfigBaseAction<Requestor> => ({
  type: DEFAULT_FEES_REQUESTED,
  payload: requestor
});

export const DefaultFeesSuccess = (data: DefaultFees): ConfigBaseAction<DefaultFees> => ({
  type: DEFAULT_FEES_SUCCEEDED,
  payload: data
});

export const DefaultFeesFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: DEFAULT_FEES_FAILED,
  payload: message
});

export const DefaultFeesUpdateRequest = (data: DefaultFees): ConfigBaseAction<DefaultFees> => ({
  type: DEFAULT_FEES_UPDATE_REQUESTED,
  payload: data
});

export const DefaultFeesUpdateSuccess = (data: string): ConfigBaseAction<string> => ({
  type: DEFAULT_FEES_UPDATE_SUCCEEDED,
  payload: data
});

export const DefaultFeesUpdateFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: DEFAULT_FEES_UPDATE_FAILED,
  payload: message
});

export const DefaultFeesUpdateClear = (message: boolean): ConfigBaseAction<boolean> => ({
  type: DEFAULT_FEES_UPDATE_CLEARED,
  payload: message
});

export const UnderlyingQuarterlyRequest = (strip: Tradable): ConfigBaseAction<Tradable> => ({
  type: UNDERLYING_QUARTERLY_REQUESTED,
  payload: strip
});

export const UnderlyingQuarterlySucceeded = (message: Tradable[]): ConfigBaseAction<Tradable[]> => ({
  type: UNDERLYING_QUARTERLY_SUCCEEDED,
  payload: message
});

export const UnderlyingQuarterlyFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: UNDERLYING_QUARTERLY_FAILED,
  payload: message
});

export const UnderlyingQuarterlyUpdate = (update: Tradable): ConfigBaseAction<Tradable> => ({
  type: UNDERLYING_QUARTERLY_UPDATE,
  payload: update
});

export const UnderlyingQuarterlyClear = (s: boolean): ConfigBaseAction<boolean> => ({
  type: UNDERLYING_QUARTERLY_CLEAR,
  payload: s
});

export const ExpiryOptionUpdate = (update: TradableAction): ConfigBaseAction<TradableAction> => ({
  type: EXPIRY_OPTIONS_UPDATE,
  payload: update
});

export const ExpiryOptionClear = (s: boolean): ConfigBaseAction<boolean> => ({
  type: EXPIRY_OPTIONS_CLEAR,
  payload: s
});

export const periodHoursRequest = (period: ProfileHoursRequestModel): ConfigBaseAction<ProfileHoursRequestModel> => ({
  type: PERIOD_HOURS_REQUESTED,
  payload: period
});

export const periodHoursSucceeded = (period: ContractPeriodModel): ConfigBaseAction<ContractPeriodModel> => ({
  type: PERIOD_HOURS_SUCCEEDED,
  payload: period
});

export const periodHoursFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: PERIOD_HOURS_FAILED,
  payload: message
});

export const MarketSettingsRequest = (requestor: Requestor): ConfigBaseAction<Requestor> => ({
  type: MARKET_SETTINGS_REQUESTED,
  payload: requestor
});

export const MarketSettingsSuccess = (data: MarketSettings): ConfigBaseAction<MarketSettings> => ({
  type: MARKET_SETTINGS_SUCCEEDED,
  payload: data
});

export const MarketSettingsFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: MARKET_SETTINGS_FAILED,
  payload: message
});

export const MarketSettingsUpdateRequest = (data: MarketSettings): ConfigBaseAction<MarketSettings> => ({
  type: MARKET_SETTINGS_UPDATE_REQUESTED,
  payload: data
});

export const MarketSettingsUpdateSuccess = (data: string): ConfigBaseAction<string> => ({
  type: MARKET_SETTINGS_UPDATE_SUCCEEDED,
  payload: data
});

export const MarketSettingsUpdateFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: MARKET_SETTINGS_UPDATE_FAILED,
  payload: message
});

export const MarketSettingsUpdateClear = (message: boolean): ConfigBaseAction<boolean> => ({
  type: MARKET_SETTINGS_UPDATE_CLEARED,
  payload: message
});

export const GetAllProfilesRequest = (data: string): ConfigBaseAction<string> => ({
  type: PROFILE_GET_ALL_REQUEST,
  payload: data
});

export const GetAllProfilesSuccess = (data: ProfileModel): ConfigBaseAction<ProfileModel> => ({
  type: PROFILE_GET_ALL_SUCCEEDED,
  payload: data
});

export const GetAllProfilesFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: PROFILE_GET_ALL_FAILED,
  payload: message
});

export const UpdateProfileRequest = (data: UpdateProfile): ConfigBaseAction<UpdateProfile> => ({
  type: PROFILE_UPDATE_REQUEST,
  payload: data
});

export const UpdateProfileSuccess = (data: string): ConfigBaseAction<string> => ({
  type: PROFILE_UPDATE_SUCCEEDED,
  payload: data
});

export const UpdateProfileFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: PROFILE_UPDATE_FAILED,
  payload: message
});

export const UpdateProfileDetailsRequest = (data: CustomProfileModel): ConfigBaseAction<CustomProfileModel> => ({
  type: PROFILE_UPDATE_DETAILS_REQUEST,
  payload: data
});

export const UpdateProfileDetailsSuccess = (data: string): ConfigBaseAction<string> => ({
  type: PROFILE_UPDATE_DETAILS_SUCCEEDED,
  payload: data
});

export const UpdateProfileDetailsFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: PROFILE_UPDATE_DETAILS_FAILED,
  payload: message
});

export const UpdatePeriodsRequest = (data: PeriodModel[]): ConfigBaseAction<PeriodModel[]> => ({
  type: PROFILE_UPDATE_PERIODS_REQUEST,
  payload: data
});

export const UpdatePeriodsSuccess = (data: string): ConfigBaseAction<string> => ({
  type: PROFILE_UPDATE_PERIODS_SUCCEEDED,
  payload: data
});

export const UpdatePeriodsFailed = (message: IApiError): ConfigBaseAction<IApiError> => ({
  type: PROFILE_UPDATE_PERIODS_FAILED,
  payload: message
});

// reducer
export interface ConfigSagaState {
  Profiles: {
    request?: string;
    reply?: ProfileModel;
    error?: IApiError;
  };
  UpdateProfiles: {
    request?: UpdateProfile;
    reply?: string;
    error?: IApiError;
  };
  UpdateProfileDetails: {
    request?: CustomProfileModel;
    reply?: string;
    error?: IApiError;
  };
  UpdatePeriods: {
    request?: PeriodModel[];
    reply?: string;
    error?: IApiError;
  };
  MarketSettings: {
    request?: Requestor;
    reply?: MarketSettings;
    error?: IApiError;
  };
  MarketSettingsUpdate: {
    request?: MarketSettings;
    reply?: string;
    error?: IApiError;
  };
  InternalDetails: {
    request?: Requestor;
    reply: InternalDetailsConfig;
    error?: IApiError;
  };
  InternalDetailsUpdate: {
    request?: InternalDetailsConfig;
    reply?: string;
    error?: IApiError;
  };
  DefaultFees: {
    request?: Requestor;
    reply?: DefaultFees;
    error?: IApiError;
  };
  DefaultFeesUpdate: {
    request?: DefaultFees;
    reply?: Reply;
    error?: IApiError;
  };
  UnderlyingQuarterlies: {
    request?: Tradable;
    reply?: Tradable[];
    error?: IApiError;
  };
  ExpiryOptions?: TradableAction | null;
  PeriodHours: {
    request?: ContractPeriodModel;
    reply?: ContractPeriodModel;
    error?: IApiError;
  };
}

const initState: ConfigSagaState = {
  MarketSettings: {},
  MarketSettingsUpdate: {},
  InternalDetails: {
    reply: {
      Books: [],
      Traders: [],
      Counterparties: [],
      FirmnessDependencies: [],
      EnvironExclusions: [],
      EnvironCreationYears: [],
      EnvironProducts: [],
      LoggedOnUser: null,
      LoggedOnUserEmail: null
    } as InternalDetailsConfig
  },
  InternalDetailsUpdate: {},
  DefaultFees: { reply: { ASX: null, FEX: null, AEMO: null, Brokers: [], Clearers: [] } },
  DefaultFeesUpdate: {},
  UnderlyingQuarterlies: {},
  PeriodHours: {},
  Profiles: {},
  UpdateProfiles: {},
  UpdateProfileDetails: {},
  UpdatePeriods: {}
};

export const ConfigReducer = (state: ConfigSagaState = initState, action: ConfigBaseAction<any>) => {
  switch (action.type) {
    case CONFIG_CLEAR_ALL:
      return initState;
    case PROFILE_GET_ALL_REQUEST:
      return { ...state, Profiles: { request: action.payload, reply: undefined } };
    case PROFILE_GET_ALL_SUCCEEDED:
      return { ...state, Profiles: { request: state.Profiles.request, reply: action.payload as ProfileModel } };
    case PROFILE_GET_ALL_FAILED:
      return { ...state, Profiles: { request: state.Profiles.request, reply: undefined, error: action.payload } };

    case PROFILE_UPDATE_REQUEST:
      return { ...state, UpdateProfiles: { request: action.payload } };
    case PROFILE_UPDATE_SUCCEEDED:
      return { ...state, UpdateProfiles: { request: state.UpdateProfiles.request, reply: action.payload } };
    case PROFILE_UPDATE_FAILED:
      return { ...state, UpdateProfiles: { request: state.UpdateProfiles.request, error: action.payload } };

    case PROFILE_UPDATE_DETAILS_REQUEST:
      return { ...state, UpdateProfileDetails: { request: action.payload } };
    case PROFILE_UPDATE_DETAILS_SUCCEEDED:
      return { ...state, UpdateProfileDetails: { request: state.UpdateProfileDetails.request, reply: action.payload } };
    case PROFILE_UPDATE_DETAILS_FAILED:
      return { ...state, UpdateProfileDetails: { request: state.UpdateProfileDetails.request, error: action.payload } };

    case PROFILE_UPDATE_PERIODS_REQUEST:
      return { ...state, UpdatePeriods: { request: action.payload } };
    case PROFILE_UPDATE_PERIODS_SUCCEEDED:
      return { ...state, UpdatePeriods: { request: state.UpdatePeriods.request, reply: action.payload } };
    case PROFILE_UPDATE_PERIODS_FAILED:
      return { ...state, UpdatePeriods: { request: state.UpdatePeriods.request, error: action.payload } };

    case INTERNAL_DETAILS_REQUESTED:
      return { ...state, InternalDetails: { request: action.payload, reply: { ...initState.InternalDetails.reply } } };
    case INTERNAL_DETAILS_SUCCEEDED:
      return {
        ...state,
        InternalDetails: { request: state.InternalDetails.request, reply: action.payload as InternalDetailsConfig }
      };
    case INTERNAL_DETAILS_FAILED:
      return {
        ...state,
        InternalDetails: {
          request: state.InternalDetails.request,
          reply: { ...initState.InternalDetails.reply },
          error: action.payload
        }
      };

    case INTERNAL_DETAILS_UPDATE_REQUESTED:
      return { ...state, InternalDetailsUpdate: { request: action.payload } };
    case INTERNAL_DETAILS_UPDATE_SUCCEEDED:
      return {
        ...state,
        InternalDetailsUpdate: { request: state.InternalDetailsUpdate.request, reply: action.payload }
      };
    case INTERNAL_DETAILS_UPDATE_FAILED:
      return {
        ...state,
        InternalDetailsUpdate: { request: state.InternalDetailsUpdate.request, error: action.payload }
      };
    case INTERNAL_DETAILS_UPDATE_CLEARED:
      return { ...state, InternalDetailsUpdate: {} };

    case DEFAULT_FEES_REQUESTED:
      if (state.DefaultFees.request?.RequestorId === action.payload?.RequestorId) return state;
      return {
        ...state,
        DefaultFees: { request: action.payload, reply: action.payload?.Clear === true ? undefined : state.DefaultFees?.reply || undefined }
      };
    case DEFAULT_FEES_SUCCEEDED:
      return { ...state, DefaultFees: { request: state.DefaultFees.request, reply: action.payload as DefaultFees } };
    case DEFAULT_FEES_FAILED:
      return {
        ...state,
        DefaultFees: {
          request: state.DefaultFees.request,
          reply: state.DefaultFees?.reply || undefined,
          error: action.payload
        }
      };

    case DEFAULT_FEES_UPDATE_REQUESTED:
      return { ...state, DefaultFeesUpdate: { request: action.payload } };
    case DEFAULT_FEES_UPDATE_SUCCEEDED:
      return { ...state, DefaultFeesUpdate: { request: state.DefaultFeesUpdate.request, reply: action.payload } };
    case DEFAULT_FEES_UPDATE_FAILED:
      return { ...state, DefaultFeesUpdate: { request: state.DefaultFeesUpdate.request, error: action.payload } };
    case DEFAULT_FEES_UPDATE_CLEARED:
      return { ...state, DefaultFeesUpdate: {} };

    case MARKET_SETTINGS_REQUESTED:
      const requestId = (action.payload as Requestor)?.RequestorId || 0;

      if (requestId === state.MarketSettings.request?.RequestorId) return state;

      return { ...state, MarketSettings: { request: action.payload, reply: state.MarketSettings?.reply, error: undefined } };
    case MARKET_SETTINGS_SUCCEEDED:
      return {
        ...state,
        MarketSettings: { request: state.MarketSettings.request, reply: action.payload as MarketSettings }
      };
    case MARKET_SETTINGS_FAILED:
      return {
        ...state,
        MarketSettings: { request: state.MarketSettings.request, reply: undefined, error: action.payload }
      };

    case MARKET_SETTINGS_UPDATE_REQUESTED:
      return { ...state, MarketSettingsUpdate: { request: action.payload } };
    case MARKET_SETTINGS_UPDATE_SUCCEEDED:
      return { ...state, MarketSettingsUpdate: { request: state.MarketSettingsUpdate.request, reply: action.payload } };
    case MARKET_SETTINGS_UPDATE_FAILED:
      return { ...state, MarketSettingsUpdate: { request: state.MarketSettingsUpdate.request, error: action.payload } };
    case MARKET_SETTINGS_UPDATE_CLEARED:
      return { ...state, MarketSettingsUpdate: {} };

    case PERIOD_HOURS_REQUESTED:
      return { ...state, PeriodHours: { request: action.payload } };
    case PERIOD_HOURS_SUCCEEDED:
      return { ...state, PeriodHours: { request: state.PeriodHours.request, reply: action.payload } };
    case PERIOD_HOURS_FAILED:
      return { ...state, PeriodHours: { request: state.PeriodHours.request, error: action.payload } };

    case UNDERLYING_QUARTERLY_REQUESTED:
      return { ...state, UnderlyingQuarterlies: { request: action.payload } };
    case UNDERLYING_QUARTERLY_SUCCEEDED:
      return {
        ...state,
        UnderlyingQuarterlies: { request: state.UnderlyingQuarterlies.request, reply: action.payload }
      };
    case UNDERLYING_QUARTERLY_FAILED:
      return {
        ...state,
        UnderlyingQuarterlies: { request: state.UnderlyingQuarterlies.request, error: action.payload }
      };
    case UNDERLYING_QUARTERLY_CLEAR:
      return { ...state, UnderlyingQuarterlies: {} };
    case UNDERLYING_QUARTERLY_UPDATE:
      return {
        ...state,
        UnderlyingQuarterlies: {
          ...state.UnderlyingQuarterlies,
          reply: state.UnderlyingQuarterlies.reply?.map(x => {
            if (x.Ticker !== action.payload.Ticker) return x;
            return Object.assign({}, x, { Price: action.payload.Price });
          })
        }
      };
    case EXPIRY_OPTIONS_UPDATE:
      return { ...state, ExpiryOptions: action.payload };
    case EXPIRY_OPTIONS_CLEAR:
      return { ...state, ExpiryOptions: undefined };
  }
  return state;
};

// selector
export const configSagaInternalDetailsError = (state: RootState) => state.ConfigSagaState.InternalDetails.error;
export const configSagaInternalDetailsReply = (state: RootState) => state.ConfigSagaState.InternalDetails.reply;
export const configSagaUnderlyingQuartersError = (state: RootState) => state.ConfigSagaState.UnderlyingQuarterlies.error;
export const configSagaUnderlyingQuartersReply = (state: RootState) => state.ConfigSagaState.UnderlyingQuarterlies.reply;

export const configSagaDefaultFeesError = (state: RootState) => state.ConfigSagaState.DefaultFees.error;
