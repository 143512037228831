import { AllowedGroups, AuthenticationApiFactory, PermissionModel, RoleModel, UserModel } from "sagas/api";
import { IApiResponse } from "sagas/types/config";
import { jwtDecode, JwtPayload } from "jwt-decode";
import axios from "axios";
import { BASE_PATH } from "saga-config";
import dateTimeHelper from "helpers/dateTimeHelper";

export enum DefaultRoles {
  FrontOffice = "FrontOffice",
  MiddleOffice = "MiddleOffice",
  BackOffice = "BackOffice",
  AppAdmin = "AppAdmin",
  UserAdmin = "UserAdmin"
}

type TokenPayload = JwtPayload & {
  permission?: string[];
  ["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]?: string;
};

const TokenStorageKey = "EnergyEdgeToken";
const RefreshTokenStorageKey = "EnergyEdgeRefreshToken";

export enum permissions {
  CreateASXExchangeDeals = "api.deals.electricity.create.asx",
  CreateFEXExchangeDeals = "api.deals.electricity.create.fex",
  CreateOTCElectricityDeals = "api.deals.electricity.create.otc",
  CreateEnvironmentalDeals = "api.deals.environ.create.otc",
  CreateGSHDeals = "api.deals.gas.create.gsh",
  ViewElectricityDeals = "api.deals.electricity.view",
  ManageDealCounterparty = "api.deals.edit.counterpartyref",
  ManageDealBroker = "api.deals.edit.brokerref",
  ViewEnvironmentalDeals = "api.deals.environ.view.otc",
  ViewGSHDeals = "api.deals.gas.view.gsh",
  ManageCustomProfiles = "api.config.profilesettings.update",
  ManageEscalationIndex = "api.config.profilesettings.update",
  ManageProfileDayTypes = "api.config.profiledaytypessettings.update",
  ManageMarketSettings = "api.config.marketsettings.update",
  ManageInternalSettings = "api.config.internalsettings.update",
  ManageEnvironSettings = "api.config.environsettings.update",
  ManageBrokerSettings = "api.config.brokersettings.update",
  ManageClearerSettings = "api.config.clearersettings.update",
  ManageExchangeSettings = "api.config.exchangesettings.update",
  ManageCounterpartySettings = "api.config.counterpartysettings.update",
  Manageroleaccess = "api.config.rolesettings.update",
  Manageuseraccess = "api.config.usersettings.update",
  ViewCustomProfiles = "api.config.profilesettings.view",
  ViewEscalationIndex = "api.config.profilesettings.view",
  ViewProfileDayTypes = "api.config.profiledaytypessettings.view",
  ViewMarketSettings = "api.config.marketsettings.view",
  ViewInternalSettings = "api.config.internalsettings.view",
  ViewEnvironSettings = "api.config.environsettings.view",
  ViewBrokerSettings = "api.config.brokersettings.view",
  ViewClearerSettings = "api.config.clearersettings.view",
  ViewExchangeSettings = "api.config.exchangesettings.view",
  ViewCounterpartySettings = "api.config.counterpartysettings.view",
  Viewroleaccess = "api.config.rolesettings.view",
  Viewuseraccess = "api.config.usersettings.view",
  RunReports = "api.reports.run"
}

interface ParsedTokenSummary {
  token?: TokenPayload;
  expiry: number;
  valid: (buffer?: number) => boolean;
}

const refreshTimeBuffer = dateTimeHelper.MILLISECONDS_PER_MINUTE * 2;

const parseTokenValue = (token?: string): ParsedTokenSummary | undefined => {
  let axiosHeaderValue = undefined;

  try {
    const jwtToken = !!token ? jwtDecode<TokenPayload>(token) : undefined;
    if (jwtToken == null) return undefined;

    const expiryTime = (jwtToken.exp || 0) * 1000;
    if (expiryTime > Date.now()) {
      axiosHeaderValue = `Bearer ${token}`;
    }

    const parsedToken: ParsedTokenSummary = {
      token: jwtToken,
      expiry: expiryTime,
      valid: buffer => expiryTime - (buffer || 0) > Date.now()
    };

    if (!parsedToken.valid()) refreshAccessToken();

    return parsedToken;
  } catch {
    return undefined;
  } finally {
    axios.defaults.headers.common["Authorization"] = axiosHeaderValue;
  }
};

const requestTokens: (refreshTokenValue?: string) => Promise<void> = async (refreshTokenValue?: string) => {
  return navigator.locks.request("loginService.refresh", async () => {
    try {
      const tokenValue = refreshTokenValue || localStorage.getItem(RefreshTokenStorageKey) || "";
      if (tokenValue.length < 1) return;

      const response = await LoginService.client.apiAuthRefreshPost(tokenValue);

      const reply = response?.data as unknown as IApiResponse;
      if (reply != null && reply.Status === "Success") {
        setAndNotifyLocalStorage(TokenStorageKey, reply.Body.token);
        setAndNotifyLocalStorage(RefreshTokenStorageKey, reply.Body.refresh);
      }
    } catch (error) {
      console.log(error);
    }
  });
};

const queueRefresh = (force: boolean) => {
  if (LoginService._refreshTimeout && !force) return;

  const refreshMs = Math.max(5000, LoginService._parsedToken.expiry - Date.now() - refreshTimeBuffer);

  window.clearTimeout(LoginService._refreshTimeout);
  LoginService._refreshTimeout = window.setTimeout(refreshAccessToken, refreshMs);
};

const refreshAccessToken: () => Promise<void> = async () => {
  if (LoginService.isAuthenticated() && LoginService._parsedToken?.valid(refreshTimeBuffer) === true) {
    queueRefresh(false);
    return;
  }

  const refreshTokenValue = localStorage.getItem(RefreshTokenStorageKey) || "";
  setAndNotifyLocalStorage(RefreshTokenStorageKey, undefined);

  if (!!refreshTokenValue) {
    await requestTokens(refreshTokenValue);

    if (!LoginService.isAuthenticated()) return;

    queueRefresh(true);
  }
};
const readToken = () => {
  LoginService._parsedToken = parseTokenValue(localStorage.getItem(TokenStorageKey) || "") || {
    token: undefined,
    expiry: 0,
    valid: () => false
  };

  refreshAccessToken();
};
const storageEventHandler = (ev: StorageEvent) => {
  if (ev.key === TokenStorageKey) {
    readToken();
  }
};

window.addEventListener("storage", storageEventHandler);
window.addEventListener(
  "beforeunload",
  () => {
    window.removeEventListener("storage", storageEventHandler);
  },
  { once: true }
);

export const LoginService = {
  _refreshTimeout: 0,
  _parsedToken: {
    expiry: 0,
    valid: () => false
  } as ParsedTokenSummary,
  initialized: false,
  _init: () => {
    if (LoginService.initialized) return;

    LoginService.initialized = true;
    readToken();
  },
  token() {
    return this.isAuthenticated() ? localStorage.getItem(TokenStorageKey) : "";
  },
  HasPermission(perm: permissions) {
    return this.isAuthenticated() ? (this._parsedToken?.token?.permission || []).some(x => x === perm) : false;
  },
  IsTrader() {
    const permissionSet: string[] = [
      permissions.CreateASXExchangeDeals,
      permissions.CreateASXExchangeDeals,
      permissions.CreateASXExchangeDeals
    ];
    return this.isAuthenticated() ? (this._parsedToken?.token?.permission || []).some(x => permissionSet.includes(x)) : false;
  },
  IsReporter() {
    const user = this._parsedToken;
    if (user?.valid() === true) {
      const p: string[] = user?.token?.permission || [];
      return p.includes(permissions.RunReports);
    } else {
      return false;
    }
  },
  IsAdmin() {
    if (this.isAuthenticated()) {
      const p: string[] = this._parsedToken?.token?.permission || [];

      return (
        p.includes(permissions.ManageCustomProfiles) ||
        p.includes(permissions.ManageEscalationIndex) ||
        p.includes(permissions.ManageProfileDayTypes) ||
        p.includes(permissions.ManageMarketSettings) ||
        p.includes(permissions.ManageInternalSettings) ||
        p.includes(permissions.ManageEnvironSettings) ||
        p.includes(permissions.ManageBrokerSettings) ||
        p.includes(permissions.ManageClearerSettings) ||
        p.includes(permissions.ManageExchangeSettings) ||
        p.includes(permissions.ManageCounterpartySettings) ||
        p.includes(permissions.Manageuseraccess) ||
        p.includes(permissions.Manageroleaccess) ||
        p.includes(permissions.ViewCustomProfiles) ||
        p.includes(permissions.ViewEscalationIndex) ||
        p.includes(permissions.ViewProfileDayTypes) ||
        p.includes(permissions.ViewMarketSettings) ||
        p.includes(permissions.ViewInternalSettings) ||
        p.includes(permissions.ViewEnvironSettings) ||
        p.includes(permissions.ViewBrokerSettings) ||
        p.includes(permissions.ViewClearerSettings) ||
        p.includes(permissions.ViewExchangeSettings) ||
        p.includes(permissions.ViewCounterpartySettings) ||
        p.includes(permissions.Viewuseraccess) ||
        p.includes(permissions.Viewroleaccess)
      );
    } else {
      return false;
    }
  },
  userIdentifier(): string {
    if (this._parsedToken?.valid() !== true) return "";

    return this._parsedToken?.token
      ? this._parsedToken.token["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] || ""
      : "";
  },
  client: AuthenticationApiFactory(),
  isAuthenticated(url?: string) {
    if (!!url && !LoginService.isAuthenticated()) {
      refreshAccessToken().then(x => {
        if (LoginService.isAuthenticated()) {
          document.location.assign(url || "/");
        }
      });
    }
    return this._parsedToken?.valid() === true;
  },
  autoRefreshLogin: async () => {
    const authenticated = LoginService._parsedToken?.valid() === true;
    if (!authenticated) {
      await requestTokens();
    }

    return LoginService._parsedToken?.valid() === true;
  },
  linkUser(onSuccess: any, user: string, pass: string, onError: (s: string[]) => void) {
    this.client.apiAuthLinkuserPost({ Email: user, Password: pass }).then(
      response => {
        const reply = response.data as unknown as IApiResponse;

        if (reply.Status === "Success" && !reply.Error) {
          onSuccess();
        } else {
          onError(reply.Error.map((x: any) => x.Message));
        }
      },
      error => {
        console.log(error);
        onError(["Login Failed"]);
      }
    );
  },
  authenticate(onSuccess: any, user: string, pass: string, onError: (s: string[]) => void) {
    this.client.apiAuthLoginPost({ Email: user, Password: pass }).then(
      response => {
        const reply = response.data as unknown as IApiResponse;

        if (reply.Status === "Success" && !reply.Error) {
          setAndNotifyLocalStorage(TokenStorageKey, reply.Body.token);
          setAndNotifyLocalStorage(RefreshTokenStorageKey, reply.Body.refresh);

          onSuccess();
        } else {
          setAndNotifyLocalStorage(TokenStorageKey);
          setAndNotifyLocalStorage(RefreshTokenStorageKey);
          onError(reply.Error.map((x: any) => x.Message));
        }
      },
      error => {
        console.log(error);
        setAndNotifyLocalStorage(TokenStorageKey);
        setAndNotifyLocalStorage(RefreshTokenStorageKey);
        onError(["Login Failed"]);
      }
    );
  },
  authenticateWithProvider(onSuccess: any, provider: string, token: string, onError: (s: string[]) => void) {
    // this needs to move into the apiAuth client
    const path = `${BASE_PATH}/api/v1/v2/auth/${provider}/login/${token}`;

    axios
      .create()
      .post(path)
      .then(
        response => {
          const reply = response.data as unknown as IApiResponse;

          if (reply.Status === "Success" && !reply.Error) {
            setAndNotifyLocalStorage(TokenStorageKey, reply.Body.token);
            setAndNotifyLocalStorage(RefreshTokenStorageKey, reply.Body.refresh);

            onSuccess();
          } else {
            setAndNotifyLocalStorage(TokenStorageKey);
            setAndNotifyLocalStorage(RefreshTokenStorageKey);
            onError(reply.Error.map((x: any) => x.Message));
          }
        },
        error => {
          console.log(error);
          setAndNotifyLocalStorage(TokenStorageKey);
          setAndNotifyLocalStorage(RefreshTokenStorageKey);
          onError(["Login Failed"]);
        }
      );
  },
  forgot(onSuccess: any, email: string, onError: any) {
    this.client.apiAuthForgotPost({ Email: email }).then(onSuccess, error => {
      console.log(error);
      if (error.response.data.Error) {
        onError(error.response.data.Error.map((x: any) => x.Message));
      } else {
        onError(["Password Reset Failed."]);
      }
    });
  },
  reset(email: string, token: string, pass: string, pass2: string, onSuccess: any, onError: any) {
    this.client.apiAuthForgotupdatePost({ Email: email, Token: token, Password: pass, ConfirmPassword: pass2 }).then(
      response => {
        var reply: any = response.data;

        if (reply.Error) {
          onError(reply.Error.map((x: any) => x.Message));
        } else {
          onSuccess();
        }
      },
      error => {
        console.log(error);

        if (error.response.data.Error) {
          onError(error.response.data.Error.map((x: any) => x.Message));
        } else {
          onError(["Password Reset Failed."]);
        }
      }
    );
  },
  getUserRoles(onSuccess: (e: UserModel[]) => void, onError: (e: string) => void) {
    if (this._parsedToken?.valid() !== true) onError("Unauthenticated user.");

    this.client.apiAuthGetusersPost().then(
      response => {
        const reply = response.data as unknown as IApiResponse;
        onSuccess(reply.Body as UserModel[]);
      },
      error => {
        console.log(JSON.stringify(error));
        onError(error.message);
      }
    );
  },
  getAllRoles(onSuccess: (e: RoleModel[]) => void, onError: (e: string) => void) {
    if (this._parsedToken?.valid() !== true) onError("Unauthenticated user.");

    this.client.apiAuthGetallrolesPost().then(
      response => {
        const reply = response.data as unknown as IApiResponse;
        onSuccess(reply.Body as RoleModel[]);
      },
      error => {
        console.log(JSON.stringify(error));
        onError(error.message);
      }
    );
  },
  getAllPermissions(onSuccess: (e: PermissionModel[]) => void, onError: (e: string) => void) {
    if (this._parsedToken?.valid() !== true) onError("Unauthenticated user.");

    this.client.apiAuthGetpermissionsPost().then(
      response => {
        const reply = response.data as unknown as IApiResponse;
        onSuccess(reply.Body as PermissionModel[]);
      },
      error => {
        console.log(JSON.stringify(error));
        onError(error.message);
      }
    );
  },
  getRolePermissions(onSuccess: (e: RoleModel[]) => void, onError: (e: string) => void) {
    this.client.apiAuthGetrolesPost().then(
      response => {
        const reply = response.data as unknown as IApiResponse;
        onSuccess(reply.Body as RoleModel[]);
      },
      error => {
        console.log(JSON.stringify(error));
        onError(error.message);
      }
    );
  },
  updateUserRoles(onSuccess: any, users: UserModel[], onError: (e: string) => void) {
    if (this._parsedToken?.valid() !== true) onError("Unauthenticated user.");

    this.client.apiAuthUpdateusersPost(users).then(onSuccess, error => {
      console.log(JSON.stringify(error));
      onError(error.message);
    });
  },
  updateRolePermissions(onSuccess: any, roles: RoleModel[], onError: (e: string) => void) {
    if (this._parsedToken?.valid() !== true) onError("Unauthenticated user.");

    this.client.apiAuthUpdaterolesPost(roles).then(
      () => {
        onSuccess();
      },
      error => {
        console.log(JSON.stringify(error));
        onError(error.message);
      }
    );
  },
  validate(onSuccess: any, user: string, role: string, linked: boolean, onError: (s: string[]) => void) {
    if (this._parsedToken?.valid() !== true) onError(["Unauthenticated user."]);

    const r = role as AllowedGroups;
    if (!r) {
      onError(["Role Not Found."]);
    }
    this.client.apiAuthValidateuserGet(user, r, linked).then(
      response => {
        var reply: any = response.data;

        if (reply.Error) {
          onError(reply.Error.map((x: any) => x.Message));
        } else {
          onSuccess();
        }
      },
      error => {
        console.log(error);
        onError(["User Validation Failed."]);
      }
    );
  },
  register(
    onSuccess: any,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phone: string,
    onError: (s: string[]) => void
  ) {
    this.client.apiAuthRegisterPost({ Email: email, Password: password, FirstName: firstName, LastName: lastName, Phone: phone }).then(
      response => {
        var reply: any = response.data;

        if (reply.Error) {
          onError(reply.Error.map((x: any) => x.Message));
        } else {
          onSuccess();
        }
      },
      error => {
        console.log(error);
        if (error.response.data.Error) {
          onError(error.response.data.Error.map((x: any) => x.Message));
        } else {
          onError(["User Registration Failed."]);
        }
      }
    );
  },
  registerWithProvider(onSuccess: any, provider: string, token: string, onError: (s: string[]) => void) {
    // this needs to move into the apiAuth client
    const path = `${BASE_PATH}/api/v1/v2/auth/${provider}/register/${token}`;

    axios
      .create()
      .post(path)
      .then(
        response => {
          var reply: any = response.data;

          if (reply.Error) {
            onError(reply.Error.map((x: any) => x.Message));
          } else {
            onSuccess();
          }
        },
        error => {
          console.log(error);
          onError(["User Registration Failed."]);
        }
      );
  },
  signout(cb: any, explicit?: boolean) {
    this._parsedToken = {
      token: undefined,
      expiry: 0,
      valid: () => false
    };

    if (explicit === true) {
      setAndNotifyLocalStorage(RefreshTokenStorageKey);
    }
    setAndNotifyLocalStorage(TokenStorageKey);

    cb();
  }
};

const setAndNotifyLocalStorage = (key: string, value?: string) => {
  const newValue = value || null;
  const tokenChangeDetail: StorageEventInit = {
    key: key,
    oldValue: localStorage.getItem(key) || null,
    newValue: newValue
  };

  newValue == null ? localStorage.removeItem(key) : localStorage.setItem(key, newValue);

  window.dispatchEvent(new StorageEvent("storage", tokenChangeDetail));
};

LoginService._init();
