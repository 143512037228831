import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LegacyDealApi } from "api/legacyApi";
import { RootState } from "./configureStore";
import { ApiClients } from "api/clients";
import { createAppSelector } from "./hooks";

interface MarketDealsSliceState {
  dealReference?: string;
  currentDeal?: LegacyDealApi.DealModel;
  loading: boolean;
  error?: string;
}
const initialState: MarketDealsSliceState = { loading: false };

export const loadDealByReference = createAsyncThunk<
  LegacyDealApi.DealModel,
  string | { reference: string; force: boolean },
  { state: RootState }
>(
  "marketDeals/loadDealByReference",
  async (dealReference, g) =>
    (
      await ApiClients.legacy.dealsClient.getDealByReference(
        typeof dealReference === "string" ? dealReference : dealReference.reference,
        g.signal
      )
    ).Body,
  {
    condition: (dealReference, api) => {
      const dealReferenceValue = typeof dealReference === "string" ? dealReference : dealReference.reference;
      const force = typeof dealReference === "string" ? false : dealReference.force === true;
      if (!dealReferenceValue?.trim()) return false;

      const state = api.getState().MarketDeals;
      return state.loading !== true && (state.currentDeal?.DealReference !== dealReferenceValue || force);
    }
  }
);

export const selectCurrentDeal = createAppSelector(
  [
    (state: RootState) => {
      return state.MarketDeals;
    },
    (_: RootState, dealReference?: string) => dealReference
  ],
  (state, dealReference) => {
    return state?.dealReference === dealReference
      ? {
          data: state.currentDeal,
          loading: state.loading,
          error: state.error
        }
      : {
          data: undefined,
          loading: false,
          error: undefined
        };
  }
);
const marketDealsSlice = createSlice({
  name: "marketDeals",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadDealByReference.pending, (state, action) => {
        state.dealReference = typeof action.meta.arg === "string" ? action.meta.arg : action.meta.arg.reference;
        state.error = undefined;
        state.currentDeal = undefined;
        state.loading = true;
      })
      .addCase(loadDealByReference.fulfilled, (state, action) => {
        state.currentDeal = action.payload;
        state.loading = false;
      })
      .addCase(loadDealByReference.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default marketDealsSlice.reducer;
