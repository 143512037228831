import { Flex } from "internal/dcc-components";
import styled from "styled-components/macro";

export const whole = "100%";
export const half = "50%";
export const third = "33.3%";
export const quarter = "25%";
export const fifth = "20%";
export const sixers = "16.66%";
export const tiles = 5;

export const FlexWrapper = styled(Flex)`
  flex-flow: column wrap;
  width: 100%;
  margin: 0 auto;
  color: ${({ theme }) => theme.core.textColor};
`;
export const FlexWrapperRow1 = styled.div`
  display: inline-grid;
  grid-gap: 0.25rem 0.5rem;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  justify-content: space-around;
  align-content: flex-start;
  margin: 0px 0px 0px 4px;
`;

export const FlexWrapperRow = styled.div`
  display: inline-grid;
  grid-gap: 0.25rem 0.5rem;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  justify-content: space-around;
  align-content: flex-start;
  margin: 0px 0px 0px 4px;
`;
export const FlexWrapperRow3 = styled.div`
  display: inline-grid;
  grid-gap: 0.25rem 0.5rem;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto auto;
  justify-content: space-around;
  align-content: flex-start;
  margin: 0px 0px 0px 4px;
`;

export const FlexWrapperRow4 = styled.div`
  display: inline-grid;
  grid-gap: 0.05rem 0.5rem;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto auto;
  justify-content: flex-start;
  align-content: flex-start;
  margin: 0px 0px 0px 0px;
  width: 100%;
`;

export const FlexWrapperRow5 = styled.div`
  display: inline-grid;
  grid-gap: 0.25rem 0.5rem;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: auto auto;
  justify-content: space-around;
  align-content: flex-start;
  margin: 0px 0px 0px 16px;
`;

export const GridItem = styled.div`
  margin: 0.1em 0.1em;
`;
export const MGridItem = styled.div`
  margin: 1.65em 0em 0em 0em;
`;
export const Grid = styled.div`
  display: inline-grid;
  grid-row-gap: 1rem;
  width: 100%;
  margin: 0 auto;
  color: ${({ theme }) => theme.core.textColor};
`;
