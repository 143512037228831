import { put, takeEvery, call } from "redux-saga/effects";
import axios from "axios";
import {
  ConfigBaseAction,
  EscalationCreateFailed,
  EscalationCreateSuccess,
  EscalationDeleteFailed,
  EscalationDeleteSuccess,
  EscalationEnableFailed,
  EscalationEnableSuccess,
  EscalationGetByIdFailed,
  EscalationGetByIdSuccess,
  EscalationGetFailed,
  EscalationGetSuccess,
  EscalationUpdateByIdFailed,
  EscalationUpdateByIdSuccess,
  ESCALATION_BY_ID_GET_REQUESTED,
  ESCALATION_BY_ID_UPDATE_REQUESTED,
  ESCALATION_CREATE_REQUESTED,
  ESCALATION_DELETE_REQUESTED,
  ESCALATION_ENABLE_REQUESTED,
  ESCALATION_GET_REQUESTED
} from "sagas-v2/reducers/indexes";
import { LoginService } from "internal/dcc-services/LoginService";
import {
  CreateEndpointRequestModel,
  CreateEndpointResponseModel,
  DeleteEndpointResponseModel,
  DeleteRequestEndpointResponseModel,
  EnableEndpointResponseModel,
  GetEndpointResponseModel,
  IndexApiFactory,
  ListEndpointResponseModel,
  UpdateEndpointRequestModel,
  UpdateEndpointResponseModel
} from "sagas-v2/api";
import { HTTPReply, IApiResponse } from "sagas-v2/types";

const indexClient = IndexApiFactory();

function* getAllEscalationsWorker(action: ConfigBaseAction<{ includeDisabled?: boolean; includeInactive?: boolean }>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply<ListEndpointResponseModel> = yield call(
      indexClient.indicesEscalationList,
      action.payload?.includeDisabled,
      action.payload?.includeInactive
    );
    const reply: IApiResponse<ListEndpointResponseModel> = res.data;

    yield put(EscalationGetSuccess(reply?.body?.indices));
  } catch (error) {
    const e = error as any;
    yield put(EscalationGetFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* deleteEscalationsWorker(action: ConfigBaseAction<{ escalationId: string }>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply<DeleteRequestEndpointResponseModel> = yield call(
      indexClient.indicesEscalationDeleteRequest,
      action.payload?.escalationId || ""
    );
    const reply: IApiResponse<DeleteRequestEndpointResponseModel> = res.data;
    const token = reply.body?.deleteToken;

    axios.defaults.headers.common["EE-DELETE-TOKEN"] = token;
    let res2: HTTPReply<DeleteEndpointResponseModel> = yield call(
      indexClient.indicesEscalationDelete,
      action.payload?.escalationId || "",
      token || ""
    );
    const reply2: IApiResponse<DeleteEndpointResponseModel> = res2.data;

    if (reply2.body.success) {
      yield put(EscalationDeleteSuccess(reply2.body));
    } else {
      yield put(EscalationDeleteFailed({ Message: "Delete Unsuccessful", Status: 500, StatusText: "Error" }));
    }
  } catch (error) {
    const e = error as any;
    yield put(EscalationDeleteFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  } finally {
    axios.defaults.headers.common["EE-DELETE-TOKEN"] = null;
  }
}

function* enableEscalationsWorker(action: ConfigBaseAction<{ escalationId: string; enabled: boolean }>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    if (action.payload?.enabled) {
      let res: HTTPReply<EnableEndpointResponseModel> = yield call(indexClient.indicesEscalationEnable, action.payload.escalationId);
      const reply: IApiResponse<EnableEndpointResponseModel> = res.data;
      yield put(EscalationEnableSuccess(reply.body));
    } else {
      let res: HTTPReply<EnableEndpointResponseModel> = yield call(
        indexClient.indicesEscalationDisable,
        action?.payload?.escalationId || ""
      );
      const reply: IApiResponse<EnableEndpointResponseModel> = res.data;
      yield put(EscalationEnableSuccess(reply.body));
    }
  } catch (error) {
    const e = error as any;
    yield put(EscalationEnableFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* createEscalationsWorker(action: ConfigBaseAction<CreateEndpointRequestModel>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply<CreateEndpointResponseModel> = yield call(
      indexClient.indicesEscalationCreate,
      action.payload as CreateEndpointRequestModel
    );
    const reply: IApiResponse<CreateEndpointResponseModel> = res.data;
    if (reply.status === "Success") {
      yield put(EscalationCreateSuccess(reply.body));
    } else {
      const error = reply.error[0];
      yield put(EscalationCreateFailed({ Message: error.message, Status: 500, StatusText: error.code }));
    }
  } catch (error) {
    const e = error as any;
    yield put(EscalationCreateFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* getEscalationWorker(action: ConfigBaseAction<string>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply<GetEndpointResponseModel> = yield call(indexClient.indicesEscalationGet, action.payload as string);
    const reply: IApiResponse<GetEndpointResponseModel> = res.data;
    if (reply.status === "Success") {
      yield put(EscalationGetByIdSuccess(reply.body));
    } else {
      const error = reply.error[0];
      yield put(EscalationGetByIdFailed({ Message: error.message, Status: 500, StatusText: error.code }));
    }
  } catch (error) {
    const e = error as any;
    yield put(EscalationGetByIdFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

function* updateEscalationWorker(action: ConfigBaseAction<UpdateEndpointRequestModel>) {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${LoginService.token()}`;

    let res: HTTPReply<UpdateEndpointResponseModel> = yield call(
      indexClient.indicesEscalationUpdate,
      action.payload?.escalationId || "",
      action.payload as UpdateEndpointRequestModel
    );
    const reply: IApiResponse<UpdateEndpointResponseModel> = res.data;
    if (reply.status === "Success") {
      yield put(EscalationUpdateByIdSuccess(reply.body));
    } else {
      const error = reply.error[0];
      yield put(EscalationUpdateByIdFailed({ Message: error.message, Status: 500, StatusText: error.code }));
    }
  } catch (error) {
    const e = error as any;
    yield put(EscalationUpdateByIdFailed({ Message: e.message, Status: e.response?.status, StatusText: e.response?.statusText }));
  }
}

// watchers
export function* watchGetAllEscalations() {
  yield takeEvery(ESCALATION_GET_REQUESTED, getAllEscalationsWorker);
}

export function* watchDeleteEscalations() {
  yield takeEvery(ESCALATION_DELETE_REQUESTED, deleteEscalationsWorker);
}

export function* watchEnableEscalations() {
  yield takeEvery(ESCALATION_ENABLE_REQUESTED, enableEscalationsWorker);
}

export function* watchCreateEscalations() {
  yield takeEvery(ESCALATION_CREATE_REQUESTED, createEscalationsWorker);
}

export function* watchGetEscalation() {
  yield takeEvery(ESCALATION_BY_ID_GET_REQUESTED, getEscalationWorker);
}

export function* watchUpdateEscalation() {
  yield takeEvery(ESCALATION_BY_ID_UPDATE_REQUESTED, updateEscalationWorker);
}
