class FreshworksWidgetHelper {
  private _prefillData: Record<string, any> = {
    name: "",
    email: "",
    customFields: {
      cf_technical_information: ""
    }
  };
  private _recentErrors: Error[] = [];

  private _setupForm(subject?: string, description?: string) {
    window.FreshworksWidget("hide", "ticketForm", ["custom_fields.cf_product", "custom_fields.cf_technical_information"]);
    window.FreshworksWidget(
      "prefill",
      "ticketForm",
      Object.assign(
        {
          subject: subject || "",
          description: description || "",
          customFields: {
            cf_technical_information: "None" // Field is hidden, but required currently.
          }
        },
        this._prefillData
      )
    );
  }

  captureError(error?: ErrorEvent | PromiseRejectionEvent) {
    if (error == null) return;

    const innerError = error instanceof PromiseRejectionEvent ? error.reason || error : error.error;

    this._recentErrors = [innerError, ...this._recentErrors].slice(0, 5);
    this._prefillData.customFields.cf_technical_information = this._recentErrors
      .map(x => [x.name, x.cause, x.message, x.stack].filter(y => !!y).join("\r\n"))
      .join("\r\n-------------\r\n");

    this._setupForm();
  }

  setUserDetails(name?: string, email?: string) {
    this._prefillData.name = name || "";
    this._prefillData.email = email || "";

    this._setupForm();
  }

  openWidget(subject?: string, description?: string): void {
    this._setupForm(subject, description);
    window.FreshworksWidget("open", "ticketForm");
  }
}

const Helper = new FreshworksWidgetHelper();
export default Helper;
