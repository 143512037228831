import { DealModel } from "sagas/api";

export enum DealAction {
  Clear = "Clear",
  Save = "Save",
  Enter = "Enter"
}

export interface DealModelAction {
  Deals?: DealModel[];
  DealAction: DealAction;
}

export enum DealActionReply {
  Succeeded = "Succeeded",
  Failed = "Failed",
  Canceled = "Canceled"
}

export interface DealResult {
  DealId: number;
  DealReference: string;
}

export interface DealActionResult {
  DealActionReply: DealActionReply;
  DealResult: DealResult;
  DealActionRequest: DealAction;
}
