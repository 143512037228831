import { ThunkAction, Action, configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import { rootSaga } from "sagas/reducers/config";

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

export const store = configureStore({
  reducer: rootReducer(history), // root reducer with router state
  preloadedState: {},
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat([routerMiddleware(history), sagaMiddleware])
});

export default store;

sagaMiddleware.run(rootSaga);

// state
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
