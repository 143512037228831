import * as React from "react";
import { Theme } from "internal/dcc-theme";
import styled from "styled-components";
import { Flex } from ".";
import ReactDOM from "react-dom";
import usePageTitle from "helpers/usePageTitle";

export function divStyle(theme: Theme, width?: string) {
  return {
    border: "none",
    width: width ? width : "95%",
    marginTop: "0rem",
    boxShadow: "0 1px 0 0 " + theme.accents.primary.base,
    boxShadowColor: theme.accents.primary.base
  };
}

export const Title = (props: React.PropsWithChildren<{ main?: boolean }>) => {
  const [title, setTitle] = React.useState("");

  usePageTitle(title);

  React.useEffect(() => {
    if (props.main !== true) return;

    if (typeof props.children === "string") {
      setTitle(props.children);
    } else if (React.isValidElement(props.children)) {
      const container = document.createElement("div");

      ReactDOM.render(props.children as React.ReactElement, container, () => {
        const title = container.innerText;
        setTitle(title);
      });
    }
  }, [props.children, props.main]);

  return typeof props.children === "string" ? (
    <div>
      <h4 style={{ marginBottom: "0.5em" }}>{props.children}</h4>
    </div>
  ) : (
    <div>{props.children}</div>
  );
};

export const FlexWrapperItem = styled(Flex)<{ width?: string; margin?: string }>`
  display: flex;
  width: ${({ width }) => (width ? width : `100%`)};
  margin: ${({ margin }) => (margin ? margin : `0.1em 0.1em`)};
`;

export const FlexWrapperContainer = styled(Flex)<{ width?: string; padding?: string; wrap?: string }>`
  flex-flow:  ${({ wrap }) => (wrap ? wrap : `row nowrap`)};
  width:  ${({ width }) => (width ? width : `100%`)};
  padding:  ${({ padding }) => (padding ? padding : `0px`)};
  margin: 0 auto;
  border-radius: 3px;

  background-color: none};
`;

export const GridItem = styled.div<{
  margin?: string;
  padding?: string;
  display?: string;
  justify?: string;
  align?: string;
  width?: string;
}>`
  padding: ${({ padding }) => (padding ? padding : `0`)};
  margin: ${({ margin }) => (margin ? margin : `0.1em 0.1em`)};
  ${({ display }) => display && `display: ${display}`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ align }) => align && `align-items: ${align}`};
  ${({ width }) => width && `width: ${width}`};
`;

export const GridLayoutOne = styled.div`
  display: inline-grid;
  grid-gap: 0.25rem 0.5rem;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  justify-content: space-around;
  align-content: center;
`;

export const GridLayout = styled.div<{ columns?: string; width?: string; justify?: string }>`
  display: inline-grid;
  grid-gap: 0.25rem 0.5rem;
  grid-template-columns: ${({ columns }) => (columns ? columns : `50% 50%`)};
  ${({ width }) => width && `width: ${width}`};
  grid-template-rows: auto auto;
  justify-content: ${({ justify }) => (justify ? justify : `space-around`)};
  align-content: flex-start;
`;

export const WorkspaceItems = styled.div<{ background?: string }>`
  display: flex;
  height: 100%;
  flex-direction: row;
  background-color: ${({ background }) => (background ? background : "rgb(225,238,249)")};
  justify-content: center;
  align-content: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const WorkspaceVerticalGroup = styled.div<{ width?: string; background?: string }>`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  ${({ background }) => (background ? `background-color: ${background};` : "")}
  flex: ${({ width }) => (width ? width : `50%`)};
`;

export const WorkspaceItem = styled.div`
  min-width: 25rem;
`;

export const ExpandoWorkspaceItem = styled(WorkspaceItem)`
  height: 100%;
`;

export const Watermark = styled.span<{
  width?: string;
  disabled?: boolean;
  margin?: string;
  wrap?: string;
  color?: string;
}>`
  white-space: ${({ wrap }) => (wrap ? wrap : `nowrap`)};
  ${({ disabled }) => disabled && "opacity: 0.3;"};
  margin: ${({ margin }) => (margin ? margin : `0`)};
  color: ${({ color }) => (color ? color : "rgb(242,103,34)")};
`;

export const Label = styled.label<{ width?: string; disabled?: boolean; margin?: string; wrap?: string }>`
  white-space: ${({ wrap }) => (wrap ? wrap : `nowrap`)};
  ${({ disabled }) => disabled && "opacity: 0.3;"};
  margin: ${({ margin }) => (margin ? margin : `0`)};
  ${({ margin }) => (margin ? "display: inline-block;" : "")}
`;
export const Optional = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && "opacity: 0.3;"};
`;
export const Disabled = styled.div<{ disabled?: boolean; width?: string }>`
  ${({ disabled }) => (disabled ? "pointer-events: none;" : "pointer-events: auto;")};
  ${({ width }) => width && `${width}`};
`;

export const Warn = styled.span<{ width?: string }>`
  color: ${({ theme }) => theme.accents.negative.base};
`;

export const Link = styled.div`
  text-decoration: underline;
  border: none;
  box-shadow: none;
  outline: none;
  color: ${({ theme }) => theme.accents.primary.base};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: underline;
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

export const FlexCol = styled.div<{ width?: string; padding?: string; margin?: string; align?: string }>`
  display: flex;
  padding: ${({ padding }) => (padding ? padding : `0.25em 0.25em`)};
  margin: ${({ margin }) => (margin ? margin : `0rem 0rem 0rem 0rem`)};
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: ${({ align }) => align || "normal"};
  justify-content: space-between;
  width: ${({ width }) => (width ? width : `100%`)};
`;

export const FlexRow = styled.div<{ width?: string; padding?: string; alignitems?: string; justifycontent?: string }>`
  display: flex;
  padding: ${({ padding }) => (padding ? padding : `0.25em 0.25em`)};
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: ${({ alignitems }) => (alignitems ? alignitems : `center`)};
  justify-content: ${({ justifycontent }) => (justifycontent ? justifycontent : `space-between`)};
  width: ${({ width }) => (width ? width : `100%`)};
`;

export const RouteStyle = styled("div")`
  width: 100%;
  background-color: ${({ theme }) => theme.core.darkBackground};
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const TileBaseStyle = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  padding: 1.25rem;
  box-sizing: border-box;

  ${RouteStyle} & {
    border-radius: 0px;
  }
`;

export const SpotTileStyle = styled(TileBaseStyle)`
  background-color: ${({ theme }) => theme.primary[1]};
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
`;
export const Tile = styled.div<{ isFlat?: boolean | null }>`
    position: relative;
    border-radius: 3px;
    padding: 0.5rem 1rem 1rem 1rem;
    margin: 0.5rem;
    box-sizing: border-box;
    background-color: ${p => p.theme.primary.base};
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    
    &:hover {
      position: relative; 
      top: 0px;
      left: 0px;
    }
}
`;
export const ExpandoTile = styled(Tile)``;
export const TileWrapperBase = styled.div`
  position: relative;

  color: ${({ theme }) => theme.core.textColor};
`;
export const SpotTileWrapper = styled(TileWrapperBase)`
  position: relative;
  min-height: 11rem;
  height: 100%;
`;

export const ThinSeparator = styled.hr`
  border: none;
  border-bottom: ${({ theme }) => `2px solid ${theme.core.primaryStyleGuideBackground}`};
  margin: 0.01em 0;
`;

export const Separator = styled.hr<{ spacing?: string; theme: Theme }>`
  border: none;
  border-bottom: ${({ theme }) => `2px solid ${theme.core.primaryStyleGuideBackground}`};
  ${({ spacing }) => (spacing ? `margin: ${spacing}em 0` : `margin: 0.01em 0`)};
`;

export const TwoColumnWrapper = styled.div`
  width: calc(50% - 2px);
  margin-right: 2px;

  &:nth-child(2n + 1) {
    margin-left: 2px;
    margin-right: 0;
  }
`;
