import React, { forwardRef, KeyboardEvent } from "react";
import { SelectButtonBase } from "./styled";
import { Flex } from "internal/dcc-components";
import styled from "styled-components/macro";

export interface SelectButtonProps {
  label: string;
  primary?: boolean;
  clicked: boolean;
  disable?: boolean;
  onClick(e: any): void;
  width: string;
  style?: React.CSSProperties;
  tabIndex?: number;
  id?: string;
  onKeyDown?: (e: KeyboardEvent<HTMLButtonElement>) => void;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const FlexWrapper = styled(Flex)`
  display: flex;
`;

export const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>((props: SelectButtonProps, ref) => {
  const handleChange = (e: any) => {
    if (!props.disable) {
      props.onClick(e);
    }
  };

  let button;
  if (props.primary) {
    button = (
      <SelectButtonBase
        {...props.buttonProps}
        ref={ref}
        id={props.id}
        tabIndex={props.tabIndex}
        selected={props.clicked}
        disable={props.disable}
        onKeyDown={props.onKeyDown}
        onClick={handleChange}
        style={props.style}
        primary>
        {props.label}
      </SelectButtonBase>
    );
  } else {
    button = (
      <SelectButtonBase
        ref={ref}
        id={props.id}
        tabIndex={props.tabIndex}
        selected={props.clicked}
        disable={props.disable}
        onKeyDown={props.onKeyDown}
        onClick={handleChange}
        style={props.style}>
        {props.label}
      </SelectButtonBase>
    );
  }

  return <FlexWrapper $width={props.width}>{button}</FlexWrapper>;
});
