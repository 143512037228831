import { ValidationMessage } from "./types";

export { Side, Period, Region, Quarter, Strip, Month, ProductType } from "./enums";
export { RegionLookup, QuarterPeriodLookup, StripPeriodLookup, emptyFuture, emptyOption } from "./futures";
export type { Future, Option } from "./futures";
export type { Expiry } from "./dates";

export { ServiceConnectionStatus } from "./serviceStatus";
export type { ServiceStatus } from "./serviceStatus";

export { SnapshotActiveStatus } from "./snapshotStatus";

export * from "./tradeStatus";
export { UpdateType } from "./updateType";

export type { User } from "./user";

export const ValidFutureTickerCode = new RegExp("^[E|B|P|H|D]{1}[N|Q|V|S]{1}[F|G|H|J|K|M|N|Q|U|V|X|Z]{1}[0-9]{2}$");
export const ValidCapTickerCode = new RegExp("^[G|R]{1}[N|Q|V|S]{1}[H|M|U|Z]{1}[0-9]{2}$");
export const ValidFEXCapTickerCode = new RegExp("^[G|R|F|Q]{1}[N|Q|V|S]{1}[H|M|U|Z]{1}[0-9]{2}$");
export const ValidAsianOptionTickerCode = new RegExp("^[B]{1}[N|Q|V|S]{1}[H|M|U|Z]{1}[0-9]{4}[C|P]{1}[0-9]{7}$");
export const ValidStripOptionTickerCode = new RegExp("^[H]{1}[N|Q|V|S]{1}[M|Z]{1}[0-9]{4}[C|P]{1}[0-9]{7}$");
export const ValidCapCode = new RegExp("^[G|R]{1}$");
export const ValidFEXCapCode = new RegExp("^[G|R|F|Q]{1}$");
export const ValidAsianCode = new RegExp("^[B]{1}$");
export const ValidOptionCode = new RegExp("^[H]{1}$");
export const ValidOptionType = new RegExp("^[C|P]{1}$");
export const ValidStripOptionCode = new RegExp("^[H]{1}$");
export const ValidProductCode = new RegExp("^[E|B|P|H|D]{1}$");
export const ValidState = new RegExp("^[N|Q|V|S]{1}$");
export const ValidQuarter = new RegExp("^[H|M|U|Z]{1}$");
export const ValidStrip = new RegExp("^[M|Z]{1}$");
export const ValidMonth = new RegExp("^[F|G|H|J|K|M|N|Q|U|V|X|Z]{1}$");
export const ValidYear = new RegExp("^[0-9]{1}$");
export const ValidFullYear = new RegExp("^(?:19|20)[0-9]{2}$");
export const ValidSmallPrice = new RegExp("^([0-9],?){0,11}(\\.[0-9]{0,4})?$");
export const ValidPrice = new RegExp("^([0-9],?){0,11}(\\.[0-9]{0,2})?$");
export const ValidInt = new RegExp("^([0-9],?){0,11}$");
export const ValidEmail = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$");
export const ValueRequired = new RegExp("^(?!\\s*$).+");
export const ValueMax254Chars = new RegExp("^[a-zA-Z0-9]{0,254}$");

export const validFutCode: ValidationMessage = { type: "warning", content: "Must be E|B|P|H|D" };
export const validCapCode: ValidationMessage = { type: "warning", content: "Must be G|R" };
export const validFEXCapCode: ValidationMessage = { type: "warning", content: "Must be G|R|F|Q" };
export const validAsianCode: ValidationMessage = { type: "warning", content: "Must be B" };
export const validOptionCode: ValidationMessage = { type: "warning", content: "Must be H" };
export const validOptionType: ValidationMessage = { type: "warning", content: "Must be C|P" };
export const validState: ValidationMessage = { type: "warning", content: "Must be N|Q|V|S" };
export const validMonth: ValidationMessage = { type: "warning", content: "Must be F|G|H|J|K|M|N|Q|U|V|X|Z" };
export const validQuarter: ValidationMessage = { type: "warning", content: "Must be H|M|U|Z" };
export const validStrip: ValidationMessage = { type: "warning", content: "Must be M|Z" };
export const validDigit: ValidationMessage = { type: "warning", content: "Must be 0-9" };
export const validCode: ValidationMessage = { type: "warning", content: "Invalid Code" };
export const validEmail: ValidationMessage = { type: "warning", content: "Invalid Email Address" };
export const validFullYear: ValidationMessage = { type: "warning", content: "Invalid Year" };
export const valueRequired: ValidationMessage = { type: "warning", content: "Value Required" };
export const valueMax254Chars: ValidationMessage = { type: "warning", content: "Must be less than 255 characters" };
export const valueMatch: ValidationMessage = { type: "warning", content: "Values do not match" };
export const validNumber: ValidationMessage = {
  type: "warning",
  content: "Must be less than 100B with 2 decimal places"
};
export const validInt: ValidationMessage = { type: "warning", content: "The minimum increment is 1" };
export const validDecimal: ValidationMessage = { type: "warning", content: "The minimum increment is 0.01" };
export const validSmallDecimal: ValidationMessage = { type: "warning", content: "The minimum increment is 0.0001" };
export const validSum: ValidationMessage = { type: "warning", content: "Hours*Vol*Price sum must be equal" };

export const validIntervalDays: (days: number) => ValidationMessage = (days = 14) => ({
  type: "warning",
  content: `Must be no more than ${days === 1 ? "1 day" : `${days} days`}`
});
export const validInterval: ValidationMessage = validIntervalDays(14);
export const validStartDate: ValidationMessage = { type: "warning", content: "Must be before End Date" };
export const validEndDate: ValidationMessage = { type: "warning", content: "Must be after Start Date" };
export const validFutureDate: ValidationMessage = { type: "warning", content: "Must be a valid future date" };
export const validPastDate: ValidationMessage = { type: "warning", content: "Must be today or a past date" };
export const validExpDate: ValidationMessage = { type: "warning", content: "Must be on or before Expiry Date" };
export const validDate: ValidationMessage = { type: "warning", content: "Must be a valid date" };
export const correctMessage: ValidationMessage = { type: "info", content: "" };
export const balanceOfMessage: ValidationMessage = { type: "info", content: "Date changed to balance of period" };
export const customInfoMessage = (s: string): ValidationMessage => {
  return { type: "info", content: s };
};
export const customWarningMessage = (s: string): ValidationMessage => {
  return { type: "warning", content: s };
};
