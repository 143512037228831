import { DealType, EPInstrumentType, OtcInstrumentType, Side, SRAInstrumentType } from "internal/dcc-types/enums";
import { EnvironmentalProduct } from "internal/dcc-types/ep";
import { Averages, OTCPrice, OTCProduct, OTCScaled, OTCVol } from "internal/dcc-types/otc";
import { SRA } from "internal/dcc-types/sra";
import { Deal } from "internal/dcc-types/tradeStatus";

// REDUX STATE
export interface PowerSelectorState {
  DealType: DealType | null;
  Instrument: OtcInstrumentType | EPInstrumentType | SRAInstrumentType | null;
  Side: Side;
  Product: OTCProduct | EnvironmentalProduct | SRA | null;
  ReviewProduct: Deal | null;
  Price: OTCPrice[] | number | null;
  Volume: OTCVol[] | number | null;
  Scaled?: OTCScaled | null;
}

// ACTIONS
export const DEALTYPE_SELECTED = "POWER_DEAL_SELECTOR/DEALTYPE_SELECTED";
export const INSTRUMENT_SELECTED = "POWER_DEAL_SELECTOR/INSTRUMENT_SELECTED";
export const PRODUCT_SELECTED = "POWER_DEAL_SELECTOR/PRODUCT_SELECTED";
export const SIDE_SELECTED = "POWER_DEAL_SELECTOR/SIDE_SELECTED";
export const VOLUME_SELECTED = "POWER_DEAL_SELECTOR/VOLUME_SELECTED";
export const SCALED_SELECTED = "POWER_DEAL_SELECTOR/SCALED_SELECTED";
export const PRICE_SELECTED = "POWER_DEAL_SELECTOR/PRICE_SELECTED";
export const CLEAR_STATE = "POWER_DEAL_SELECTOR/CLEAR_STATE";

export const PRODUCT_REVIEW = "POWER_DEAL_SELECTOR/PRODUCT_REVIEW";
export const PRODUCT_REVIEW_UPDATE_HOURS = "POWER_DEAL_SELECTOR/PRODUCT_REVIEW_UPDATE_HOURS";

interface DealTypeSelectedAction {
  type: typeof DEALTYPE_SELECTED;
  payload: DealType | null;
}

interface InstrumentSelectedAction {
  type: typeof INSTRUMENT_SELECTED;
  payload: OtcInstrumentType | EPInstrumentType | SRAInstrumentType | null;
}

interface ProductSelectedAction {
  type: typeof PRODUCT_SELECTED;
  payload: OTCProduct | EnvironmentalProduct | SRA | null;
}

interface SideSelectedAction {
  type: typeof SIDE_SELECTED;
  payload: Side;
}

interface VolumeSelectedAction {
  type: typeof VOLUME_SELECTED;
  payload: OTCVol[] | number | null;
}

interface ScaledSelectedAction {
  type: typeof SCALED_SELECTED;
  payload: OTCScaled | null;
}

interface PriceSelectedAction {
  type: typeof PRICE_SELECTED;
  payload: OTCPrice[] | number | null;
}

interface ClearStateAction {
  type: typeof CLEAR_STATE;
  payload: boolean;
}

interface PowerProductReviewAction {
  type: typeof PRODUCT_REVIEW;
  payload: Deal | null;
}

interface PowerProductReviewUpdateHoursAction {
  type: typeof PRODUCT_REVIEW_UPDATE_HOURS;
  payload: { hours: number | null; averages?: Averages };
}

export type DealSelectorActionTypes =
  | ProductSelectedAction
  | SideSelectedAction
  | VolumeSelectedAction
  | PriceSelectedAction
  | InstrumentSelectedAction
  | ClearStateAction
  | DealTypeSelectedAction
  | PowerProductReviewAction
  | PowerProductReviewUpdateHoursAction
  | ScaledSelectedAction;

// ACTION CREATORS
export function selectPowerDealType(inst: DealType | null): DealSelectorActionTypes {
  return {
    type: DEALTYPE_SELECTED,
    payload: inst
  };
}

export function selectPowerInstrument(
  instrument: OtcInstrumentType | EPInstrumentType | SRAInstrumentType | null
): DealSelectorActionTypes {
  return {
    type: INSTRUMENT_SELECTED,
    payload: instrument
  };
}

export function selectPowerProduct(product: OTCProduct | EnvironmentalProduct | SRA | null): DealSelectorActionTypes {
  return {
    type: PRODUCT_SELECTED,
    payload: product
  };
}

export function selectPowerSide(side: Side): DealSelectorActionTypes {
  return {
    type: SIDE_SELECTED,
    payload: side
  };
}

export function selectPowerVolume(vol: OTCVol[] | number | null): DealSelectorActionTypes {
  return {
    type: VOLUME_SELECTED,
    payload: vol
  };
}

export function selectPowerScaled(vol: OTCScaled | null): DealSelectorActionTypes {
  return {
    type: SCALED_SELECTED,
    payload: vol
  };
}

export function selectPowerPrice(price: OTCPrice[] | number | null): DealSelectorActionTypes {
  return {
    type: PRICE_SELECTED,
    payload: price
  };
}

export function clearPowerState(p: boolean): DealSelectorActionTypes {
  return {
    type: CLEAR_STATE,
    payload: p
  };
}

export function reviewPowerProduct(product: Deal | null): DealSelectorActionTypes {
  return {
    type: PRODUCT_REVIEW,
    payload: product
  };
}

export function reviewPowerProductUpdateHours(hours: number | null, averages?: Averages): DealSelectorActionTypes {
  return {
    type: PRODUCT_REVIEW_UPDATE_HOURS,
    payload: { hours: hours, averages: averages }
  };
}

// REDUCER
const initialState: PowerSelectorState = {
  Side: Side.Undefined,
  DealType: null,
  Instrument: null,
  Product: null,
  Volume: null,
  Price: null,
  ReviewProduct: null
};

export function powerDealSelectorReducer(state = initialState, action: DealSelectorActionTypes): PowerSelectorState {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...initialState,
        ReviewProduct: state.ReviewProduct
      };
    }
    case DEALTYPE_SELECTED: {
      return {
        ...state,
        DealType: action.payload,
        ReviewProduct: state.ReviewProduct
      };
    }
    case INSTRUMENT_SELECTED: {
      return {
        ...state,
        Instrument: action.payload,
        DealType: state.DealType,
        ReviewProduct: state.ReviewProduct
      };
    }
    case PRODUCT_SELECTED: {
      if (action.payload == null && state.Product == null) return state;
      return {
        ...state,
        Product: action.payload
      };
    }
    case PRODUCT_REVIEW: {
      return {
        ...state,
        ReviewProduct: action.payload
      };
    }
    case PRODUCT_REVIEW_UPDATE_HOURS: {
      return {
        ...state,
        ReviewProduct: {
          ...state.ReviewProduct,
          CurrencyCode: state.ReviewProduct?.CurrencyCode || "AUD",
          PowerProduct: {
            ...state.ReviewProduct?.PowerProduct!,
            Hours: action.payload.hours,
            Averages: action.payload?.averages
          }
        }
      };
    }
    case SIDE_SELECTED: {
      return {
        ...state,
        Side: action.payload
      };
    }
    case VOLUME_SELECTED: {
      return {
        ...state,
        Volume: action.payload
      };
    }
    case SCALED_SELECTED: {
      return {
        ...state,
        Scaled: action.payload
      };
    }
    case PRICE_SELECTED: {
      return {
        ...state,
        Price: action.payload
      };
    }
    default:
      return state;
  }
}
