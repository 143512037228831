import { all } from "redux-saga/effects";
import {
  watchCreateEscalations,
  watchDeleteEscalations,
  watchEnableEscalations,
  watchGetAllEscalations,
  watchGetEscalation,
  watchUpdateEscalation
} from "sagas-v2/controllers/indexes";
import {
  watchGetUnderlyingQuarters,
  watchContractPeriodHours,
  watchGetDefaultFees,
  watchGetInternalDetails,
  watchGetMarketSettings,
  watchSetMarketSettings,
  watchSetInternalDetails,
  watchSetDefaultFees,
  watchGetAllProfiles,
  watchUpdateProfile,
  watchUpdateProfileDetails,
  watchUpdatePeriods
} from "./config";
import {
  watchCreateDayType,
  watchDeleteDayType,
  watchDeleteDayTypeRequest,
  watchGetDays,
  watchGetDayType,
  watchGetDayTypes,
  watchUpdateDayTypeRequest
} from "./daytypes";
import { watchGetDeals, watchGetLogs, watchSubmitDeal } from "./deals";
import {
  watchCreateProfile,
  watchDeleteProfile,
  watchDeleteProfileRequest,
  watchGetProfile,
  watchGetProfileDisplayPriorities,
  watchGetProfileExpanded,
  watchGetProfileNormalisations,
  watchGetProfileResolutions,
  watchGetProfileSeasons,
  watchGetProfileTypes,
  watchGetProfileUsages,
  watchGetSummary,
  watchUpdateProfileV2
} from "./profiles";

// root
export function* rootSaga() {
  yield all([
    watchSubmitDeal(),
    watchGetUnderlyingQuarters(),
    watchContractPeriodHours(),
    watchGetDefaultFees(),
    watchGetInternalDetails(),
    watchGetMarketSettings(),
    watchSetMarketSettings(),
    watchSetInternalDetails(),
    watchSetDefaultFees(),
    watchGetLogs(),
    watchGetDeals(),
    watchGetAllProfiles(),
    watchUpdateProfileV2(),
    watchUpdateProfileDetails(),
    watchUpdatePeriods(),
    watchCreateProfile(),
    watchGetSummary(),
    watchGetProfile(),
    watchUpdateProfile(),
    watchDeleteProfileRequest(),
    watchDeleteProfile(),
    watchGetProfileSeasons(),
    watchGetProfileTypes(),
    watchGetProfileResolutions(),
    watchGetProfileNormalisations(),
    watchGetProfileDisplayPriorities(),
    watchGetProfileUsages(),
    watchGetProfileExpanded(),
    watchCreateDayType(),
    watchGetDayTypes(),
    watchGetDays(),
    watchGetDayType(),
    watchUpdateDayTypeRequest(),
    watchDeleteDayType(),
    watchDeleteDayTypeRequest(),

    watchGetAllEscalations(),
    watchDeleteEscalations(),
    watchEnableEscalations(),
    watchCreateEscalations(),
    watchGetEscalation(),
    watchUpdateEscalation()
  ]);
}
