import styled, { DefaultTheme } from "styled-components/macro";
import { SubmitButtonProps } from "./SubmitButton";

interface SelectButtonBaseProps {
  primary?: boolean;
  selected: boolean;
  label?: String;
  disable?: boolean;
}
export const SelectButtonBase = styled.button`
  background: ${p =>
    p.disable
      ? p.theme.primary[2]
      : !p.selected
        ? p.theme.primary[2]
        : p.primary
          ? p.theme.accents.primary.base
          : p.theme.accents.negative.base};
  border-radius: 3px;
  border: none;
  color: ${p => (p.disable ? p.theme.primary[1] : p.selected ? p.theme.primary.base : p.theme.secondary.base)};
  transition: background-color 0.2s ease;
  cursor: ${(props: SelectButtonBaseProps) => (props.disable ? `initial` : `pointer`)};
  margin: 0.1em 0.1em;
  padding: 0.25em 0.25em;
  width: 100%;
  box-shadow: ${p =>
    p.selected
      ? `rgba(0, 0, 0, 0.3) 0px 1px 0px 0px;`
      : p.disable
        ? `rgba(0, 0, 0, 0.3) 0px 0px 0px 0px`
        : `rgba(0, 0, 0, 0.3) 0px 1px 0px 0px`};
  &:focus {
    box-shadow: ${p =>
      p.selected
        ? `rgba(0, 0, 0, 0.3) 0px 1px 0px 0px;`
        : !p.disable
          ? `rgba(0, 0, 0, 0.3) 0px 2px 1px 1px;`
          : `rgba(0, 0, 0, 0.3) 0px 0px 0px 0px`};
    color: ${p => (p.disable ? p.theme.primary[1] : p.theme.primary.base)};
    background: ${p =>
      p.disable
        ? p.theme.primary[2]
        : !p.selected
          ? p.theme.primary[1]
          : p.primary
            ? p.theme.accents.primary.base
            : p.theme.accents.negative.base};
  }
  &:hover {
    box-shadow: ${p =>
      p.selected
        ? `rgba(0, 0, 0, 0.3) 0px 1px 0px 0px;`
        : !p.disable
          ? `rgba(0, 0, 0, 0.3) 0px 2px 1px 1px;`
          : `rgba(0, 0, 0, 0.3) 0px 0px 0px 0px`};
    color: ${p => (p.disable ? p.theme.primary[1] : p.theme.primary.base)};
    background: ${p =>
      p.disable
        ? p.theme.primary[2]
        : !p.selected
          ? p.theme.primary[1]
          : p.primary
            ? p.theme.accents.primary.base
            : p.theme.accents.negative.base};
  }
`;

export const SubmitButtonBase = styled.button<{
  props: SubmitButtonProps;
  theme: DefaultTheme;
  disabled?: boolean;
  margin?: string;
}>`
  background-color: ${({ props, theme, disabled }) =>
    disabled
      ? theme.primary[2]
      : props.positive
        ? theme.accents.positive.base
        : props.negative
          ? theme.accents.negative.base
          : theme.accents.aware.base};
  border: none;
  box-shadow: ${({ disabled }) => (disabled ? `rgba(0, 0, 0, 0.3) 0px 0px 0px 0px` : "rgba(0, 0, 0, 0.3) 0px 1px 0px 0px")};
  border-radius: 3px;
  white-space: nowrap;
  color: ${({ theme, disabled }) => (disabled ? theme.primary.base : theme.primary.base)};
  padding: 0.5rem 0.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: ${({ margin }) => (margin ? margin : `4px 2px`)};
  cursor: pointer;
  width: ${({ props }) => props.width};
  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? `rgba(0, 0, 0, 0.3) 0px 0px 0px 0px` : "rgba(0, 0, 0, 0.3) 0px 2px 1px 1px")};
    background-color: ${({ props, theme, disabled }) =>
      disabled
        ? theme.primary[2]
        : props.positive
          ? theme.accents.positive.darker
          : props.negative
            ? theme.accents.negative.darker
            : theme.accents.aware.darker};
    color: ${({ theme, disabled }) => (disabled ? theme.primary.base : theme.primary[2])};
  }
  &:focus {
    box-shadow: ${({ disabled }) => (disabled ? `rgba(0, 0, 0, 0.3) 0px 0px 0px 0px` : "rgba(0, 0, 0, 0.3) 0px 2px 1px 1px")};
    background-color: ${({ props, theme, disabled }) =>
      disabled
        ? theme.primary[2]
        : props.positive
          ? theme.accents.positive.darker
          : props.negative
            ? theme.accents.negative.darker
            : theme.accents.aware.darker};
    color: ${({ theme, disabled }) => (disabled ? theme.primary.base : theme.primary[2])};
  }

  :disabled {
    opacity: 0.59;
    cursor: not-allowed;
  }
`;
