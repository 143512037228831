import React from "react";
import { SelectButton } from "app/components/Button";
import { Flex } from "internal/dcc-components";
import styled from "styled-components/macro";

export interface GroupButtonItem {
  label: string;
  primary: boolean;
  disable?: boolean;
}

export interface GroupButtonProps {
  items: GroupButtonItem[];
  width: string;
  onSelected(e: string): void;
}

interface GroupButtonState {
  selectedLabel: string;
  clicked: Map<string, boolean>;
}

const FlexWrapper = styled(Flex)`
  flex-flow: row wrap;
  width: 100%;
`;

export class GroupButton extends React.Component<GroupButtonProps, GroupButtonState> {
  constructor(props: GroupButtonProps) {
    super(props);

    const defaultClicked = new Map<string, boolean>(props.items.map(x => [x.label, false] as [string, boolean]));

    this.handleChange = this.handleChange.bind(this);
    this.state = {
      selectedLabel: "",
      clicked: defaultClicked
    };
  }

  clear() {
    this.setState(state => {
      let newState = Object.assign(state);
      newState.clicked.forEach((value: boolean, key: string, map: Map<string, boolean>) => map.set(key, false));
      return newState;
    });
  }

  setSelection(e: string) {
    this._setState(e);
  }

  _setState(selected: string) {
    this.setState(state => {
      let newState = Object.assign(state);
      newState.clicked.forEach((value: boolean, key: string, map: Map<string, boolean>) => map.set(key, false));
      newState.clicked.set(selected, true);
      return newState;
    });
    this.props.onSelected(selected);
  }

  handleChange(event: React.ChangeEvent<unknown>) {
    let selected = (event.target as HTMLInputElement).innerText;
    this._setState(selected);
  }

  render() {
    const items = this.props.items.map((i, inx) => (
      <SelectButton
        onKeyDown={() => {}}
        width={this.props.width}
        key={inx}
        label={i.label}
        primary={i.primary}
        clicked={this.state.clicked.get(i.label) || false}
        onClick={this.handleChange}
        disable={i.disable}
      />
    ));

    return (
      <FlexWrapper justifyContent="flex-start" alignItems="center">
        {items}
      </FlexWrapper>
    );
  }
}
