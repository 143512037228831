import { Month, Region, Side, Period, ProductType } from "internal/dcc-types";
import { InstrumentType } from "internal/dcc-types/enums";
import {
  PRODUCT_SELECTED,
  DEALTYPE_SELECTED,
  PRICE_SELECTED,
  SIDE_SELECTED,
  TickerSelectorActionTypes,
  VOLUME_SELECTED,
  INSTRUMENT_SELECTED,
  TICKER_CLEARED,
  PRODUCT_REVIEW,
  PRODUCT_REVIEW_UPDATE_HOURS,
  TICKER_PENDING
} from "./actions";
import { TickerSelectorState } from "./state";

const initialState: TickerSelectorState = {
  Side: Side.Undefined,
  Instrument: InstrumentType.Undefined,
  Product: {
    Expiry: { Month: Month.Undefined, Year: 0 },
    Region: Region.Undefined,
    ProductType: ProductType.Undefined,
    Period: Period.Undefined,
    Ticker: ""
  },
  Volume: null,
  Price: null,
  ReviewProduct: null,
  DealType: null,
  Pending: null
};

export function tickerSelectorReducer(state = initialState, action: TickerSelectorActionTypes): TickerSelectorState {
  switch (action.type) {
    case DEALTYPE_SELECTED: {
      return {
        ...initialState,
        DealType: action.payload
      };
    }
    case TICKER_CLEARED:
      return {
        ...initialState,
        DealType: state.DealType,
        ReviewProduct: state.ReviewProduct
      };
    case INSTRUMENT_SELECTED: {
      return {
        ...initialState,
        Instrument: action.payload,
        DealType: state.DealType,
        ReviewProduct: state.ReviewProduct
      };
    }
    case PRODUCT_SELECTED: {
      return {
        ...state,
        Product: action.payload
      };
    }
    case PRODUCT_REVIEW: {
      return {
        ...state,
        ReviewProduct: action.payload
      };
    }
    case PRODUCT_REVIEW_UPDATE_HOURS: {
      return {
        ...state,
        ReviewProduct: {
          ...state.ReviewProduct,
          CurrencyCode: state.ReviewProduct?.CurrencyCode || "AUD",
          ExchangeProduct: { ...state.ReviewProduct?.ExchangeProduct!, Hours: action.payload }
        }
      };
    }
    case SIDE_SELECTED: {
      return {
        ...state,
        Side: action.payload
      };
    }
    case VOLUME_SELECTED: {
      return {
        ...state,
        Volume: action.payload
      };
    }
    case PRICE_SELECTED: {
      return {
        ...state,
        Price: action.payload
      };
    }
    case TICKER_PENDING: {
      return {
        ...state,
        Pending: action.payload
      };
    }
    default:
      return state;
  }
}
