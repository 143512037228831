import { Strip as ApiStrip } from "sagas/api";

export enum SettlementPeriod {
  M30 = 30,
  M5 = 5
}

export enum Exchange {
  ASX = "ASX",
  FEX = "FEX",
  AEMO = "AEMO"
}

export enum Side {
  Undefined = "Undefined",
  Buy = "Buy",
  Sell = "Sell"
}

export enum Period {
  Undefined = "Undefined",
  Base = "Base",
  Peak = "Peak"
}

export enum Profile {
  Undefined = "Undefined",
  Flat = "Flat",
  Peak = "Peak",
  OffPeak = "Off Peak",
  SevenDayPeak = "7 Day Peak"
}

export enum ProfileTypeIdentifiers {
  General = "49e88f36-ebe2-4ff4-89f9-2df33d0b3682",
  Recurring = "77ab7319-88c8-4d47-913d-4ae4074f2746",
  FullyExpanded = "2ad43792-f99f-4b9e-92be-0a2b88c1bd45"
}

export enum Region {
  Undefined = "Undefined",
  NSW = "NSW",
  QLD = "QLD",
  VIC = "VIC",
  SA = "SA",
  TAS = "TAS"
}

export enum Quarter {
  Undefined = "Undefined",
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4"
}

export enum Strip {
  Undefined = "Undefined",
  FIN = "Fin Yr",
  CAL = "Cal Yr"
}

export function ConvertStrip(strip?: Strip) {
  switch (strip) {
    case Strip.CAL:
      return ApiStrip.Cal;
    case Strip.FIN:
      return ApiStrip.Fin;
    case Strip.Undefined:
      return undefined;
  }

  return undefined;
}

export function ConvertApiStrip(strip?: ApiStrip) {
  switch (strip) {
    case ApiStrip.Cal:
      return Strip.CAL;
    case ApiStrip.Fin:
      return Strip.FIN;
    case undefined:
      return Strip.Undefined;
  }

  return Strip.Undefined;
}

export enum OptionType {
  Undefined = "Undefined",
  CALL = "Call",
  PUT = "Put"
}

export enum AsianOptionType {
  Undefined = "Undefined",
  CAP = "Cap",
  FLOOR = "Floor"
}

export type UnderlyingOption = OptionType | AsianOptionType;

export function GetUnderlyingOption(s?: string | null) {
  if (s === OptionType.CALL.toUpperCase()) {
    return OptionType.CALL;
  }
  if (s === OptionType.PUT.toUpperCase()) {
    return OptionType.PUT;
  }

  if (s === AsianOptionType.CAP.toUpperCase()) {
    return AsianOptionType.CAP;
  }
  if (s === AsianOptionType.FLOOR.toUpperCase()) {
    return AsianOptionType.FLOOR;
  }

  return OptionType.Undefined;
}

export enum DealType {
  ASX = "ASX",
  OTC = "OTC",
  EP = "EP",
  SRA = "SRA",
  FEX = "FEX",
  GSH = "GSH"
}

export enum UnitOfMeasure {
  "$" = "$",
  "$/MWh" = "$/MWh",
  "$/cert" = "$/cert",
  "$/unit" = "$/unit",
  "$/trade" = "$/trade",
  "$/lot" = "$/lot",
  "$/GJ" = "$/GJ",
  "$/MW" = "$/MW"
}

// ASX and FEX
export enum ProductType {
  Undefined = "Undefined",
  MONTH_FUTURES = "MONTH_FUTURES",
  QUARTER_FUTURES = "QUARTER_FUTURES",
  FUTURES_STRIPS = "FUTURES_STRIPS",
  CAP_QUARTERS = "CAP_QUARTERS",
  CAP_STRIPS = "CAP_STRIPS",
  OPTIONS = "OPTIONS",
  ASIANS = "ASIANS"
}
export type ExchangeProductType = keyof typeof ProductType;

export enum InstrumentType {
  Undefined = "Undefined",
  FUTURE = "FUTURE",
  CAP = "CAP",
  ASIAN = "ASIAN",
  OPTION = "OPTION"
}
export type ASXInstrumentType = keyof typeof InstrumentType;

// OTC

export enum PriceType {
  PRICE = "Price",
  PREMIUM = "Premium",
  OPTIONPREMIUM = "OptionPremium"
}

export enum OtcProductType {
  Undefined = "Undefined",
  SWAP = "SWAP",
  CAP = "CAP",
  FLOOR = "FLOOR",

  ASIANOPTION = "ASIAN_OPTION",
  SWAPTION = "SWAPTION",
  CAPTION = "CAPTION",
  FLOORTION = "FLOORTION",

  SWAPTIONCOLLAR = "SWAPTION_COLLAR",
  HALFHOURLYCOLLAR = "HALF_HOURLY_COLLAR",
  ASIANCOLLAR = "ASIAN_COLLAR"
}

export type OTCProductType = keyof typeof OtcProductType;

export enum OtcInstrumentType {
  Undefined = "Undefined",
  SWAP = "SWAP",
  CAP = "CAP",
  FLOOR = "FLOOR",

  ASIANOPTION = "ASIAN_OPTION",
  SWAPTION = "SWAPTION",
  CAPTION = "CAPTION",
  FLOORTION = "FLOORTION",

  SWAPTIONCOLLAR = "SWAPTION_COLLAR",
  HALFHOURLYCOLLAR = "HALF_HOURLY_COLLAR",
  ASIANCOLLAR = "ASIAN_COLLAR"
}

export type OTCInstrumentType = keyof typeof OtcInstrumentType;

export enum Month {
  Undefined = "Undefined",
  JAN = "Jan",
  FEB = "Feb",
  MAR = "Mar",
  APR = "Apr",
  MAY = "May",
  JUN = "Jun",
  JUL = "Jul",
  AUG = "Aug",
  SEP = "Sep",
  OCT = "Oct",
  NOV = "Nov",
  DEC = "Dec"
}

export enum Firmness {
  Undefined = "Undefined",
  FIRM = "Firm",
  NONFIRM = "Non Firm"
}

// EP

export enum EPInstrumentType {
  Undefined = "Undefined",
  SPOT = "SPOT",
  FORWARD = "FORWARD",
  OPTION = "OPTION"
}

export enum EPProductType {
  Undefined = "Undefined",
  LGC = "LGC",
  STC = "STC",
  ESC = "ESC",
  VEEC = "VEEC",
  ACCU = "ACCU",
  KACC = "KACC",
  NKACCU = "NKACCU"
}

// SRA

export enum SRAProductType {
  Undefined = "Undefined"
}

export enum SRAInstrumentType {
  Undefined = "Undefined",
  PURCHASEATAUCTION = "PURCHASEATAUCTION",
  FORWARD = "FORWARD",
  OPTION = "OPTION"
}

export enum Interconnector {
  Undefined = "Undefined",
  DIRECTLINK = "Directlink",
  QNI = "QNI",
  VICTORIATONSW = "Victoria to NSW Interconnector",
  BASSLINK = "Basslink",
  HEYWOOD = "Heywood Interconnector",
  MURRAYLINK = "Murraylink"
}

export enum TopMenu {
  Undefined = "Undefined",
  POWER = "POWER",
  ENVIRON = "ENVIRON",
  GAS = "GAS",
  ADMIN = "ADMIN",
  REPORTS = "REPORTS"
}

// GSH GAS

export enum Pipeline {
  Undefined = "Undefined",
  WALNNt = "WAL NNt",
  WAL = "WAL",
  SEQ = "SEQ",
  MAP = "MAP",
  MSP = "MSP"
}

export enum DeliveryPeriod {
  Undefined = "Undefined",
  TODAYNONNETTED = "Today (Non-Netted)",
  TOMORROWNONNETTED = "Tomorrow (Non-Netted)",
  TOMORROWNETTED = "Tomorrow (Netted)",
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly"
}
