import { Firmness } from "internal/dcc-types/enums";
import { KeyValue } from "sagas/types/config";

export interface FirmnessState {
  Firmness: Firmness | null;
  DependOn?: KeyValue | null;
}

// REDUX STATE
export interface FirmnessSelectorState {
  Firmness: FirmnessState;
}

// action
export const FIRMNESS_SELECTED = "FIRMNESS_SELECTOR/FIRMNESS_SELECTED";
interface FirmnessSelectedAction {
  type: typeof FIRMNESS_SELECTED;
  payload: FirmnessState;
}
export const FIRMNESS_CLEARED = "FIRMNESS_SELECTOR/FIRMNESS_CLEARED";
interface FirmnessClearedAction {
  type: typeof FIRMNESS_CLEARED;
  payload: boolean;
}

export type FirmnessSelectorActionTypes = FirmnessSelectedAction | FirmnessClearedAction;

// action creators
export function selectFirmness(p: FirmnessState): FirmnessSelectorActionTypes {
  return {
    type: FIRMNESS_SELECTED,
    payload: p
  };
}
export function clearFirmness(p: boolean): FirmnessSelectorActionTypes {
  return {
    type: FIRMNESS_CLEARED,
    payload: p
  };
}

const initialState: FirmnessSelectorState = { Firmness: { Firmness: null } };

export function firmnessSelectorReducer(state = initialState, action: FirmnessSelectorActionTypes): FirmnessSelectorState {
  switch (action.type) {
    case FIRMNESS_CLEARED:
      return initialState;
    case FIRMNESS_SELECTED: {
      return {
        ...state,
        Firmness: action.payload
      };
    }
    default:
      return state;
  }
}
