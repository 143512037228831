import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadedData } from "./loadedData";
import { RootState } from "./configureStore";
import { createAppSelector } from "./hooks";
import { KeyedValue } from "sagas/api";
import { ApiClients } from "api/clients";

interface FeeConfigurationState {
  unitsOfMeasure: LoadedData<KeyedValue[]>;
}

const initialState: FeeConfigurationState = {
  unitsOfMeasure: {
    loading: false,
    data: [],
    error: undefined,
    lastLoaded: 0
  }
};

export const selectUnitsOfMeasure = createAppSelector([(state: RootState) => state.FeeConfiguration.unitsOfMeasure.data], data =>
  data
    .map(x => ({
      Id: x.Id || 0,
      Value: x.Value || ""
    }))
    .filter(x => x.Id > 0 && !!x.Value)
);

export const loadUnitsOfMeasure = createAsyncThunk<KeyedValue[] | undefined, void, { state: RootState }>(
  "feeConfig/loadUnitsOfMeasure",
  async (_, g) => {
    const data = await ApiClients.legacy.configApi.getMarketSettings(g.signal);
    return data?.Body?.UnitOfMeasures || undefined;
  },
  {
    condition: (_, api) => {
      const { data, error, loading } = api.getState().FeeConfiguration.unitsOfMeasure;
      if (loading) return false;

      return data.length < 1 && !error;
    }
  }
);

const feeConfigurationSlice = createSlice({
  name: "feeConfig",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadUnitsOfMeasure.pending, (state, action) => {
        state.unitsOfMeasure.loading = true;
      })
      .addCase(loadUnitsOfMeasure.fulfilled, (state, action) => {
        state.unitsOfMeasure = {
          data: action.payload || [],
          error: undefined,
          lastLoaded: Date.now(),
          loading: false
        };
      })
      .addCase(loadUnitsOfMeasure.rejected, (state, action) => {
        state.unitsOfMeasure = {
          data: state.unitsOfMeasure.data || [],
          error: "An error occurred when loading configuration data.",
          lastLoaded: Date.now(),
          loading: false
        };
      });
  }
});

export default feeConfigurationSlice.reducer;
