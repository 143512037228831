import { css } from "styled-components/macro";
import styled from "styled-components/macro";

interface FlexStyled {
  height?: string;
  width?: string;
  direction?: "column" | "row";
  wrap?: string;
  justifyContent?: "center" | "flex-start" | "flex-end" | "space-between";
  alignItems?: "center" | "stretch" | "flex-end" | "flex-start";
}

export const flexStyle = ({ direction, wrap, justifyContent, alignItems }: FlexStyled) => css`
  display: flex;
  flex-direction: ${direction};
  flex-wrap: ${wrap};
  justify-content: ${justifyContent};
  align-items: ${alignItems};
`;

interface BoxStyled {
  height?: string;
  width?: string;
  $width?: string;
}
export const boxStyle = ({ height, width, $width }: BoxStyled) => css`
  height: ${height};
  width: ${width || $width};
`;

export const Box = styled("div")`
  ${boxStyle};
`;

export const Flex = styled(Box)`
  ${flexStyle};
`;
