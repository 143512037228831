import { ToggleButton } from "app/components/Button";
import { Disabled, Separator } from "internal/dcc-components/styled";
import { DateService, TickerService } from "internal/dcc-services";
import { Future, Option } from "internal/dcc-types";
import { ExpiryConverter, ExpiryReverseLookup, getNetDate } from "internal/dcc-types/dates";
import { DealType, InstrumentType, Month, Period, ProductType, Quarter, Region, Side, Strip } from "internal/dcc-types/enums";
import { emptyFuture } from "internal/dcc-types/futures";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { DealAction, DealActionReply, DealActionResult } from "sagas/types/deals";
import { RootState } from "store/configureStore";
import { reviewProductUpdateHours, selectProduct, selectSide } from "../actions";
import { Grid, half, quarter, fifth, sixers, tiles } from "../../styled";
import { Deal } from "internal/dcc-types/tradeStatus";
import { FlexWrapper } from "../../styled";
import {
  ContractPeriodModel,
  ProfileHoursRequestModel,
  Region as ApiRegion,
  DealType as ApiDealType,
  ProfileGetSummaryResponseProfileModel
} from "sagas/api";
import { periodHoursRequest } from "sagas/reducers/config";

interface EnabledValue {
  value: string;
  enabled?: boolean;
}

export interface FutureSelectorProps {
  Instrument: InstrumentType;
  Today: Date;
  Side: Side;
  Product: Future | Option;
  Deal: DealActionResult;
  ReviewProduct: Deal | null;
  VerticalSpacing?: number;
  selectSide: (e: Side) => void;
  selectProduct: (item: Future) => void;
  PeriodHoursRequest: (i: ProfileHoursRequestModel) => void;
  ReviewProductUpdateHours: (i: number | null) => void;
  PeriodHours: ContractPeriodModel;
  Pending?: boolean | null;

  dealType: DealType;
}

const FutureSelectorContainer: React.FC<FutureSelectorProps> = ({
  Instrument,
  Today,
  selectSide,
  selectProduct,
  Deal,
  Pending,
  ...props
}) => {
  function currentExpiriesF(date: Date): EnabledValue[] {
    return DateService.getFutureExpiryDates(new Date(date), 6).map(m => {
      return { value: ExpiryConverter.toString(m), enabled: m.Enabled };
    });
  }

  function currentYearsF(date: Date): number[] {
    return DateService.getFutureYears(new Date(date), tiles);
  }

  function currentQuartersF(date: Date): Quarter[] {
    return DateService.getASXTradableQuarters(new Date(date))!;
  }

  const [region, setRegion] = useState(Region.Undefined);
  const [period, setPeriod] = useState(Period.Undefined);
  const [m, setM] = useState(Month.Undefined);
  const [q, setQ] = useState(Quarter.Undefined);
  const [strip, setStrip] = useState(Strip.Undefined);
  const [year, setYear] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const sideButtons = {
    items: [
      { label: Side.Buy, primary: true },
      { label: Side.Sell, primary: true }
    ]
  };
  const regionButtons = {
    items: [
      { label: Region.NSW, primary: true },
      { label: Region.QLD, primary: true },
      { label: Region.VIC, primary: true },
      { label: Region.SA, primary: true }
    ]
  };
  const quarterButtons = {
    items: [Quarter.Q1, Quarter.Q2, Quarter.Q3, Quarter.Q4].map(x => ({ label: x, primary: true }))
  };
  const stripButtons = { items: [Strip.FIN, Strip.CAL].map(x => ({ label: x, primary: true })) };

  const [periodButtons, setPeriodButtons] = useState({
    items: [
      { label: Period.Base, primary: true, disable: false },
      { label: Period.Peak, primary: true, disable: false }
    ]
  });

  const [monthButtons, setMonthButtons] = useState({
    items: currentExpiriesF(new Date(Today)).map(x => ({ label: x.value, primary: true, disable: !x.enabled }))
  });
  const [yearButtons, setYearButtons] = useState({
    items: currentYearsF(new Date(Today)).map(x => ({ label: x.toString(), primary: true, disable: false }))
  });

  useEffect(() => {
    if (props.ReviewProduct && props.ReviewProduct.ExchangeProduct) {
      setDisabled(!props.ReviewProduct.Editing);
      selectSide(props.ReviewProduct.Side as Side);
      setRegion(props.ReviewProduct.ExchangeProduct.Region);
      setPeriod(props.ReviewProduct.ExchangeProduct.Period);
      if (
        props.ReviewProduct.ExchangeProduct?.Expiry?.Month &&
        props.ReviewProduct.ExchangeProduct.ProductType === ProductType.MONTH_FUTURES
      ) {
        setM(props.ReviewProduct.ExchangeProduct.Expiry.Month);
      }
      if (props.ReviewProduct.ExchangeProduct.Quarter) setQ(props.ReviewProduct.ExchangeProduct.Quarter);
      if (props.ReviewProduct.ExchangeProduct.Strip) setStrip(props.ReviewProduct.ExchangeProduct.Strip);
      setYear(props.ReviewProduct.ExchangeProduct.Expiry.Year);
    } else {
      setDisabled(false);
      selectSide(Side.Undefined);
      setRegion(Region.Undefined);
      setQ(Quarter.Undefined);
      setStrip(Strip.Undefined);
      setM(Month.Undefined);
      setPeriod(Period.Undefined);
      setYear(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ReviewProduct]);

  useEffect(() => {
    if (Pending === true) {
      setDisabled(true);
    } else if (Pending === false) {
      setDisabled(false);
    }
  }, [Pending]);

  useEffect(() => {
    if (props.ReviewProduct) return;

    if (
      Deal &&
      (Deal.DealActionRequest === DealAction.Clear || Deal.DealActionRequest === DealAction.Enter) &&
      Deal.DealActionReply === DealActionReply.Succeeded
    ) {
      selectSide(Side.Undefined);
      setRegion(Region.Undefined);
      setQ(Quarter.Undefined);
      setStrip(Strip.Undefined);
      setM(Month.Undefined);
      setPeriod(Period.Undefined);
      setYear(0);
      setYearButtons({
        items: DateService.getFutureYears(new Date(Today), tiles).map(x => ({
          label: x.toString(),
          primary: true,
          disable: false
        }))
      });
      setPeriodButtons({
        items: [
          { label: Period.Base, primary: true, disable: false },
          { label: Period.Peak, primary: true, disable: false }
        ]
      });
      setMonthButtons({
        items: currentExpiriesF(new Date(Today)).map(x => ({ label: x.value, primary: true, disable: !x.enabled }))
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Deal, props.ReviewProduct]);

  useEffect(() => {
    if (props.ReviewProduct) return;

    setRegion(Region.Undefined);
    setQ(Quarter.Undefined);
    setStrip(Strip.Undefined);
    setM(Month.Undefined);
    setPeriod(Period.Undefined);
    setYear(0);
    setYearButtons({
      items: DateService.getFutureYears(new Date(Today), tiles).map(x => ({
        label: x.toString(),
        primary: true,
        disable: false
      }))
    });
    setPeriodButtons({
      items: [
        { label: Period.Base, primary: true, disable: false },
        { label: Period.Peak, primary: true, disable: false }
      ]
    });
    setMonthButtons({
      items: currentExpiriesF(new Date(Today)).map(x => ({ label: x.value, primary: true, disable: !x.enabled }))
    });
    selectProduct(emptyFuture);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Today, props.ReviewProduct]);

  const profileList = useSelector<RootState, ProfileGetSummaryResponseProfileModel[]>(
    x => x.ProfilesSagaState?.ProfileGetSummary?.reply?.Profiles || []
  );
  const profileName = period.toString() === "Base" ? "Flat" : period.toString();
  const profileId = profileList?.find(x => x.ProfileName === profileName)?.ProfileId || "";

  useEffect(() => {
    if (region === Region.Undefined) return;
    if (period === Period.Undefined) return;
    if (year <= 0) return;

    if (strip !== Strip.Undefined || q !== Quarter.Undefined || (m !== Month.Undefined && !!profileId)) {
      const [start, end] = DateService.getPeriodDates(q, strip, year, m);
      props.PeriodHoursRequest({
        From: getNetDate(start),
        To: getNetDate(end),
        Region: region as unknown as ApiRegion,
        DealType: props.dealType === DealType.ASX ? ApiDealType.Asx : ApiDealType.Fex,
        VolumeProfileId: profileId
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, period, m, q, strip, year, profileId]);

  useEffect(() => {
    if (props.ReviewProduct && props.PeriodHours && props.PeriodHours?.VolumeProfileTotal) {
      if (props.ReviewProduct?.ExchangeProduct?.Hours !== props.PeriodHours?.VolumeProfileTotal && !props.ReviewProduct.Editing)
        props.ReviewProductUpdateHours(props.PeriodHours.VolumeProfileTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.PeriodHours, props.ReviewProduct]);

  useEffect(() => {
    if (props.ReviewProduct && !props.ReviewProduct.Editing) return;

    // incorrect
    if (!props.PeriodHours || !props.PeriodHours.VolumeProfileTotal) return;

    let currentYear = yearButtons.items.find(i => i.label === Today.getFullYear().toString());
    if (!currentYear) return;

    if (
      !DateService.getASXTradableQuarters(new Date(Today))!.includes(q) &&
      year.toString() === currentYear.label &&
      q !== Quarter.Undefined
    ) {
      selectProduct(emptyFuture);
      return;
    }

    if (strip === Strip.FIN && !DateService.isFinTradable(Today, Today.getFullYear()) && year.toString() === currentYear.label) {
      selectProduct(emptyFuture);
      return;
    }

    if (region !== Region.Undefined && year > 0 && period === Period.Base && m !== Month.Undefined) {
      const fut = {
        Expiry: { Month: m, Year: year },
        Strip: Strip.Undefined,
        Quarter: Quarter.Undefined,
        Region: region,
        ProductType: ProductType.MONTH_FUTURES,
        Period: period,
        Ticker: "",
        Hours: props.PeriodHours?.VolumeProfileTotal
      };
      fut.Ticker = TickerService.getFutureCode(fut);
      selectProduct(fut);
    } else if (region !== Region.Undefined && year > 0 && period !== Period.Undefined && q !== Quarter.Undefined) {
      const fut = {
        Expiry: { Month: Month.Undefined, Year: year },
        Strip: Strip.Undefined,
        Quarter: q,
        Region: region,
        ProductType: ProductType.QUARTER_FUTURES,
        Period: period,
        Ticker: "",
        Hours: props.PeriodHours?.VolumeProfileTotal
      };
      const code = TickerService.getFutureCode(fut);
      const m = ExpiryReverseLookup.get(code[2]);
      fut.Ticker = code;
      if (m) fut.Expiry.Month = m;
      selectProduct(fut);
    } else if (region !== Region.Undefined && year > 0 && period !== Period.Undefined && strip !== Strip.Undefined) {
      const fut = {
        Expiry: { Month: Month.Undefined, Year: year },
        Strip: strip,
        Quarter: Quarter.Undefined,
        Region: region,
        ProductType: ProductType.FUTURES_STRIPS,
        Period: period,
        Ticker: "",
        Hours: props.PeriodHours?.VolumeProfileTotal
      };
      const code = TickerService.getFutureCode(fut);
      const m = ExpiryReverseLookup.get(code[2]);
      fut.Ticker = code;
      if (m) fut.Expiry.Month = m;
      selectProduct(fut);
    } else {
      selectProduct(emptyFuture);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, period, m, q, strip, year, props.ReviewProduct, yearButtons.items, Today, props.PeriodHours]);

  useEffect(() => {
    if (props.ReviewProduct && !props.ReviewProduct.Editing) return;

    if (!props.Product.FromQuickSelector) return;

    let availMonthly =
      currentExpiriesF(Today)
        .map(x => x.value)
        .includes(ExpiryConverter.toString(props.Product.Expiry)) || props.Product.ProductType !== ProductType.MONTH_FUTURES;
    let availQuarterly =
      currentQuartersF(Today).includes(props.Product.Quarter!) ||
      props.Product.ProductType !== ProductType.QUARTER_FUTURES ||
      props.Product.Expiry.Year !== currentYearsF(Today)[0];
    if (!currentYearsF(Today).includes(ExpiryConverter.toLongYear(props.Product.Expiry.Year)) || !availMonthly || !availQuarterly) {
      selectProduct!(emptyFuture);
      setRegion(Region.Undefined);
      setQ(Quarter.Undefined);
      setStrip(Strip.Undefined);
      setM(Month.Undefined);
      setPeriod(Period.Undefined);
      setYear(0);
      setYearButtons({
        items: DateService.getFutureYears(new Date(Today), tiles).map(x => ({
          label: x.toString(),
          primary: true,
          disable: false
        }))
      });
      setPeriodButtons({
        items: [
          { label: Period.Base, primary: true, disable: false },
          { label: Period.Peak, primary: true, disable: false }
        ]
      });
      setMonthButtons({
        items: currentExpiriesF(new Date(Today)).map(x => ({ label: x.value, primary: true, disable: !x.enabled }))
      });
    } else if (props.Product.ProductType === ProductType.MONTH_FUTURES) {
      setM(props.Product.Expiry.Month);
      setRegion(props.Product.Region);
      setPeriod(props.Product.Period);
      setQ(props.Product.Quarter || Quarter.Undefined);
      setStrip(props.Product.Strip || Strip.Undefined);
      setYear(props.Product.Expiry.Year);
    } else if (props.Product.ProductType === ProductType.FUTURES_STRIPS) {
      setM(Month.Undefined);
      setRegion(props.Product.Region);
      setPeriod(props.Product.Period);
      setQ(props.Product.Quarter || Quarter.Undefined);
      setStrip(props.Product.Strip || Strip.Undefined);
      setYear(props.Product.Expiry.Year);
    } else if (props.Product.ProductType === ProductType.QUARTER_FUTURES) {
      setM(Month.Undefined);
      setRegion(props.Product.Region);
      setPeriod(props.Product.Period);
      setQ(props.Product.Quarter || Quarter.Undefined);
      setStrip(props.Product.Strip || Strip.Undefined);
      setYear(props.Product.Expiry.Year);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Product, props.ReviewProduct]);

  useEffect(() => {
    if (props.ReviewProduct && !props.ReviewProduct.Editing) return;

    if (q !== Quarter.Undefined) {
      if (m !== Month.Undefined) {
        setM(Month.Undefined);
        setYear(0);
        if (period === Period.Base) {
          setPeriod(Period.Undefined);
        }
      }
      setStrip(Strip.Undefined);

      let newYearButtons = {
        items: DateService.getFutureYears(new Date(Today), tiles).map(x => ({
          label: x.toString(),
          primary: true,
          disable: false
        }))
      };
      let currentYear = newYearButtons.items.find(i => i.label === Today.getFullYear().toString())!;

      currentYear.disable = !DateService.getASXTradableQuarters(new Date(Today))?.includes(q) || false;

      if (currentYear.disable && year.toString() === currentYear.label) {
        setYear(0);
      }

      setYearButtons(newYearButtons);
    }
  }, [q, m, period, props.ReviewProduct, Today, year]);

  useEffect(() => {
    if (props.ReviewProduct && !props.ReviewProduct.Editing) return;

    if (strip !== Strip.Undefined) {
      if (m !== Month.Undefined) {
        setM(Month.Undefined);
        setYear(0);
        if (period === Period.Base) {
          setPeriod(Period.Undefined);
        }
      }
      setQ(Quarter.Undefined);

      let newYearButtons = {
        items: DateService.getFutureYears(new Date(Today), tiles).map(x => {
          let disabled = false;
          if (strip === Strip.FIN) {
            disabled = !DateService.isFinTradable(Today, x);
          }
          if (strip === Strip.CAL) {
            disabled = !DateService.isCalTradable(Today, x);
          }
          if (disabled && year.toString() === x.toString()) {
            setYear(0);
          }
          const but = {
            label: x.toString(),
            primary: true,
            disable: disabled
          };
          return but;
        })
      };

      setYearButtons(newYearButtons);
    } else {
      setYearButtons({
        items: DateService.getFutureYears(new Date(Today), tiles).map(x => ({
          label: x.toString(),
          primary: true,
          disable: false
        }))
      });
    }
  }, [strip, m, period, props.ReviewProduct, Today, year]);

  function _clearImpliedSelection(all: boolean = true) {
    if (m !== Month.Undefined) {
      setM(Month.Undefined);
      setYear(0);
      setYearButtons({
        items: DateService.getFutureYears(new Date(Today), tiles).map(x => ({
          label: x.toString(),
          primary: true,
          disable: false
        }))
      });

      if (all && period === Period.Base) {
        setPeriod(Period.Undefined);
      }
    }
  }

  function handlePeriodSelected(e: string) {
    if (e === Period.Peak) {
      _clearImpliedSelection(false);
    }
    setMonthButtons({
      items: currentExpiriesF(new Date(Today)).map(x => ({
        label: x.value,
        primary: true,
        disable: !x.enabled || e === Period.Peak
      }))
    });

    setPeriod(e as Period);
  }

  function handleMonthlySelected(e: string) {
    let exp = ExpiryConverter.fromString(e);
    let selectedYear = ExpiryConverter.toLongYear(exp.Year);
    setYearButtons(old => {
      const newItems = old.items.map(i => {
        if (i.label !== selectedYear.toString()) {
          i.disable = true;
        } else {
          i.disable = false;
        }
        return i;
      });

      return { items: newItems };
    });
    setM(exp.Month);
    setYear(selectedYear);
    setPeriod(Period.Base);
    setQ(Quarter.Undefined);
    setStrip(Strip.Undefined);
  }

  return (
    <Disabled disabled={disabled}>
      <fieldset style={{ border: 0 }} disabled={disabled}>
        <Grid>
          <ToggleButton
            selectedLabel={
              props.ReviewProduct && props.ReviewProduct.Side && !props.ReviewProduct.Editing ? props.ReviewProduct.Side : props.Side
            }
            onSelected={e => selectSide(e as Side)}
            width={half}
            items={sideButtons.items}
          />

          <ToggleButton selectedLabel={region} onSelected={e => setRegion(e as Region)} width={quarter} items={regionButtons.items} />

          <ToggleButton selectedLabel={period} onSelected={handlePeriodSelected} width={half} items={periodButtons.items} />
          <FlexWrapper justifyContent="center" alignItems="center">
            {props.dealType === DealType.ASX && (
              <ToggleButton
                selectedLabel={ExpiryConverter.toString({ Month: m, Year: year })}
                onSelected={handleMonthlySelected}
                width={sixers}
                items={monthButtons.items}
              />
            )}
            <ToggleButton selectedLabel={q} onSelected={e => setQ(e as Quarter)} width={quarter} items={quarterButtons.items} />
            <ToggleButton selectedLabel={strip} onSelected={e => setStrip(e as Strip)} width={half} items={stripButtons.items} />
          </FlexWrapper>
          <ToggleButton selectedLabel={year.toString()} onSelected={e => setYear(Number(e))} width={fifth} items={yearButtons.items} />
          <Separator spacing="0.1" />
          <Separator spacing="0.1" />
        </Grid>
      </fieldset>
    </Disabled>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  selectProduct: (item: Future) => dispatch(selectProduct(item)),
  selectSide: (item: Side) => dispatch(selectSide(item)),
  PeriodHoursRequest: (i: ProfileHoursRequestModel) => dispatch(periodHoursRequest(i)),
  ReviewProductUpdateHours: (i: number | null) => dispatch(reviewProductUpdateHours(i))
});

const MapStateToProps = (state: RootState) => ({
  Pending: state.tickerSelector.Pending,
  Instrument: state.tickerSelector.Instrument,
  Side: state.tickerSelector.Side,
  Product: state.tickerSelector.Product,
  Deal: state.DealsSagaState.SubmitDeal.reply,
  Today: state.tickerSelector.ReviewProduct
    ? state.tickerSelector.ReviewProduct.DealDate || new Date()
    : state.dealDetailsSelector.SelectedDate || new Date(),
  ReviewProduct: state.tickerSelector.ReviewProduct,
  PeriodHours: state.ConfigSagaState.PeriodHours.reply
});

export const FutureSelector = connect(MapStateToProps, mapDispatchToProps)(FutureSelectorContainer);
