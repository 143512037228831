import { KeyValue } from "sagas/types/config";

// REDUX STATE
export interface Counterparty {
  Counterparty: KeyValue | null;
  Trader: KeyValue | null;
  Netted?: boolean;
  ExcludeCredit?: boolean;
  AFMA?: boolean;
  ISDA?: boolean;
  LongFormConfirmation?: boolean;
  CounterpartyRef: string | null;
}

export interface CounterpartySelectorState {
  Counterparty: Counterparty | null;
}

// action
export const COUNTERPARTY_SELECTED = "COUNTERPARTY_SELECTOR/COUNTERPARTY_SELECTED";
interface CounterpartySelectedAction {
  type: typeof COUNTERPARTY_SELECTED;
  payload: Counterparty | null;
}

export const COUNTERPARTY_CLEARED = "COUNTERPARTY_SELECTOR/COUNTERPARTY_CLEARED";
interface CounterpartyClearedAction {
  type: typeof COUNTERPARTY_CLEARED;
  payload: boolean;
}

export type CounterpartySelectorActionTypes = CounterpartySelectedAction | CounterpartyClearedAction;

// action creators
export function selectCounterparty(p: Counterparty | null): CounterpartySelectorActionTypes {
  return {
    type: COUNTERPARTY_SELECTED,
    payload: p
  };
}
export function clearCounterparty(p: boolean): CounterpartySelectorActionTypes {
  return {
    type: COUNTERPARTY_CLEARED,
    payload: p
  };
}

const initialState: CounterpartySelectorState = { Counterparty: null };

export function counterpartySelectorReducer(state = initialState, action: CounterpartySelectorActionTypes): CounterpartySelectorState {
  switch (action.type) {
    case COUNTERPARTY_SELECTED: {
      return {
        ...state,
        Counterparty: action.payload
      };
    }
    case COUNTERPARTY_CLEARED: {
      return initialState;
    }
    default:
      return state;
  }
}
