import { Future, Option, Side } from "internal/dcc-types";
import { DealType, InstrumentType } from "internal/dcc-types/enums";
import { Deal } from "internal/dcc-types/tradeStatus";

export const DEALTYPE_SELECTED = "TICKER_SELECTOR/DEALTYPE_SELECTED";
export const INSTRUMENT_SELECTED = "TICKER_SELECTOR/INSTRUMENT_SELECTED";
export const PRODUCT_SELECTED = "TICKER_SELECTOR/PRODUCT_SELECTED";
export const SIDE_SELECTED = "TICKER_SELECTOR/SIDE_SELECTED";
export const VOLUME_SELECTED = "TICKER_SELECTOR/VOLUME_SELECTED";
export const PRICE_SELECTED = "TICKER_SELECTOR/PRICE_SELECTED";
export const TICKER_CLEARED = "TICKER_SELECTOR/TICKER_CLEARED";
export const TICKER_PENDING = "TICKER_SELECTOR/TICKER_PENDING";

export const PRODUCT_REVIEW = "TICKER_SELECTOR/PRODUCT_REVIEW";
export const PRODUCT_REVIEW_UPDATE_HOURS = "TICKER_SELECTOR/PRODUCT_REVIEW_UPDATE_HOURS";

interface DealTypeSelectedAction {
  type: typeof DEALTYPE_SELECTED;
  payload: DealType | null;
}

interface InstrumentSelectedAction {
  type: typeof INSTRUMENT_SELECTED;
  payload: InstrumentType;
}

interface ProductSelectedAction {
  type: typeof PRODUCT_SELECTED;
  payload: Future | Option;
}

interface ProductReviewAction {
  type: typeof PRODUCT_REVIEW;
  payload: Deal | null;
}

interface ProductReviewUpdateHoursAction {
  type: typeof PRODUCT_REVIEW_UPDATE_HOURS;
  payload: number | null;
}

interface SideSelectedAction {
  type: typeof SIDE_SELECTED;
  payload: Side;
}

interface VolumeSelectedAction {
  type: typeof VOLUME_SELECTED;
  payload: number | null;
}

interface PriceSelectedAction {
  type: typeof PRICE_SELECTED;
  payload: number | null;
}

interface TickerClearedAction {
  type: typeof TICKER_CLEARED;
  payload: boolean;
}

interface TickerPendingAction {
  type: typeof TICKER_PENDING;
  payload: boolean;
}

export type TickerSelectorActionTypes =
  | ProductSelectedAction
  | SideSelectedAction
  | VolumeSelectedAction
  | PriceSelectedAction
  | InstrumentSelectedAction
  | TickerClearedAction
  | TickerPendingAction
  | ProductReviewAction
  | DealTypeSelectedAction
  | ProductReviewUpdateHoursAction;

// action creators
export function selectExchangeDealType(d: DealType | null): TickerSelectorActionTypes {
  return {
    type: DEALTYPE_SELECTED,
    payload: d
  };
}

export function selectInstrument(inst: InstrumentType | string): TickerSelectorActionTypes {
  return {
    type: INSTRUMENT_SELECTED,
    payload: inst as InstrumentType
  };
}

export function selectProduct(product: Future | Option): TickerSelectorActionTypes {
  return {
    type: PRODUCT_SELECTED,
    payload: product
  };
}

export function reviewProduct(product: Deal | null): TickerSelectorActionTypes {
  return {
    type: PRODUCT_REVIEW,
    payload: product
  };
}

export function reviewProductUpdateHours(hours: number | null): TickerSelectorActionTypes {
  return {
    type: PRODUCT_REVIEW_UPDATE_HOURS,
    payload: hours
  };
}

export function selectSide(side: Side): TickerSelectorActionTypes {
  return {
    type: SIDE_SELECTED,
    payload: side
  };
}

export function selectVolume(vol: number | null): TickerSelectorActionTypes {
  return {
    type: VOLUME_SELECTED,
    payload: vol
  };
}

export function selectPrice(price: number | null): TickerSelectorActionTypes {
  return {
    type: PRICE_SELECTED,
    payload: price
  };
}

export function clearASXState(p: boolean): TickerSelectorActionTypes {
  return {
    type: TICKER_CLEARED,
    payload: p
  };
}

export function pendingState(p: boolean): TickerSelectorActionTypes {
  return {
    type: TICKER_PENDING,
    payload: p
  };
}
