import {
  DayTypeCreateRequestModel,
  DayTypeCreateResponseModel,
  DayTypeDaysResponseModel,
  DayTypeDeleteRequestResponseModel,
  DayTypeGetResponseModel,
  DayTypeGetSingularResponseModel,
  DayTypeUpdateRequestModel,
  DayTypeUpdateResponseModel
} from "sagas/api";
import { IApiError, IApiResponse } from "../types/config";

export { rootSaga } from "../controllers/root";

export const CREATE_DAY_TYPE_REQUEST_V2 = "DAYTYPES/CREATE_DAY_TYPE_REQUEST_V2";
export const CREATE_DAY_TYPE_SUCCEEDED_V2 = "DAYTYPES/CREATE_DAY_TYPE_SUCCEEDED_V2";
export const CREATE_DAY_TYPE_FAILED_V2 = "DAYTYPES/CREATE_DAY_TYPE_FAILED_V2";

export const GET_DAY_TYPES_REQUEST_V2 = "DAYTYPES/GET_DAY_TYPES_REQUEST_V2";
export const GET_DAY_TYPES_SUCCEEDED_V2 = "DAYTYPES/GET_DAY_TYPES_SUCCEEDED_V2";
export const GET_DAY_TYPES_FAILED_V2 = "DAYTYPES/GET_DAY_TYPES_FAILED_V2";

export const GET_DAYS_REQUEST_V2 = "DAYTYPES/GET_DAYS_REQUEST_V2";
export const GET_DAYS_SUCCEEDED_V2 = "DAYTYPES/GET_DAYS_SUCCEEDED_V2";
export const GET_DAYS_FAILED_V2 = "DAYTYPES/GET_DAYS_FAILED_V2";

export const GET_DAY_TYPE_REQUEST_V2 = "DAYTYPES/GET_DAY_TYPE_REQUEST_V2";
export const GET_DAY_TYPE_SUCCEEDED_V2 = "DAYTYPES/GET_DAY_TYPE_SUCCEEDED_V2";
export const GET_DAY_TYPE_FAILED_V2 = "DAYTYPES/GET_DAY_TYPE_FAILED_V2";

export const UPDATE_DAY_TYPE_REQUEST_V2 = "DAYTYPES/UPDATE_DAY_TYPE_REQUEST_V2";
export const UPDATE_DAY_TYPE_SUCCEEDED_V2 = "DAYTYPES/UPDATE_DAY_TYPE_SUCCEEDED_V2";
export const UPDATE_DAY_TYPE_FAILED_V2 = "DAYTYPES/UPDATE_DAY_TYPE_FAILED_V2";

export const DELETE_DAY_TYPE_REQUEST_V2 = "DAYTYPES/DELETE_DAY_TYPE_REQUEST_V2";
export const DELETE_DAY_TYPE_SUCCEEDED_V2 = "DAYTYPES/DELETE_DAY_TYPE_SUCCEEDED_V2";
export const DELETE_DAY_TYPE_FAILED_V2 = "DAYTYPES/DELETE_DAY_TYPE_FAILED_V2";

export const DELETE_DAY_TYPE_REQUEST_REQUEST_V2 = "DAYTYPES/DELETE_DAY_TYPE_REQUEST_REQUEST_V2";
export const DELETE_DAY_TYPE_REQUEST_SUCCEEDED_V2 = "DAYTYPES/DELETE_DAY_TYPE_REQUEST_SUCCEEDED_V2";
export const DELETE_DAY_TYPE_REQUEST_FAILED_V2 = "DAYTYPES/DELETE_DAY_TYPE_REQUEST_FAILED_V2";

export interface DayTypesBaseAction<T> {
  type: string;
  payload: T;
}

// action creators
export const CreateDayTypesRequest = (model: DayTypeCreateRequestModel): DayTypesBaseAction<DayTypeCreateRequestModel> => ({
  type: CREATE_DAY_TYPE_REQUEST_V2,
  payload: model
});

export const CreateDayTypesSuccess = (data: DayTypeCreateResponseModel): DayTypesBaseAction<DayTypeCreateResponseModel> => ({
  type: CREATE_DAY_TYPE_SUCCEEDED_V2,
  payload: data
});

export const CreateDayTypesFailed = (message: IApiError): DayTypesBaseAction<IApiError> => ({
  type: CREATE_DAY_TYPE_FAILED_V2,
  payload: message
});

export const GetDayTypesRequest = (model: string | null): DayTypesBaseAction<string | null> => ({
  type: GET_DAY_TYPES_REQUEST_V2,
  payload: model
});

export const GetDayTypesSuccess = (data: DayTypeGetResponseModel): DayTypesBaseAction<DayTypeGetResponseModel> => ({
  type: GET_DAY_TYPES_SUCCEEDED_V2,
  payload: data
});

export const GetDayTypesFailed = (message: IApiError): DayTypesBaseAction<IApiError> => ({
  type: GET_DAY_TYPES_FAILED_V2,
  payload: message
});

export const GetDaysRequest = (model: string | null): DayTypesBaseAction<string | null> => ({
  type: GET_DAYS_REQUEST_V2,
  payload: model
});

export const GetDaysSuccess = (data: DayTypeDaysResponseModel): DayTypesBaseAction<DayTypeDaysResponseModel> => ({
  type: GET_DAYS_SUCCEEDED_V2,
  payload: data
});

export const GetDaysFailed = (message: IApiError): DayTypesBaseAction<IApiError> => ({
  type: GET_DAYS_FAILED_V2,
  payload: message
});

export const GetDayTypeRequest = (model: string): DayTypesBaseAction<string> => ({
  type: GET_DAY_TYPE_REQUEST_V2,
  payload: model
});

export const GetDayTypeSuccess = (data: DayTypeGetSingularResponseModel): DayTypesBaseAction<DayTypeGetSingularResponseModel> => ({
  type: GET_DAY_TYPE_SUCCEEDED_V2,
  payload: data
});

export const GetDayTypeFailed = (message: IApiError): DayTypesBaseAction<IApiError> => ({
  type: GET_DAY_TYPE_FAILED_V2,
  payload: message
});

export const UpdateDayTypeRequest = (model: DayTypeUpdateRequestModel): DayTypesBaseAction<DayTypeUpdateRequestModel> => ({
  type: UPDATE_DAY_TYPE_REQUEST_V2,
  payload: model
});

export const UpdateDayTypeSuccess = (data: DayTypeUpdateResponseModel): DayTypesBaseAction<DayTypeUpdateResponseModel> => ({
  type: UPDATE_DAY_TYPE_SUCCEEDED_V2,
  payload: data
});

export const UpdateDayTypeFailed = (message: IApiError): DayTypesBaseAction<IApiError> => ({
  type: UPDATE_DAY_TYPE_FAILED_V2,
  payload: message
});

export const DeleteDayTypeRequest = (model: {
  dayTypeId: string | null;
  authKey: string | null;
}): DayTypesBaseAction<{ dayTypeId: string | null; authKey: string | null }> => ({
  type: DELETE_DAY_TYPE_REQUEST_V2,
  payload: model
});

export const DeleteDayTypeSuccess = (data: IApiResponse): DayTypesBaseAction<IApiResponse> => ({
  type: DELETE_DAY_TYPE_SUCCEEDED_V2,
  payload: data
});

export const DeleteDayTypeFailed = (message: IApiError): DayTypesBaseAction<IApiError> => ({
  type: DELETE_DAY_TYPE_FAILED_V2,
  payload: message
});

export const DeleteDayTypeRequestRequest = (model: string): DayTypesBaseAction<string> => ({
  type: DELETE_DAY_TYPE_REQUEST_REQUEST_V2,
  payload: model
});

export const DeleteDayTypeRequestSuccess = (
  data: DayTypeDeleteRequestResponseModel | null
): DayTypesBaseAction<DayTypeDeleteRequestResponseModel | null> => ({
  type: DELETE_DAY_TYPE_REQUEST_SUCCEEDED_V2,
  payload: data
});

export const DeleteDayTypeRequestFailed = (message: IApiError): DayTypesBaseAction<IApiError> => ({
  type: DELETE_DAY_TYPE_REQUEST_FAILED_V2,
  payload: message
});

// reducer
export interface DayTypesSagaState {
  CreateDayTypes?: {
    request?: DayTypeCreateRequestModel;
    reply?: DayTypeCreateResponseModel;
    error?: IApiError;
  };
  GetDayTypes?: {
    request?: string | null;
    reply?: DayTypeGetResponseModel;
    error?: IApiError;
  };
  GetDays?: {
    request?: string | null;
    reply?: DayTypeDaysResponseModel;
    error?: IApiError;
  };
  GetDayType?: {
    request?: string;
    reply?: DayTypeGetSingularResponseModel;
    error?: IApiError;
  };
  UpdateDayType?: {
    request?: string;
    reply?: DayTypeUpdateResponseModel;
    error?: IApiError;
  };
  DeleteDayType?: {
    request?: string;
    reply?: IApiResponse;
    error?: IApiError;
  };
  DeleteDayTypeRequest?: {
    request?: string;
    reply?: DayTypeDeleteRequestResponseModel;
    error?: IApiError;
  };
}

const initState: DayTypesSagaState = {};

export const DayTypesReducer = (state: DayTypesSagaState = initState, action: DayTypesBaseAction<any>) => {
  switch (action.type) {
    case CREATE_DAY_TYPE_REQUEST_V2:
      return { ...state, CreateDayTypes: { request: action.payload } };
    case CREATE_DAY_TYPE_SUCCEEDED_V2:
      return { ...state, CreateDayTypes: { request: state.CreateDayTypes?.request, reply: action.payload } };
    case CREATE_DAY_TYPE_FAILED_V2:
      return { ...state, CreateDayTypes: { request: state.CreateDayTypes?.request, error: action.payload } };
    case GET_DAY_TYPES_REQUEST_V2:
      return { ...state, GetDayTypes: { request: action.payload } };
    case GET_DAY_TYPES_SUCCEEDED_V2:
      return { ...state, GetDayTypes: { request: state.GetDayTypes?.request, reply: action.payload } };
    case GET_DAY_TYPES_FAILED_V2:
      return { ...state, GetDayTypes: { request: state.GetDayTypes?.request, error: action.payload } };
    case GET_DAYS_REQUEST_V2:
      return { ...state, GetDays: { request: action.payload } };
    case GET_DAYS_SUCCEEDED_V2:
      return { ...state, GetDays: { request: state.GetDays?.request, reply: action.payload } };
    case GET_DAYS_FAILED_V2:
      return { ...state, GetDays: { request: state.GetDays?.request, error: action.payload } };
    case GET_DAY_TYPE_REQUEST_V2:
      return { ...state, GetDayType: { request: action.payload } };
    case GET_DAY_TYPE_SUCCEEDED_V2:
      return { ...state, GetDayType: { request: state.GetDayType?.request, reply: action.payload } };
    case GET_DAY_TYPE_FAILED_V2:
      return { ...state, GetDayType: { request: state.GetDayType?.request, error: action.payload } };
    case UPDATE_DAY_TYPE_REQUEST_V2:
      return { ...state, UpdateDayType: { request: action.payload } };
    case UPDATE_DAY_TYPE_SUCCEEDED_V2:
      return { ...state, UpdateDayType: { request: state.UpdateDayType?.request, reply: action.payload } };
    case UPDATE_DAY_TYPE_FAILED_V2:
      return { ...state, UpdateDayType: { request: state.UpdateDayType?.request, error: action.payload } };
    case DELETE_DAY_TYPE_REQUEST_V2:
      return { ...state, DeleteDayType: { request: action.payload } };
    case DELETE_DAY_TYPE_SUCCEEDED_V2:
      return { ...state, DeleteDayType: { request: state.DeleteDayType?.request, reply: action.payload } };
    case DELETE_DAY_TYPE_FAILED_V2:
      return { ...state, DeleteDayType: { request: state.DeleteDayType?.request, error: action.payload } };
    case DELETE_DAY_TYPE_REQUEST_REQUEST_V2:
      return { ...state, DeleteDayTypeRequest: { request: action.payload } };
    case DELETE_DAY_TYPE_REQUEST_SUCCEEDED_V2:
      return {
        ...state,
        DeleteDayTypeRequest: { request: state.DeleteDayTypeRequest?.request, reply: action.payload }
      };
    case DELETE_DAY_TYPE_REQUEST_FAILED_V2:
      return {
        ...state,
        DeleteDayTypeRequest: { request: state.DeleteDayTypeRequest?.request, error: action.payload }
      };
  }
  return state;
};
