import * as generatedConfigurationApi from "./marketConfigurationApi";
import * as generatedDealsApi from "./marketDealsApi";
import * as generatedIntermediariesApi from "./intermediariesApiClient";
import * as generatedLegacyApi from "./legacyApi";

import { MsalApp, loginRequest } from "app/auth";
import { LoginService } from "internal/dcc-services/LoginService";
import { AccountInfo } from "@azure/msal-browser";

const config = (
  await import(
    /* webpackMode: "lazy-once" */
    "../appConfig"
  )
).default;

class AuthorizedApiClients {
  private static _msal = MsalApp;
  public async getToken() {
    if (AuthorizedApiClients._msal == null) return "";

    let accountInfo: AccountInfo | undefined = undefined;

    try {
      if (AuthorizedApiClients._msal.getActiveAccount() == null) {
        const accounts = AuthorizedApiClients._msal.getAllAccounts();
        if (accounts.length === 1) {
          accountInfo = accounts[0];
          AuthorizedApiClients._msal.setActiveAccount(accounts[1]);
        } else {
          await AuthorizedApiClients._msal.acquireTokenRedirect({
            ...loginRequest,
            prompt: "select_account"
          });
          return "";
        }
      }
      const token = await AuthorizedApiClients._msal.acquireTokenSilent({
        ...loginRequest,
        account: accountInfo
      });

      const accessToken = token?.accessToken || "";
      if (!!accessToken) {
        return accessToken;
      }
    } catch (ex) {
      console.error(ex);
    }

    await AuthorizedApiClients._msal.acquireTokenRedirect({
      ...loginRequest,
      prompt: "select_account"
    });
    return "";
  }
}

const ApiClientTokenHelper = new AuthorizedApiClients();

class marketsClient extends generatedConfigurationApi.MarketConfigurationApi.MarketsClient {}
class marketFeesClient extends generatedConfigurationApi.MarketConfigurationApi.MarketFeesClient {}
class productsClient extends generatedConfigurationApi.MarketConfigurationApi.ProductsClient {}
class lookupDataClient extends generatedConfigurationApi.MarketConfigurationApi.LookupDataClient {}
class marketDealsApi extends generatedDealsApi.MarketDealsApi.Client {}
class dealIntermediariesClient extends generatedIntermediariesApi.MarketDealIntermediariesApi.DealIntermediariesClient {}
class legacyDealsApi extends generatedLegacyApi.LegacyDealApi.DealsClient {}
class legacyConfigApi extends generatedLegacyApi.LegacyDealApi.ConfigClient {}

const getLegacyToken = async () => {
  return LoginService.token() || "";
};

export const ApiClients: {
  marketConfiguration: {
    marketsClient: generatedConfigurationApi.MarketConfigurationApi.MarketsClient;
    marketFeesClient: generatedConfigurationApi.MarketConfigurationApi.MarketFeesClient;
    productsClient: generatedConfigurationApi.MarketConfigurationApi.ProductsClient;
    lookupClient: generatedConfigurationApi.MarketConfigurationApi.LookupDataClient;
  };
  deals: {
    marketDealsClient: generatedDealsApi.MarketDealsApi.Client;
    intermediariesClient: generatedIntermediariesApi.MarketDealIntermediariesApi.IDealIntermediariesClient;
  };
  legacy: {
    dealsClient: generatedLegacyApi.LegacyDealApi.DealsClient;
    configApi: generatedLegacyApi.LegacyDealApi.ConfigClient;
  };
  getToken: () => Promise<string>;
} = {
  getToken: ApiClientTokenHelper.getToken,
  marketConfiguration: {
    marketsClient: new marketsClient(ApiClientTokenHelper.getToken, config.MarketsApiBaseUrl),
    marketFeesClient: new marketFeesClient(ApiClientTokenHelper.getToken, config.MarketsApiBaseUrl),
    productsClient: new productsClient(ApiClientTokenHelper.getToken, config.MarketsApiBaseUrl),
    lookupClient: new lookupDataClient(ApiClientTokenHelper.getToken, config.MarketsApiBaseUrl)
  },
  deals: {
    marketDealsClient: new marketDealsApi(getLegacyToken, config.LegacyServerApiBaseUrl),
    intermediariesClient: new dealIntermediariesClient(getLegacyToken, config.LegacyServerApiBaseUrl)
  },
  legacy: {
    dealsClient: new legacyDealsApi(getLegacyToken, config.LegacyServerApiBaseUrl),
    configApi: new legacyConfigApi(getLegacyToken, config.LegacyServerApiBaseUrl)
  }
};
